import { Avatar, Box, Button, Grid, Paper, Stack, TextField } from "@mui/material";
import { LockOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert } from "../components/ui/Alert";

import { authAction } from "../_actions/AuthAction";
import { LoadingButton } from "@mui/lab";

import LoginIcon from "@mui/icons-material/Login"

const paperStyle={ padding :20 ,width:280, margin:"240px auto"}
const avatarStyle={backgroundColor:'#1bbd7e'}
const btnstyle={margin:'8px 0'}

export const Login = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const navigation = useNavigate();

    const auth = useSelector(state => state.auth);

    useEffect(() => {
        if (auth.isAuth) {
            navigation("/dashboard");
        }
    },[auth.isAuth])

    const [inputs, setInputs] = useState({
        username: '',
        password: ''
    });

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    const submitForm = (e) => {
        e.preventDefault();
        console.log("Login Form Submitted");

        if (inputs.username && inputs.password) {
            // get return url from location state or default to home page
            const { from } = location.state || { from: { pathname: "/" } };
            dispatch(authAction.login(inputs.username, inputs.password, from));
        }

    }

    return (

        <Box 
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <Grid container spacing={2} >
                <Paper elevation={10} style={paperStyle}>

                    <Grid item xs={12} >
                        <Stack alignItems={"center"}>
                            <Avatar style={avatarStyle}> <LockOutlined /></Avatar>
                            <h2>Sign In</h2>
                        </Stack>
                    </Grid>
                    
                    <Grid item xs={12} >
                    <Alert />
                    </Grid>

                    <Grid container direction={"column"} spacing={2}>

                        

                        <Grid item>
                            <TextField 
                                autoFocus 
                                label='Username' 
                                placeholder='Enter username' 
                                variant="outlined" 
                                name="username" 
                                id="username"
                                fullWidth 
                                required 
                                onChange={handleChange}/>
                        </Grid>
                        
                        <Grid item>
                            <TextField label='Password' placeholder='Enter password' type='password' variant="outlined" id="password" name="password" fullWidth required onChange={handleChange}/>
                        </Grid>

                        <Grid item >
                            <LoadingButton
                                loading={auth.loading}
                                loadingPosition="start"
                                startIcon={<LoginIcon />}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={submitForm}
                            >
                                Sign in
                            </LoadingButton>
                        </Grid>

                    </Grid>    
                </Paper>
            </Grid>
        
        </Box>
    );
}