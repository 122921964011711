import { Box, Button, ButtonGroup, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { Referral } from '../components/referral';
import { RotaWeekPrint } from '../components/rota/RotaWeekPrint';
import { Alert } from '../components/ui/Alert';
import { EntryPage } from '../components/weekreport/EntryPage';
import { AccessDenied } from '../pages/403';
import { JobRole } from '../pages/admin/JobRole';
import { RotaPlan } from '../pages/admin/RotaPlan';
import { Dashboard } from '../pages/Dashboard';
import { Employee } from '../pages/Employee';
import { IncidentPage } from '../pages/IncidentPage';
import { Logout } from '../pages/Logout';
import { RoomOccupancy } from '../pages/RoomOccupancy';
import { Rota } from '../pages/Rota';
import { WeekReport } from '../pages/WeekReport';
import { Roles } from '../_constants/routeRolesConstant';
import { PrivateRoute } from '../_helpers/PrivateRoute';

const drawerWidth = 240;

export const AppContainer = () => {

    return (
      <div style={{flexGrow: 1, bgcolor: 'Background.default', marginLeft: `${drawerWidth}px`}}>
        <Container style={{marginTop: 16, marginLeft: 1}} maxWidth="xl">
          <Alert />
          <Routes>
              <Route path="/" element={<PrivateRoute requiredRoles={[Roles.DASHBOARD_MANAGE]}><Dashboard /></PrivateRoute>} />
              <Route path="/dashboard" element={<PrivateRoute requiredRoles={[Roles.DASHBOARD_MANAGE]}><Dashboard /></PrivateRoute>} />
              <Route path="/roomocupancy" element={<PrivateRoute requiredRoles={[Roles.ROOM_MANAGE]}><RoomOccupancy /> </PrivateRoute>} />
              <Route path="/rota" element={<PrivateRoute requiredRoles={[Roles.ROTA_MANAGE]}><Rota /> </PrivateRoute>} />
              <Route path="/rota/print" element={<PrivateRoute requiredRoles={[Roles.ROTA_MANAGE]}><RotaWeekPrint /> </PrivateRoute>} />

              <Route path="/employee" element={<PrivateRoute requiredRoles={[Roles.EMPLOYEE_MANAGE]}><Employee /></PrivateRoute>} />

              <Route path="/referral" element={<PrivateRoute requiredRoles={[Roles.REFERRAL_MANAGE]}><Referral /></PrivateRoute>} />

              <Route path="/admin/jobrole" element={ <PrivateRoute requiredRoles={[Roles.ADMIN_ROLE]}> <JobRole /> </PrivateRoute>} />
              <Route path="/admin/rotaplan" element={<PrivateRoute requiredRoles={[Roles.ADMIN_ROLE]}> <RotaPlan /></PrivateRoute>} />

              <Route path="/weekreport" element={<PrivateRoute requiredRoles={[Roles.ENTRY_MANAGE]}> <WeekReport /></PrivateRoute>} />
              <Route path="/weekly" element={<PrivateRoute requiredRoles={[Roles.ENTRY_MANAGE]}><EntryPage /></PrivateRoute>} />

              <Route path="/incident" element={<PrivateRoute requiredRoles={[Roles.INCIDENT_MANAGE]}><IncidentPage /></PrivateRoute>} />

              <Route path="logout" element={<Logout  />} />
              <Route path="/403" element={<AccessDenied />} />
          </Routes>  
        </Container>
</div>

    )
}