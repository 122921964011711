import { Card, CardContent, CardHeader, Grid } from "@mui/material"
import { localCurrency } from "../../_helpers/number"
import { WRLabel, WRValue } from "../ui/InfoBlock"


export const KFI = ({room, referral}) => {

    return (

        <Card>
            <CardHeader title="Key Financial Information (KFI)" />

            <CardContent>

                <Grid container spacing={1}>
                    <Grid item xs={5}><WRLabel value="Bed vacancies" /></Grid>
                    <Grid item xs={2}>{room && <WRValue value={` ${room.noOfRoomsNotOccupied} / ${room.noOfRooms}`} />}</Grid>
                    <Grid item xs={3}><WRLabel value="Inquiries" /></Grid>
                    <Grid item xs={2}><WRValue value="-" /></Grid>

                    <Grid item xs={5}><WRLabel value="Average Weekly Fee" /></Grid>
                    <Grid item xs={2}>{room && <WRValue value={localCurrency(room.actual / room.noOfRooms)} />}</Grid>
                    <Grid item xs={3}><WRLabel value="Pending Referrals" /></Grid>
                    <Grid item xs={2}><WRValue value={(referral.totNInfo1 && referral.totNInfo2) ? `${(referral.totNInfo1 - referral.totNInfo2)} / ${referral.totNInfo1}` : '-'} /></Grid>

                    {/*<Grid item xs={5}><WRLabel value="Revenue based on Avg Weekly Income" /></Grid>
                    <Grid item xs={2}><WRValue value="4/10" /></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={2}></Grid>

                    <Grid item xs={5}><WRLabel value="Wages" /></Grid>
                    <Grid item xs={2}><WRValue value="4/10" /></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={2}></Grid>
    */}
                </Grid>

            </CardContent>
        </Card>
    )
}