import React, { useState } from 'react';

import { Card, CardContent, CardHeader, Grid, MenuItem, Paper, TextField } from '@mui/material';

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { dateFormat, SYS_DATE_FORMAT } from '../../_helpers/datetime';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { referralAction } from '../../_actions/referralAction';
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import { fundAction } from '../../_actions/fundAction';

export const RegistrationForm = ({currReferral, close}) => {

    const dispatch = useDispatch();

    const [referral, setReferral] = useState(currReferral);
    const [errors, setErrors] = useState({error: false});
    const [btnSaveLabel, setBtnSaveLabel] = useState('Register');

    const fundTypes = useSelector(state =>  state.fund.types);

    useEnhancedEffect(() => {
        dispatch(fundAction.getFundTypes());
    },[]);

    const handleFormChange = (e) => {
        const {name, value} = e.target;

        setReferral({...referral, [name]: value});
    }

    const handleDatePicker = (name, date) => {
        setReferral({...referral, [name]: date.format("YYYY-MM-DD")}); 
    }

    const validate = () => {
        console.log("validate Forms")
        let formErrors = {error: false};

        if (!referral.firstName || referral.firstName.length <= 0) {
            formErrors = {...formErrors, error: true, firstName: 'Required First Name'};
        }
    
        if (!referral.lastName || referral.lastName.length <= 0) {
            formErrors = {...formErrors, error: true, lastName: 'Required Last Name'};
        }

        if (!referral.nhsNo || referral.nhsNo.length <= 0) {
            formErrors = {...formErrors, error: true, nhsNo: 'Required NHS No'};
        }

        if (!referral.referredDate || referral.referredDate.length <= 0) {
            formErrors = {...formErrors, error: true, referredDate: 'Required Referred Date'};
        }

        if (!referral.contactFirstName || referral.contactFirstName.length <= 0) {
            formErrors = {...formErrors, error: true, contactFirstName: 'Required First Name'};
        }
    
        if (!referral.contactLastName || referral.contactLastName.length <= 0) {
            formErrors = {...formErrors, error: true, contactLastName: 'Required Last Name'};
        }

        if (!referral.contactRelationship || referral.contactRelationship.length <= 0) {
            formErrors = {...formErrors, error: true, contactRelationship: 'Required Relationship'};
        }

        if (!referral.contactNo || referral.contactNo.length <= 0) {
            formErrors = {...formErrors, error: true, contactNo: 'Required Contact No'};
        }

        if (!referral.inquiryChannelID || referral.inquiryChannelID <= 0) {
            formErrors = {...formErrors, error: true, inquiryChannelID: 'Required Inquiry Channel'};
        }

        if (!referral.fundingTypeID || referral.fundingTypeID < 0) {
            formErrors = {...formErrors, error: true, fundingTypeID: 'Required Funding Type'};
        }

        if (!referral.dob || referral.dob.length <= 0) {
            formErrors = {...formErrors, error: true, dob: 'Required Date of Birth'};
        }

        if (!referral.gender || referral.gender.length <= 0) {
            formErrors = {...formErrors, error: true, gender: 'Required Gender'};
        }

        setErrors(formErrors);
        return !formErrors.error;
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (validate()) {
            console.log("True");
            dispatch(referralAction.register(referral));
        }
    }

    return (
        <Card>
            <CardHeader title="Referral Form"></CardHeader>
            <CardContent>
            
                <Grid container spacing={2}>

                    <Grid item xs={6}>

                        <Card>
                            <CardHeader title="Client Information"></CardHeader>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} >
                                        <TextField
                                            autoFocus
                                            name="firstName"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            value={referral.firstName}
                                            onChange={handleFormChange}
                                            error={errors.firstName}
                                            helperText={errors.firstName ? errors.firstName : ''}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="lastName"
                                            name="lastName"
                                            label="Last Name"
                                            value={referral.lastName}
                                            onChange={handleFormChange}
                                            error={errors.lastName}
                                            helperText={errors.lastName ? errors.lastName : ''}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="nhsNo"
                                            name="nhsNo"
                                            label="NHS No"
                                            value={referral.nhsNo}
                                            onChange={handleFormChange}
                                            error={errors.nhsNo}
                                            helperText={errors.nhsNo ? errors.nhsNo : ''}
                                        />
                                    </Grid>

                                    <Grid item xs={4} >
                                        <LocalizationProvider dateAdapter={DateAdapter}>
                                        <DatePicker
                                            label="Date of Birth"
                                            type="date"
                                            mask="__-__-____"
                                            value={referral.dob}
                                            inputFormat={SYS_DATE_FORMAT}
                                            maxDate={moment()}
                                            onChange={(newValue) => {
                                                handleDatePicker('dob', newValue);
                                            }}
                                            renderInput={(params) => 
                                                <TextField {...params} error={errors.dob}
                                                    fullWidth
                                                    disabled
                                                    helperText={errors.dob ? errors.dob : ''} />}
                                        />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item xs={4} sm={4}>
                                        <TextField
                                            id="gender"
                                            select
                                            label="Gender"
                                            name="gender"
                                            value={referral.gender}
                                            onChange={handleFormChange}
                                            variant="outlined"
                                            fullWidth
                                            required
                                            error={errors.gender}
                                                helperText={errors.gender ? errors.gender : ''}
                                        >
                                            <MenuItem key="M" value="M">Male</MenuItem>
                                            <MenuItem key="F" value="F">Female</MenuItem>
                                            <MenuItem key="O" value="3">Other</MenuItem>
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                    </Grid>

                    <Grid item xs={6}>

                        <Card>
                            <CardHeader title="Contact Information"></CardHeader>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} >
                                        <TextField
                                            autoFocus
                                            name="contactFirstName"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="contactFirstName"
                                            label="First Name"
                                            value={referral.contactFirstName}
                                            onChange={handleFormChange}
                                            error={errors.contactFirstName}
                                            helperText={errors.contactFirstName ? errors.contactFirstName : ''}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="contactLastName"
                                            name="contactLastName"
                                            label="Last Name"
                                            value={referral.contactLastName}
                                            onChange={handleFormChange}
                                            error={errors.contactLastName}
                                            helperText={errors.contactLastName ? errors.contactLastName : ''}
                                        />
                                    </Grid>

                                    <Grid item xs={6} >
                                        <TextField
                                            autoFocus
                                            name="contactRelationship"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="contactRelationship"
                                            label="Relationship"
                                            value={referral.contactRelationship}
                                            onChange={handleFormChange}
                                            error={errors.contactRelationship}
                                            helperText={errors.contactRelationship ? errors.contactRelationship : ''}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="contactNo"
                                            name="contactNo"
                                            label="Contact No"
                                            value={referral.contactNo}
                                            onChange={handleFormChange}
                                            error={errors.contactNo}
                                            helperText={errors.contactNo ? errors.contactNo : ''}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="contactEmail"
                                            name="contactEmail"
                                            label="Contact Email"
                                            value={referral.contactEmail}
                                            onChange={handleFormChange}
                                            error={errors.contactEmail}
                                            helperText={errors.contactEmail ? errors.contactEmail : ''}
                                        />
                                    </Grid>

                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={3} >
                        <LocalizationProvider dateAdapter={DateAdapter}>
                        <DatePicker
                            label="Referred Date"
                            type="date"
                            mask="__-__-____"
                            value={referral.referredDate}
                            inputFormat={SYS_DATE_FORMAT}
                            maxDate={moment()}
                            onChange={(newValue) => {
                                handleDatePicker('referredDate', newValue);
                            }}
                            renderInput={(params) => <TextField {...params} error={errors.referredDate}
                            fullWidth
                            disabled
                            helperText={errors.referredDate ? errors.referredDate : ''} />}
                        />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={4} sm={4}>
                        <TextField
                            id="inquiryChannelID"
                            select
                            label="Inquiry Channel"
                            name="inquiryChannelID"
                            value={referral.inquiryChannelID}
                            onChange={handleFormChange}
                            variant="outlined"
                            fullWidth
                            required
                            error={errors.inquiryChannelID}
                                helperText={errors.inquiryChannelID ? errors.inquiryChannelID : ''}
                        >
                            {/*jobRoles.map(jobRole => <MenuItem key={jobRole.id} value={jobRole.id}>{jobRole.name}</MenuItem>)*/}
                            <MenuItem key="1" value="1">Direct</MenuItem>
                            <MenuItem key="2" value="2">Hospital</MenuItem>
                            <MenuItem key="2" value="3">Web Inquiry</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={5} >
                        <TextField
                            id="fundingTypeID"
                            select
                            label="Funding Type"
                            name="fundingTypeID"
                            value={referral.fundingTypeID}
                            onChange={handleFormChange}
                            variant="outlined"
                            fullWidth
                            required
                            error={errors.fundingTypeID}
                                helperText={errors.fundingTypeID ? errors.fundingTypeID : ''}
                        >
                            
                            <MenuItem key="0" value="0">Not Known For now</MenuItem>
                            {fundTypes.map(fundType => <MenuItem key={fundType.id} value={fundType.id}>{`${fundType.name}`}</MenuItem>)}
                            
                        </TextField>
                    </Grid>

                    <Grid item xs={12}></Grid>
                    <Grid item xs={8}></Grid>

                    <Grid item xs={2} >
                        <LoadingButton
                            loading={false}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            type="submit"
                            onClick={onSubmit}
                        >
                            {btnSaveLabel}
                        </LoadingButton>

                    </Grid>

                    <Grid item xs={2} >
                        <LoadingButton
                            loading={false}
                            loadingPosition="start"
                            startIcon={<CloseIcon />}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={close}
                        >
                            Close
                        </LoadingButton>

                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}