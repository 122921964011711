import React ,{useState} from "react";
import { Card, CardContent, CardHeader, Grid, TextField } from '@mui/material';

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { SYS_DATE_FORMAT } from "../../_helpers/datetime";
import { LoadingButton } from "@mui/lab";

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

export const AdmissionForm = ({dateType, onCancel, onSave}) => {
    const [txnDate, setTxnDate] = useState();

    const [errors, setErrors] = useState({});

    const handleDatePicker = (name, date) => {
        //setOccupy({...occupy, [name]: date.format("YYYY-MM-DD")}); 
    }

    const onSubmit = (e) => {
        e.preventDefault();
        onSave(dateType, txnDate);
    }

    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        { (dateType === 'admission') ? 
                        <LocalizationProvider dateAdapter={DateAdapter}>
                        <DatePicker
                            mask="__-__-____"
                            label="Admission Date"
                            type="date"
                            value={txnDate}
                            inputFormat={SYS_DATE_FORMAT}
                            onChange={(newValue) => {
                                setTxnDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} error={errors.txnDate}
                            helperText={errors.txnDate ? errors.txnDate : ''} />}
                        />
                        </LocalizationProvider>

                        : '' }

                        {(dateType === 'discharge') ? 
                        <LocalizationProvider dateAdapter={DateAdapter}>
                        <DatePicker
                            mask="__-__-____"
                            label="Discharge Date"
                            type="date"
                            value={txnDate}
                            inputFormat={SYS_DATE_FORMAT}
                            onChange={(newValue) => {
                                setTxnDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} error={errors.txnDate}
                            helperText={errors.txnDate ? errors.txnDate : ''} />}
                        />
                        </LocalizationProvider>

                        : '' }
                    </Grid>

                    <Grid item xs={3} >
                        <LoadingButton
                            loading={false}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                            onClick={onSubmit}
                        >
                            OK
                        </LoadingButton>
                    </Grid>
                    
                    <Grid item xs={3} >
                        <LoadingButton
                            loading={false}
                            loadingPosition="start"
                            startIcon={<CloseIcon />}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            type="submit"
                            onClick={(e) => onCancel('admission')}
                        >
                            Close
                        </LoadingButton>

                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}