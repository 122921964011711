import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography } from "@mui/material";
import React from "react";
import { InfoBlock } from "../ui/InfoBlock";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { dateFormat } from "../../_helpers/datetime";
import { displayChannelName } from "../../_helpers/channelHelper";

export const ReferralDetail = ({referral}) => {

    const {referenceNo, referredDate, inquiryChannel} = referral;

    return (

        <Paper sx={{padding: 2}}>
            
            <Typography marginBottom={2} variant="h6">Referral Detail</Typography>
            <InfoBlock label={"Referance No "} value={referenceNo} valueType={"text"} />
            <InfoBlock label={"Referred Date"} value={dateFormat(referredDate)} valueType={"date"} />
            <InfoBlock label={"Channel"} value={displayChannelName(inquiryChannel)} valueType={"text"} />
     
        </Paper>
    )

}