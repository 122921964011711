import React from 'react';
import { Box, Typography } from '@mui/material';
import { localCurrency, numberFormat } from '../../_helpers/number';

export const InfoBlock = ({label, value, valueType}) => {

  function valueString (value, valueType) {
    switch (valueType) {
      case 'curr':
        return localCurrency(value);
        break;
      case 'num':
        return numberFormat(value);
        break;
      default:
        return value;
    }
  }


    return (
        <Box  alignContent={"start"} >
          <Typography color="text.secondary"> {label} </Typography>

          <Typography variant="h6" component="div" sx={{ mb: 1.5 }}>
            {valueString(value, valueType)}
          </Typography>
        </Box>
    )
}


export const WRLabel = ({value}) => {
  return (
      <Box
      sx={{
          backgroundColor: '#87C1FF',
          padding: 1,
          borderRadius: '10px'
        }}
      >
          {value}
      </Box>
  )
}

export const WRValue = ({value}) => {
  return (
      <Box
      sx={{
          backgroundColor: '#C2C2C2',
          padding: 1,
          borderRadius: '10px',
          textAlign: "center"
        }}
      >
          {value}
      </Box>
  )
}