import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, CardContent, CardHeader, Grid, Link, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import SaveIcon from '@mui/icons-material/Save';
import { useDispatch, useSelector } from "react-redux";
import { referralAction } from "../../_actions/referralAction";
import { roomAction } from "../../_actions/roomAction";
import { localCurrency } from "../../_helpers/number";
import { referral } from "../../_reducers/referralReducer";

export const RoomAllocation = ({referral}) => {

    const dispatch = useDispatch();

    const workingLocation = useSelector(state => state.location.working);

    const [errors, setErrors] = useState([]);

    const rooms = useSelector(state => state.room.nonOccupiedRooms);

    const [selectedRoomID, setSelectedRoomID] = useState(0);

    useEffect(() => {
        dispatch(roomAction.getNonOccupiedRooms(workingLocation.id))
        if (referral.room) {
            setSelectedRoomID(referral.room.id);
        }
        
    },[referral.id > 0])

    const submitForm = (e) => {
        e.preventDefault();

        if (validate()) {
            dispatch(referralAction.updateRoom(referral.id, selectedRoomID));
        }

    } 

    const validate = () => {
        console.log("validate Forms")
        let formErrors = {error: false};

        if (selectedRoomID <= 0) {
            formErrors = {...formErrors, error: true, selectedRoomID: 'Room not selected'};
        }

        setErrors(formErrors);
        return !formErrors.error;
    }

    return (
        <Card>
            <CardHeader title="Allocate Room" />

            <CardContent>
                <Grid container spacing={1}>
                 

               

                {rooms.map( room => 
                    (room.isOccupied == 'N' || (referral.room && room.isOccupied == 'R' && room.id == referral.room.id)) ? (
                <Grid item>

                    <Paper
                        variant="outlined"
                        border={1}
                        sx={{
                            width: 280,
                            bgcolor: selectedRoomID === room.id ? '#c5e1a5' : '',
                            transition: "transform 0.15s ease-in-out",
                            "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
                          }}
                          onClick={() => setSelectedRoomID(room.id)}
                        >
                            <Typography variant="h6" gutterBottom component="div" ml={1}>
                        Room No : {room.roomNo}
                    </Typography>


                    <Box
                        component="span"
                        m={1}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={12}
                    >
                    
                    <Typography variant="overline" display="block" gutterBottom alignItems="flex-start" >
                      {room.roomType.name}
                    </Typography>

                    <Typography variant="overline" display="block" gutterBottom>
                      {localCurrency(room.rate)}
                    </Typography>

                    </Box>
                    </Paper>

                </Grid>
                    ) : ''
                 )}
                  </Grid>

                <Grid container spacing={2}>
                <Grid item xs={9}>
                        
                        </Grid>
    
                        <Grid item xs={3} >
                            <LoadingButton
                                loading={false}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                type="submit"
                                onClick={submitForm}
                            >
                                Ok
                            </LoadingButton>
    
                        </Grid>
                </Grid>
            </CardContent>
        </Card>

    );
}