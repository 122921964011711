import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material"

export const RightTile = ({title, main, secondary}) => {
    return (
        <Card>
            <CardHeader title={<Typography variant="button">{title}</Typography>} sx={{padding: 1, backgroundColor: 'success.light', color: 'white'}} />

            <CardContent p={0} m={0}>
            <Box
      sx={{
          
          padding: 1,
          borderRadius: '10px',
          textAlign: "center"
          
        }}
      >
          <Typography> {main} </Typography>
          <Typography> {secondary} </Typography>
        </Box>
            </CardContent>
        </Card>
    )
}