import { Button, Container, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import moment from 'moment';

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { LoadingButton } from "@mui/lab";

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from "react-redux";
import { updateLocale } from "moment";
import { SYS_DATE_FORMAT } from "../../_helpers/datetime";

export const EmployeeForm = ({setEditing, jobRoles, currEmployee, create, update, close}) => {

    const [employee, setEmployee] = useState({...currEmployee, jobRoleID: currEmployee.jobRole ? currEmployee.jobRole.id : ''});
    const [errors, setErrors] = useState({error: false});

    const loading = useSelector(state => state.employee.loading);
    const locations = useSelector(state => state.location.list);

    const [formTitle, setFormTitle] = useState('Employee');
    const [btnSaveLabel, setBtnSaveLabel] = useState('Create');


    useEffect(() => {
        console.log(currEmployee);
        if (currEmployee.id > 0) {
            setBtnSaveLabel('Update');
            setFormTitle("Edit Employee");
            setEmployee({...currEmployee, locationID: currEmployee.location.id, jobRoleID: currEmployee.jobRole.id});  
        } else {
            setBtnSaveLabel('Create');
            setFormTitle("Register New Employee")
            setEmployee({...currEmployee, joinDate: moment().format("YYYY-MM-DD"), leftDate: ''});
        }
    },[currEmployee])

    const handleFormChange = (e) => {
        console.log("handleFormChange");

        const {name, value} = e.target;

        setEmployee({...employee, [name]: value});
  
    }

    const handleDatePicker = (name, date) => {
        setEmployee({...employee, [name]: date.format("YYYY-MM-DD")});
        
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate() ) {
            if (employee.id > 0) {
                update(employee);
            } else {
                create(employee);
            }
        }

    }

    const validate = () => {
        console.log("validate Forms")
        let formErrors = {error: false};

        if (!employee.firstName || employee.firstName.length <= 0) {
            formErrors = {...formErrors, error: true, firstName: 'Required First Name'};
        }
    
        if (!employee.lastName || employee.lastName.length <= 0) {
            formErrors = {...formErrors, error: true, lastName: 'Required First Name'};
        }

        if (!employee.jobRoleID || employee.jobRoleID.length <= 0) {
            formErrors = {...formErrors, error: true, jobRoleID: 'Required First Name'};
        }

        if (!employee.dob || moment(employee.dob).format("YYYYMMDD") >= moment().format("YYYYMMDD")) {
            formErrors = {...formErrors, error: true, dob: 'Invalid Date of Birth'};
        }

        setErrors(formErrors);
        return !formErrors.error;
    }
    
    return (
        <Container style={{height: "85vh"}} >
            <Box style={{   marginTop: 8, 
                            display: 'flex',
                            flexDirection: 'column',
                           }}>

                <Typography component="h1" variant="h5" mb={4} align="left">
                    {formTitle}
                </Typography>

            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            autoFocus
                            autoComplete="fname"
                            name="firstName"
                            variant="outlined"
                            required
                            fullWidth
                            id="firstName"
                            label="First Name"
                            value={employee.firstName}
                            onChange={handleFormChange}
                            error={errors.firstName}
                            helperText={errors.firstName ? errors.firstName : ''}
                          />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="lastName"
                            name="lastName"
                            label="Last Name"
                            value={employee.lastName}
                            onChange={handleFormChange}
                            error={errors.lastName}
                            helperText={errors.lastName ? errors.lastName : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="middleName"
                            variant="outlined"
                            fullWidth
                            id="middleName"
                            label="Middle Name"
                            value={employee.middleName}
                            onChange={handleFormChange}
                            error={errors.middleName}
                            helperText={errors.middleName ? errors.middleName : ''}
                          />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="otherName"
                            name="otherName"
                            label="Other Name"
                            value={employee.otherName}
                            onChange={handleFormChange}
                            error={errors.otherName}
                            helperText={errors.otherName ? errors.otherName : ''}
                        />
                    </Grid>

                    <Grid item xs={12} align="left">
                        <LocalizationProvider dateAdapter={DateAdapter}>
                        <DatePicker
                            label="Date of Birth"
                            type="date"
                            value={employee.dob}
                            inputFormat={SYS_DATE_FORMAT}
                            onChange={(newValue) => {
                                handleDatePicker('dob', newValue);
                            }}
                            renderInput={(params) => <TextField {...params} error={errors.dob}
                            helperText={errors.dob ? errors.dob : ''} />}
                        />
                        </LocalizationProvider>

                      
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <TextField
                            id="jobRoleID"
                            select
                            label="Main Role"
                            name="jobRoleID"
                            value={employee.jobRoleID}
                            onChange={handleFormChange}
                            variant="outlined"
                            fullWidth
                            error={errors.jobRoleID}
                                helperText={errors.jobRoleID ? errors.jobRoleID : ''}
                        >
                            {jobRoles.map(jobRole => <MenuItem key={jobRole.id} value={jobRole.id}>{jobRole.name}</MenuItem>)}
                        </TextField>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="contractedHours"
                            name="contractedHours"
                            label="Contracted Hours"
                            value={employee.contractedHours}
                            onChange={handleFormChange}
                            error={errors.contractedHours}
                            helperText={errors.contractedHours ? errors.contractedHours : ''}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <LocalizationProvider dateAdapter={DateAdapter}>
                        <DatePicker
                            label="Join Date"
                            type="date"
                            value={employee.joinDate}
                            inputFormat={SYS_DATE_FORMAT}
                            onChange={(newValue) => {
                                handleDatePicker('joinDate', newValue);
                            }}
                            renderInput={(params) => <TextField {...params} error={errors.joinDate}
                            helperText={errors.joinDate ? errors.joinDate : ''} />}
                        />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} sm={3} >
                        <LocalizationProvider dateAdapter={DateAdapter} >
                        <DatePicker
                            label="Left Date"
                            type="date"
                            value={employee.leftDate}
                            inputFormat={SYS_DATE_FORMAT}
                            onChange={(newValue) => {
                                handleDatePicker('leftDate', newValue);
                            }}
                            disabled={btnSaveLabel === 'Create'}
                            renderInput={(params) => <TextField {...params} error={errors.leftDate}
                            helperText={errors.leftDate ? errors.leftDate : ''} />}
                        />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12}></Grid>
                    <Grid item xs={6}></Grid>

                    <Grid item xs={3} >
                        <LoadingButton
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            type="submit"
                        >
                            {btnSaveLabel}
                        </LoadingButton>

                    </Grid>

                    <Grid item xs={3} >
                        <LoadingButton
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<CloseIcon />}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={close}
                        >
                            Close
                        </LoadingButton>

                    </Grid>
                </Grid>

                
            </form>
            </Box>
        </Container>
    )
}