export const referralConstant = {
    REQUEST : 'REFERRAL_REQUEST',
    RESET: 'REFERRAL_RESET',

    SUBMIT : 'REFERRAL_SUBMIT',
    SUBMIT_SUCCESS : 'REFERRAL_SUBMIT_SUCCESS',
    SUBMIT_ERROR: 'REFERRAL_SUBMIT_ERROR',

    GET_REFERRALS_REQUEST: 'REFERRAL_GET_REFERRALS_REQUEST',
    GET_LOCATION_STATUS_SUCCESS: 'REFERRAL_GET_LOCATION_STATUS_SUCCESS',
    GET_LOCATION_STATUS_ERROR: 'REFERRAL_GET_LOCATION_STATUS_ERROR',

    SUBMIT_UPDATE_SUCCESS: 'REFERRAL_SUBMIT_UPDATE_SUCCESS',
    CLOSE_SUCCESS: 'REFERRAL_CLOSE_SUCCESS',

    SET_CURR: 'REFERRAL_SET_CURR',

    GET_SR_REQUEST: 'REFERRAL_GET_SR_REQUEST',
    GET_SR_SUCCESS: 'REFERRAL_GET_SR_SUCCESS',
    GET_SR_ERROR: 'REFERRAL_GET_SR_ERROR',

    GET_FUNDS_SUCCESS: 'REFERRAL_GET_FUNDS_SUCCESS',

    GET_REASONS_SUCCESS : 'REFERRAL_GET_REASONS_SUCCESS',
    GET_REASONS_ERROR: 'REFERRAL_GET_REASONS_ERROR',
    
}