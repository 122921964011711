import { appConstant } from '../_constants/appConstant';

export const appActions = {
    startLoading,
    stopLoading,

    
};

function startLoading() {
    return { type: appConstant.START_LOADING };
}

function stopLoading() {
    return { type: appConstant.STOP_LOADING };
}