import { Button, Card, CardActions, CardContent, CardHeader, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";

import EditIcon from '@mui/icons-material/Edit';

import { localCurrency } from '../../_helpers/number';
import { CYCLES } from "../../_constants/appConstant";

export const Funds = ({funds, onEdit, enableEdit}) => {
    const [total, setTotal] = useState(0);

    useEffect(() => {
        let fundTotal = funds.map(fund => parseFloat(fund.amount)).reduce((prevVal, currVal) => prevVal + currVal, 0);
        setTotal(fundTotal);
    }, [funds]);

    function displayCycleName(code) {
        return CYCLES.find(cycle => cycle.code === code).name;
    }

    return (
        <Card>
            <CardHeader title="Fund Detail" />

            <CardContent>
                <Grid container spacing={2}>
                {funds && funds.map(fund => 
                    <>
                    <Grid item xs={6}> {fund.fundName}</Grid>
                    <Grid item xs={2}> {displayCycleName(fund.cycle)}</Grid>
                    <Grid item xs={4} textAlign={"end"}> {localCurrency(fund.amount)}</Grid>
                    </>
                )}
                
                    <Grid item xs={8}>  </Grid>
                    <Grid item xs={4} textAlign={"end"}>______________  </Grid>

                    <Grid item xs={8}> Total </Grid>
                    <Grid item xs={4} textAlign={"end"}> {localCurrency(total)}</Grid>

                </Grid>
            </CardContent>

{/*
            <CardActions> 
                <Button size='small' color='primary' startIcon={<EditIcon />} onClick={(e) => { e.preventDefault(); onEdit('fund')}} disabled={!enableEdit}>
                    Edit
                </Button>
                </CardActions>*/}
        </Card>
    )
}