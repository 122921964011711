import { Card, CardContent, Divider, Typography } from "@mui/material";
import React, { PureComponent } from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";


const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
        name: 'Page H',
        uv: 1890,
        pv: 4800,
        amt: 0,
      },
      {
        name: 'Page I',
        uv: 2390,
        pv: 3800,
        amt: 2500,
      },
      {
        name: 'Page J',
        uv: 3490,
        pv: 4300,
        amt: 2100,
      },
  ];

export default class StackedBarChart extends PureComponent {

    render()  {
        return (
            <Card>
                <CardContent>
                    <Typography
                        gutterBottom
                        color="textPrimary"
                    >
                        {this.props.title.toUpperCase()}
                    </Typography>
                    
                    <Divider />
                    
                    <br/>

                    <ResponsiveContainer width="100%" height={450}>
                        <BarChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                              top: 20,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                        
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="pv" stackId="a" fill="#8884d8" />
                            <Bar dataKey="uv" stackId="a" fill="#82ca9d" />
                            <Bar dataKey="amt" stackId="a" fill="#dddddd" />
                        </BarChart>
                    </ResponsiveContainer>
                    
                </CardContent>
            </Card>
    )};

}