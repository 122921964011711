import { Box, CircularProgress, IconButton, MenuItem, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { referralAction } from "../../_actions/referralAction";
import { ReferralList } from "./list";
import { RegistrationForm } from "./RegistrationForm";
import { useDispatch } from 'react-redux';
import { Detail } from "./Detail";
import { careBandAction } from "../../_actions/careBandAction";
import { alertActions } from "../../_actions/alertAction";

import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import { Mentions } from "antd";

const initialState = {
    id: 0,
    firstName: '',
    lastName: '',
    nhsNo: '',
    dob: '',
    gender: '',
    referredDate: '',
    locationID: 0,

    contactFirstName: '',
    contactLastName: '',
    contactRelationship: '',
    contactNo: '',
    contactEmail: '',

    inquiryChannelID: '',
    fundingTypeID: '',
}

export const Referral = () => {

    const dispatch = useDispatch();

    const workingLocation = useSelector(state => state.location.working);
    const referrals = useSelector(state => state.referral.referrals);
    const referral = useSelector(state => state.referral.currReferral);

    const [txnType, setTxnType] = useState("VIEW");
    const [isEditing, setIsEditing] = useState(false);
    const [status, setStatus] = useState("P");

    const [currReferral, setCurrReferral] = useState({...initialState, locationID: workingLocation.id});

    const loading = useSelector(state => state.referral.listLoading);

    //newEmployee({...initialState, locationID: workingLocation.id});

    useEffect(() => {
        console.log("User Returned to page");
        dispatch(referralAction.reset());
        setTxnType("VIEW");
    }, []);

    useEffect(() => {

        if (workingLocation.id > 0) {
            dispatch(referralAction.getLocationReferrals(workingLocation, status));    
        }
        
        dispatch(careBandAction.getAll());

    }, [workingLocation, status]);

    useEffect(() => {
        console.log(referral)
        if (referral.id > 0) {
            setCurrReferral(referral);
            setIsEditing(true);
            setTxnType("DETAIL"); 
        }

    }, [referral]);

    const newreferral = (e) => {
        e.preventDefault();
        setTxnType("NEW");
        console.log(workingLocation);
        setCurrReferral({...initialState, locationID: workingLocation.id});
        setIsEditing(true);
        
    }

    const loadCurrReferral = (loadReferral) => {
        dispatch(referralAction.setCurrReferral(loadReferral));
        setCurrReferral(loadReferral);
        setIsEditing(true);
        setTxnType("DETAIL");

    }

    const create = () => {

    }
    const update = () => {
        
    }

    const closeForm = () => {
        setTxnType("VIEW");
        setIsEditing(false);
        dispatch(alertActions.clear());
    }

    return (
        <>
        <Paper style={{padding: 12, color: 'red', marginBottom: 8}}>
        <Box display="flex">
            <Box flexGrow={1} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Referrals
              </Typography>

            </Box>

            <Box>
            <Stack direction={"row"} spacing={1}>
                {!isEditing &&

                    <IconButton aria-label="Add" size="large"  onClick={newreferral} color='primary'>
                        <AddCircleOutlineSharpIcon fontSize="inherit" />
                    </IconButton>

                }

                {!isEditing &&
                <TextField
                    id="status"
                    select
                    label="Status"
                    name="status"
                    value={status}
            
                    onChange={(e) => setStatus(e.target.value )}
                    variant="outlined"
                    
                >
                    <MenuItem key={"A"} value={"A"}>All</MenuItem>
                    <MenuItem key={"P"} value={"P"}>Pending</MenuItem>
                </TextField>
                }

                {(currReferral.id > 0 ) &&
                    <IconButton aria-label="Close" size="large"  onClick={closeForm} >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                </Stack>
            </Box>
        </Box>
        </Paper>


        {isEditing ? 
            <>
            
            {txnType === "NEW" && <RegistrationForm currReferral={currReferral} create={create} update={update} close={closeForm} />}
            {txnType === "DETAIL" && <Detail referral={currReferral} />}
            </>
            : (loading ?               
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>     
                : <ReferralList referrals={referrals} loadCurrReferral={loadCurrReferral}/>)
            }
        </>
    )
}