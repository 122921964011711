import { Button, Card, CardActions, CardContent, CardHeader, Grid, Paper, TextField } from '@mui/material';
import React, { useState } from 'react';
import { localCurrency, numberFormat } from '../../_helpers/number';
import { InfoBlock } from '../ui/InfoBlock';
import { dateFormat } from '../../_helpers/datetime';


import EditIcon from '@mui/icons-material/Edit';

export const PersonalDetail = ({txnMode, firstName, lastName, middleName, otherName, dob, leftDate, onEdit}) => {


    return (
        <Card>
            <CardHeader title="Personal Detail"></CardHeader>
            <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                <InfoBlock label={"First Name "} value={firstName} valueType={"text"}/>
                </Grid>
                <Grid item xs={2}>
                <InfoBlock label={"Last Name"} value={lastName} valueType={"text"}/>
                </Grid>
                <Grid item xs={2}>
                <InfoBlock label={"Middle Name "} value={middleName} valueType={"text"}/>
                </Grid>
                <Grid item xs={2}>
                <InfoBlock label={"Other Name "} value={otherName} valueType={"text"}/>
                </Grid>
                <Grid item xs={2}>
                <InfoBlock label={"Date of Birth "} value={dateFormat(dob)}/>
            </Grid>
            </Grid>
            </CardContent>

            {txnMode !== 'V' &&
            <CardActions>
                <Button size='small' color='primary' startIcon={<EditIcon />} onClick={(e) => { e.preventDefault(); onEdit('admission')}} disabled={leftDate}>
                    Edit
                </Button>
            </CardActions> }
        </Card>
    )
}