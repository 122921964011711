import { authConstant } from "../_constants/authConstant";

const initialState = {
            loading: false,
            isAuth: false,
        };

export function auth(state = initialState, action) {
    switch (action.type) {
        case authConstant.REQUEST: 
            return {
                ...state,
                loading: true,
            }
        case authConstant.LOGIN_SUCCESS:
            return {
                ...state,
                username: action.user.username,
                token: action.user.jwttoken,
                roles: action.user.roles,
                isAuth: true,
                loading: false
            }
        case authConstant.LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
}