import { dashboardConstant } from "../_constants/dashboardConstant";

const initialState = {
            loading: false,
            today: {},
            working: {id:0},
            falls: {},
            seriousInjury: {},
            pressureInjury: {},
            infections: {},
            safeguarding: {},
            transferToHospital: {},
            cqcNotification: {},
            referral: {},
        };

export function dashboard(state = initialState, action) {
    switch (action.type) {
        case dashboardConstant.REQUEST: 
            return {
                ...state,
                loading: true,
            }
        case dashboardConstant.GET_MONTH_SUCCESS:
            return {
                ...state,
                today: action.dashboard,
                loading: false
            }
        case dashboardConstant.GET_WEEK_SUCCESS:
            return {
                ...state,
                room: action.dashboard,
                loading: false
            }
        case dashboardConstant.GET_REFERRAL_P_REQUEST: 
            return {
                ...state,
                referralP: 0,
                referralloading: true,
            }
        case dashboardConstant.GET_REFERRAL_P_SUCCESS:
            return {
                ...state,
                referralP: action.count,
                referralloading: false,
            }
        case dashboardConstant.GET_FALLS_SUCCESS:
            return {
                ...state,
                falls: action.dsDetail,
                fallsLoading: false,
            }
        case dashboardConstant.GET_SERIOUS_INJURY_SUCCESS: 
            return {
                ...state,
                seriousInjury: (action.dsDetail == null ? {} : action.dsDetail),
            }
        case dashboardConstant.GET_PRESSURE_INJURY_SUCCESS: 
            return {
                ...state,
                pressureInjury: (action.dsDetail == null ? {} : action.dsDetail),
            }
        case dashboardConstant.GET_INFECTIONS_SUCCESS: 
            return {
                ...state,
                infections: (action.dsDetail == null ? {} : action.dsDetail),
            }
        case dashboardConstant.GET_SAFEGUARDING_SUCCESS: 
            return {
                ...state,
                safeguarding: (action.dsDetail == null ? {} : action.dsDetail),
            }
        case dashboardConstant.GET_TRANSFERTOHOSPITAL_SUCCESS: 
            return {
                ...state,
                transferToHospital: (action.dsDetail == null ? {} : action.dsDetail),
            }
        case dashboardConstant.GET_CQCNOTIFICATION_SUCCESS: 
            return {
                ...state,
                cqcNotification: (action.dsDetail == null ? {} : action.dsDetail),
            }
        case dashboardConstant.GET_REFERRAL_SUCCESS: 
            return {
                ...state,
                referral: (action.dsDetail == null ? {} : action.dsDetail),
            }
        default:
            return state;
    }
}