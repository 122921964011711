import { Button, Card, CardContent, CardHeader } from '@mui/material';
import React from 'react';
import { dateFormat } from '../../_helpers/datetime';
import { InfoBlock } from '../ui/InfoBlock';

export const Resident = ({resident, edit}) => {

    const {id, firstName, lastName, preferredName, niNo, nhsNo, gender, dob} = resident;

    function dispalyGender (code) {
        switch (code) {
            case 'M':
                return 'Male';
            case 'F':
                return 'Female';
            default:
                return 'Other';
        }
    }

    return (
        <Card>
            <CardHeader title="Resident Detail"></CardHeader>
            <CardContent>
            
                <InfoBlock label={"First Name "} value={firstName} />
                <InfoBlock label={"Last Name"} value={lastName} />
                <InfoBlock lable={"Preferred Name"} value={preferredName} />
                <InfoBlock label={"Identity "} value={niNo} />
                <InfoBlock label={"NHS No"} value={nhsNo} />
                <InfoBlock label={"Date of Birth "} value={dateFormat(dob)} />
                <InfoBlock label={"Gender "} value={dispalyGender(gender)} />

            </CardContent>
        </Card>
    )
}