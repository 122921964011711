import { Box, Button, CircularProgress, Grid, Link, Paper, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InfoBlock } from "../ui/InfoBlock";
import { AssessmentForm } from "./AssessmentForm";
import { EvaluationForm } from "./EvaluationForm";
import { DocumentGeneratePrint } from "./DocumentGeneratePrint";
import { FundForm } from "./FundForm";
import { referralAction } from "../../_actions/referralAction";
import { useDispatch, useSelector } from "react-redux";
import { AdmissionConfirmation } from "./AdmissionConfirmation";
import { RoomAllocation } from "./RoomAllocation";
import { AdmitForm } from "./AdmitForm";
import { CloseReferral } from "./CloseReferral";
import { Dialog } from "../ui/Dialog";


export const Tasks = ({referral}) => {

  const dispatch = useDispatch();
  const steps = useSelector(state => state.referral.currReferral.steps);
  const referralID = useSelector(state => state.referral.currReferral.id);

  const [openDilaog, setOpenDialog] = useState(false);
  const [moveToStep, setMoveToStep] = useState({})

  const tasksLoading = useSelector(state => state.referral.currReferral.tasksLoading);

  useEffect(() => {
      console.log(referral)
      let nextStep = referral.stage + 1;
      setActiveStep(referral.stage);
  }, [referral])

  useEffect(() => {
    dispatch(referralAction.getTasks(referral.id));
    dispatch(referralAction.getFunds(referral.id));
  }, [referral.stage])

    const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    console.log(activeStep)
  },[activeStep])


  const loadTaskComponent = ({index}) => {
    switch (index) {
        case 0:
            return 'Registerd';
        
        case 1:
            return <AssessmentForm />
        default:
            return (
                <>Defalut</>
                );
    }
  }

  const moveTo = (step) => {
    setOpenDialog(true);

    setMoveToStep(step);
  }

  const moveToOk = () => {
    console.log("moveToOk")
    setOpenDialog(false);

    // Take action to move
    dispatch(referralAction.openFrom(referralID, moveToStep));
  }

  
    return (

        <Grid container spacing={2}>

            {openDilaog && <Dialog open={openDilaog} handleClose={() => setOpenDialog(false)} handleOk={moveToOk} message={`Please confirm to move to ${moveToStep.srStage.name}`}/>}

            <Grid item xs="4">
        
              <Box sx={{ maxWidth: 800 }}>
                
                {tasksLoading 
                  ? <CircularProgress />
                  :

                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.id}>
                      <StepLabel
                        
                      >
                        
                        { referral.status === 'P' && step.status === 'D' && step.srStage.id > 1 ? <Link href="#" underline="none" onClick={() => moveTo(step)}>
                                                  {step.srStage.name}
                                                </Link> : step.srStage.name}
                      
                      </StepLabel>
                        
                    </Step>
                  ))}
                </Stepper>
}
                {/*activeStep === steps.length && (
                  <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                      Reset
                    </Button>


                  </Paper>
                )*/}

                {/*
                <Paper square elevation={0} sx={{ p: 3 }}>
                  <Typography>All steps completed - you&apos;re finished</Typography>
                  <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                    Reset
                  </Button>

                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </Paper>
                 */}
              </Box>

            </Grid>

            <Grid item xs="8">

              {(() => {
                switch (activeStep) {
                case 0:
                    return <></>
                case 1:
                    return <EvaluationForm updateField="isQualified" referral={referral} referredDate={referral.referredDate}/>
                case 2:
                    return <AssessmentForm updateField="isSutable2" referral={referral} />
                case 3:
                  return <AdmissionConfirmation referral={referral} referredDate={referral.referredDate}/>
                case 4:
                    return <RoomAllocation referral={referral} />
                case 5: 
                    return <FundForm referral={referral}/>
                case 6:
                  return <DocumentGeneratePrint referral={referral} />
                case 7:
                  return <AdmitForm referal={referral} />
                case 8:
                  return <CloseReferral referral={referral} />
                default:
                    return null
                }
              })()}
                        
            </Grid>
      </Grid>
  );
}