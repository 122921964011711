import logo from './logo.svg';
//import './App.css';
import { Header } from './layout/Header';
import { SideMenu } from './layout/SideMenu';
import { AppContainer } from './layout/AppContainer';
import { Alert, AlertTitle, Backdrop, CircularProgress, createTheme, CssBaseline, ThemeProvider, Typography } from '@mui/material';
import { purple, green } from '@mui/material/colors';
import { Login } from './pages/Login';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions } from './_actions/alertAction';
import { Route, Routes, useLocation } from 'react-router-dom';

function App() {

  const theme = createTheme({
    palette: {
      primary: {
        main: "#1ABC9C", //#4a148c
      },
      secondary: {
        main: green[500],
      },
      background: {
        default: "#eceef1"
      }
    },
    typography: {
      subtitle1: {
        color: purple[300],
      },
      subtitle2: {
        color: purple[700],
      }
    },
    select: {
      '&:before': {
          borderColor: "white",
      },
      '&:after': {
          borderColor: "red",
      }
  },
  icon: {
      fill: "red",
  },
  });

  const dispatch = useDispatch();
  const location = useLocation();
  const workingLocation = useSelector(state => state.location.working);

  useEffect(() => {
    console.log("Location changed");
    dispatch(alertActions.clear());
    console.log(process.env)
  }, [location, workingLocation]);

  
  const isAuth = useSelector(state => state.auth.isAuth);

  const loading = useSelector(state => state.skapp.loading);

  const handleClose = () => {

  }
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      {/*<div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Typography variant={"h2"}>Changing Preset...</Typography>
          <CircularProgress />
        </div>*/}

<Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    
      {isAuth ? <>
      <Header />
      <SideMenu />
      <AppContainer /> </>
      : <Login /> }
      
    </ThemeProvider>
  );
}

export default App;
