import { useEffect, useState } from 'react';

import { Box, Grid, IconButton, Paper } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

import { InfoBlock } from "../ui/InfoBlock"

const initialState = {witnessed: '', };

export const FallsAdditionalInfo = ({checkListItems, edit, enableEdit}) => {

    const [data, setData] = useState(initialState);

    useEffect(() => {
        
        if (checkListItems) {
            console.log("Checklist mapper Effect")
            console.log(checkListItems)
            let mappedData = initialState;
            
            checkListItems.map(item => {
                if (item.itemID == 11) {
                    mappedData = {...mappedData, witnessed: (item.txnValue === "Y" ? "Yes" : "No")}
                } else if (item.itemID == 12) {
                    mappedData = {...mappedData, employee: item.referenceDataValue};
                } else if (item.itemID == 13) {
                    mappedData = {...mappedData, fallArea: item.referenceDataValue}
                } else if (item.itemID == 14) {
                    mappedData = {...mappedData, injuryType: item.referenceDataValue}
                } else if (item.itemID == 15) {
                    mappedData = {...mappedData,}
                } else if (item.itemID == 16) {
                    mappedData = {...mappedData, incidentFormNo: item.txnValue}
                } else if (item.itemID == 17) {
                    mappedData = {...mappedData, fallenTime: item.txnValue}
                } else {
                    mappedData = mappedData;
                }
            });
            setData(mappedData);
        }
    }, [checkListItems])

    return (
        <Paper sx={{padding: 1}}>
        <Box display="flex" alignItems="center">

            <Box flexGrow={1} ></Box>
            <Box>
                <IconButton onClick={edit} disabled={!enableEdit} color="primary">
                        <EditIcon />
                </IconButton>
            </Box>
        </Box>
        
        <Grid container spacing={2}>

            <Grid item xs={6}>

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <InfoBlock label={"Fallen Time"} value={data.fallenTime} />
                    </Grid>

                    <Grid item xs={12}>
                        <InfoBlock label={"Witnessed"} value={data.witnessed} />
                    </Grid>

                    <Grid item xs={12}>
                        <InfoBlock label={"Employee"} value={data.employee} />
                    </Grid>

                    <Grid item xs={12}>
                        <InfoBlock label={"Fallen Area"} value={data.fallArea} />
                    </Grid>

                    <Grid item xs={12}>
                        <InfoBlock label={"Injury Type"} value={data.injuryType} />
                    </Grid>

                    <Grid item xs={12}>
                        <InfoBlock label={"Incident Form No"} value={data.incidentFormNo} />
                    </Grid>

                </Grid>

            </Grid>

            <Grid item xs={6}>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InfoBlock label={"Fall Prevention Steps"} />
                        
                        {checkListItems && checkListItems.map(item => (item.itemID == 15) ? <InfoBlock value={item.referenceDataValue} /> : '' )}
                        
                    </Grid>
            
                    
                </Grid>

            </Grid>
        </Grid>
        </Paper>
    )
}