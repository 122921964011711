import { Box, Collapse, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import InboxIcon from '@mui/icons-material/Inbox';
import MailIcon from '@mui/icons-material/Mail';
import GroupIcon from '@mui/icons-material/Group';
import DashBoardIcon from '@mui/icons-material/Dashboard';
import BedIcon from '@mui/icons-material/Bed';
import ScheduleIcon from '@mui/icons-material/Schedule';

import { Link, useLocation } from 'react-router-dom';
import { ExpandLess, ExpandMore, StarBorder } from '@mui/icons-material';

const drawerWidth = 240;

const ROUTES = [
    {text: "Dashboard", path: "/dashboard", icon: "DashBoardIcon"},
    {text: 'Room Ocupancy', path: '/roomocupancy', icon: "BedIcon"},
    {text: 'Rota', path: '/rota', icon: "ScheduleIcon"},
    {text: 'Employee', path: '/employee', icon: "GroupIcon"},
    {text: 'Referral', path: '/referral', icon: "GroupIcon"},
    {text: 'Incident', path: '/incident', icon: "GroupIcon"},
    {text: 'Weekly Entry', path: '/weekly', icon: "GroupIcon"}
    
];

export const SideMenu = () => {

  const theme = useTheme();

  const { pathname } = useLocation();

  const [expandAdmin, setExpandAdmin] = useState(false);

    return (
      
        <Drawer
            variant='permanent'
            anchor='left'
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
            PaperProps={{
              sx: {
                backgroundColor: "#e8f5e9",//                " "#f3e5f5",
                color: "black",
              }
            }}
        >
            <Toolbar sx={{  bgcolor: theme.palette.primary.main, color: "white"  }}>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1, fontWeight: 'bold'  }}>
                    Care Home
                </Typography>
            </Toolbar>

            <Divider />


            <List >
                {ROUTES.map((route, index) => (
                    
                    <ListItem 
                      button 
                      component={Link}
                      to={route.path} 
                      key={route.text} 
           
                      selected={pathname === route.path}
                      style={
                        pathname === route.path
                        ? {
                            //background: "theme.premiry",
                            background: "#00b8d4", //"#e1bee7",
                            width: 230,
                            marginLeft: 8,
                            paddingLeft: 2,
                            borderRadius: 4,
                            boxShadow: "2px 3px 6px rgba(0, 0, 0, 0.3)",
                            }
                        : {}
                    }
                    >
                        <ListItemIcon>
                            {route.icon === "DashBoardIcon" && <DashBoardIcon sx={{color: theme.palette.primary.main}} />}
                            {route.icon === "GroupIcon" && <GroupIcon sx={{color: theme.palette.primary.main}}/>}
                            {route.icon === "BedIcon" && <BedIcon sx={{color: theme.palette.primary.main}}/>}
                            {route.icon === "ScheduleIcon" && <ScheduleIcon sx={{color: theme.palette.primary.main}}/>}
                        </ListItemIcon>
                        <ListItemText primary={route.text} />
                    </ListItem>
                  
                ))}

<ListItemButton onClick={() => setExpandAdmin(!expandAdmin)}>
        <ListItemIcon>
          <InboxIcon sx={{color: theme.palette.primary.main}}/>
        </ListItemIcon>
        <ListItemText primary="Admin" />
        {expandAdmin ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={expandAdmin} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }}
            component={Link}
            to={"/admin/jobrole"} 
            key={"jobrole"} 
            selected={pathname === "/admin/jobrole"}
            style={
                        pathname === "/admin/jobrole"
                        ? {
                            background: "theme.premiry",
                            borderRadius: 4,
                            boxShadow: "2px 3px 6px rgba(0, 0, 0, 0.3)"
                            }
                        : {}
                    }
          >
            <ListItemIcon>
              <StarBorder sx={{color: theme.palette.primary.main}}/>
            </ListItemIcon>
            <ListItemText primary="Job Role" />
          </ListItemButton>

          <ListItemButton sx={{ pl: 4 }}
            component={Link}
            to={"/admin/rotaplan"} 
            key={"rotaplan"} 
            selected={pathname === "/admin/rotaplan"}
            style={
                        pathname === "/admin/rotaplan"
                        ? {
                            background: "theme.premiry",
                            borderRadius: 4,
                            boxShadow: "2px 3px 6px rgba(0, 0, 0, 0.3)"
                            }
                        : {}
                    }
          >
            <ListItemIcon>
              <StarBorder sx={{color: theme.palette.primary.main}}/>
            </ListItemIcon>
            <ListItemText primary="Rota Plan" />
          </ListItemButton>
        </List>
      </Collapse>
            </List>
        </Drawer>
    
    )
}