import { entryConstant } from "../_constants/entryConstant";
import axios from '../_helpers/axios';

export const entryAction = {
    getEntriesByLocation,

    update,
    getCurrentWeek,
    getWeekEntries,
}


function getEntriesByLocation(locationID) {

    return (dispatch) => {

        dispatch(request());

        axios.get(`/api/v1/entry/${locationID}`)
            .then(response => {
                dispatch(success(response.data.data))
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: entryConstant.REQUEST}}
    function success(entries) { return {type: entryConstant.GET_LOC_ENTRIES_SUCCESS, entries}}
    function error(error) { return {type: entryConstant.GET_LOC_ENTRIES_ERROR, error}}
}

function update(entry) {

    return (dispatch) => {

        dispatch(request());

        axios.put(`/api/v1/entry/`, entry)
            .then(response => {
                dispatch(success(response.data.data))
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: entryConstant.REQUEST}}
    function success(entry) { return {type: entryConstant.UPDATE_SUCCESS, entry}}
    function error(error) { return {type: entryConstant.UPDATE_ERROR, error}}
}

function getCurrentWeek(locationID) {

    return (dispatch) => {

        dispatch(request());

        axios.get(`/api/v1/entry/W/${locationID}`)
            .then(response => {
                dispatch(success(response.data.data))
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: entryConstant.REQUEST}}
    function success(period) { return {type: entryConstant.GET_CURR_PERIOD_SUCCESS, period}}
    function error(error) { return {type: entryConstant.GET_CURR_PERIOD_ERROR, error}}
}

function getWeekEntries(weekNo, locationID) {

    return (dispatch) => {

        dispatch(request());

        axios.get(`/api/v1/entry/W/${weekNo}/${locationID}`)
            .then(response => {
                dispatch(success(response.data.data))
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: entryConstant.REQUEST}}
    function success(period) { return {type: entryConstant.GET_ENTRY_PERIOD_SUCCESS, period}}
    function error(error) { return {type: entryConstant.GET_ENTRY_PERIOD_ERROR, error}}
}
