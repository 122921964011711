export const rotaConstant = {
    INIT: 'ROTA_INIT',
    REQUEST: 'ROTA_REQUEST',
    UPDATE_SHIFT_SUCCESS: 'ROTA_UPDATE_SHIFT_SUCCESS',
    UPDATE_SHIFT_ERROR: 'ROTA_UPDATE_SHIFT_ERROR',

    GET_WEEK_SHIFT_SUCCESS: 'ROTA_GET_WEEK_SHIFT_SUCCESS',
    GET_WEEK_SHIFT_ERROR: 'ROTA_GET_WEEK_SHIFT_ERROR',

    COPY_WEEK_SHIFT_SUCCESS: 'ROTA_COPY_WEEK_SHIFT_SUCCESS',
    COPY_WEEK_SHIFT_ERROR: 'ROTA_COPY_WEEK_SHIFT_ERROR',

}