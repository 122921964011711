import { fundConstant } from "../_constants/fundConstant";

const initialState = {
            loading: false,
            types: [],
        };

export function fund(state = initialState, action) {
    switch (action.type) {
        case fundConstant.REQUEST: 
            return {
                ...state,
                loading: true,
            }
        case fundConstant.GET_FUND_TYPES_SUCCESS:
            return {
                ...state,
                types: action.types,
                loading: false
            }
        default:
            return state;
    }
}