import { rotaConstant } from "../_constants/rotaConstant";

const initialState = {
            loading: false,
            weekRota: {},
        };

export function rota(state = initialState, action) {
    switch (action.type) {
        case rotaConstant.INIT:
            return initialState;

        case rotaConstant.REQUEST: 
            return {
                ...state,
                loading: true,
            }
        case rotaConstant.GET_WEEK_SHIFT_SUCCESS:
            return {
                ...state,
                weekRota: {mon: action.shifts.filter(shift => shift.day == 1),
                           tue: action.shifts.filter(shift => shift.day == 2),
                           wed: action.shifts.filter(shift => shift.day == 3),
                           thu: action.shifts.filter(shift => shift.day == 4),
                           fri: action.shifts.filter(shift => shift.day == 5),
                           sat: action.shifts.filter(shift => shift.day == 6),
                           sun: action.shifts.filter(shift => shift.day == 7),
                            },
                loading: false
            }
        case rotaConstant.UPDATE_SHIFT_SUCCESS: 
            return {
                ...state,
                weekRota: { mon: action.shift.day == 1 ? (state.weekRota.mon.map( shift => shift.id == action.shift.id ? action.shift : shift)) : state.weekRota.mon,
                            tue: action.shift.day == 2 ? (state.weekRota.tue.map( shift => shift.id == action.shift.id ? action.shift : shift)) : state.weekRota.tue,
                            wed: action.shift.day == 3 ? (state.weekRota.wed.map( shift => shift.id == action.shift.id ? action.shift : shift)) : state.weekRota.wed,
                            thu: action.shift.day == 4 ? (state.weekRota.thu.map( shift => shift.id == action.shift.id ? action.shift : shift)) : state.weekRota.thu,
                            fri: action.shift.day == 5 ? (state.weekRota.fri.map( shift => shift.id == action.shift.id ? action.shift : shift)) : state.weekRota.fri,
                            sat: action.shift.day == 6 ? (state.weekRota.sat.map( shift => shift.id == action.shift.id ? action.shift : shift)) : state.weekRota.sat,
                            sun: action.shift.day == 7 ? (state.weekRota.sun.map( shift => shift.id == action.shift.id ? action.shift : shift)) : state.weekRota.sun,
                        },
                loading: false,
            }

        default:
            return state;
    }
}