import { Button, Card, CardActions, CardContent, CardHeader, IconButton, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { dateFormat } from '../../_helpers/datetime';
import { InfoBlock } from '../ui/InfoBlock';
import { ContractDetail } from './ContractDetail';
import { ContractForm } from './ContractForm';
import { PersonalDetail } from './PersonalDetail';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import { EmploymentForm } from './EmploymentForm';


const initialContract = {id: 0, jobRoleId: 0, jobRoleName: '', contractedHours: 0, startDate: '', endDate: ''};

export const EmployeeDetail = ({employee, close}) => {

    const [isEditing, setIsEditing] = useState(false);
    const [txnType, setTxnType] = useState('DISPLAY');

    const [currContract, setCurrContract] = useState(initialContract);

    const jobRoles = useSelector(state => state.employee.jobRoles);

    const onEditEmployment = (e) => {
        e.preventDefault();
        setTxnType('EMPLOYMENT');
    }
    const onAddContract = () => {
        setCurrContract({...initialContract, employeeId: employee.id});
        setTxnType('CONTRACT')
    }

    const onEditContract = (contract) => {
        console.log(contract)
        setCurrContract({...contract, jobRoleId: contract.jobRole.id, jobRoleName: contract.jobRole.name});
        setTxnType('CONTRACT');
    }

    const closeForm = () => {
        setTxnType( "DISPLAY");
    }


    return (
        <Grid container spacing={2}>

            <Grid item xs={11}>

            </Grid>

            <Grid item xs={1} >

                <IconButton aria-label="Close" size="large"  onClick={close} disabled={txnType !== "DISPLAY"}>
                    <CloseIcon fontSize="inherit" />
                </IconButton>

            </Grid>

            {txnType !== "DISPLAY" 
                ? 
                (<>
                    {txnType === "CONTRACT" && <ContractForm currContract={currContract} jobRoles={jobRoles} close={closeForm}/>} 

                    {txnType === "EMPLOYMENT" && <EmploymentForm currEmployee={employee} close={closeForm}/>} 
                </>)
                :
                (<>
            <Grid item xs={12}>
                <PersonalDetail 
                    firstName={employee.firstName}
                    lastName={employee.lastName}
                    middleName={employee.middleName}
                    otherName={employee.otherName}
                    dob={employee.dob}
                    leftDate={employee.leftDate}
                    />
            </Grid>


            <Grid item xs={12}>
                <Card>
                    <CardHeader title='Employment Detail' />
                    <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <CardContent>
                            <InfoBlock label={"Join Date "} value={dateFormat(employee.joinDate)}/>
                            <InfoBlock label={"Leave Date "} value={employee.leftDate ? dateFormat(employee.leftDate) : ''}/>
                        </CardContent>

                        <CardActions>
                            <Button size='small' color='primary' startIcon={<EditIcon />} onClick={onEditEmployment} disabled={employee.leftDate}>
                                Edit
                            </Button>
                        </CardActions>
                    </Grid>

                    <Grid item xs={9}>
                        <ContractDetail employeeId={employee.id} leaveDate={employee.leftDate} onEdit={onEditContract} onAdd={onAddContract} />
                    </Grid>
                    </Grid>
                </Card>
            </Grid>
                </>)}
        </Grid>
    )
}