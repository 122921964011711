import { Button, Card, CardActions, CardContent, CardHeader, Grid, Paper, TextField } from '@mui/material';
import React, { useState } from 'react';
import { localCurrency, numberFormat } from '../../_helpers/number';
import { InfoBlock } from '../ui/InfoBlock';
import { dateFormat } from '../../_helpers/datetime';


import EditIcon from '@mui/icons-material/Edit';

export const Room = ({txnMode, roomNo, roomType, roomRate, admissionDate, leavingDate, onEdit, enableEdit}) => {

    return (
        <Card>
            <CardHeader title="Room Detail"></CardHeader>
            <CardContent>
            
                <InfoBlock label={"No "} value={roomNo} valueType={"text"}/>
                <InfoBlock label={"Type"} value={roomType} valueType={"text"}/>
                <InfoBlock label={"Rate "} value={roomRate} valueType={"curr"}/>
                <InfoBlock label={"Admission Date "} value={dateFormat(admissionDate)}/>
                {leavingDate && <InfoBlock label={"Leaving Date "} value={dateFormat(leavingDate)}/> }
            
            </CardContent>

            {/*txnMode !== 'V' &&
            <CardActions>
                <Button size='small' color='primary' startIcon={<EditIcon />} onClick={(e) => { e.preventDefault(); onEdit('admission')}} disabled={!enableEdit}>
                    Edit
                </Button>
    </CardActions> */}
        </Card>
    )
}