import { incidentConstant } from "../_constants/incidentConstant";
import axios from '../_helpers/axios';
import { alertActions } from "./alertAction";

import { appActions }  from './appAction';

export const incidentAction = {
    getIncidentTypes, 
    getIncidentTypeReasons,

    register,
    getIncidentsByCustomer,
    getIncidentsByLocation,
    setIncident,
    setChecklistItemEdit,
    setChecklistItemEditCancel,
    close,

    getChecklistItems,
    updateCheckList,
    updateBulkCheckList,

    getFallAreas,
    getFallPreventions,
    getInjuryTypes,

    setHeaderEdit,
    setAdditionalInfoEdit,

    getGroupByType,
}


function register(incident) {
    return dispatch => {
        console.log(incident);
        
        axios.post('/api/v1/incident', incident) 
            .then(response => {
                dispatch(success(response.data.data));
                dispatch(alertActions.success(response.data.message));
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: incidentConstant.REQUEST}}
    function success(incident) { return { type: incidentConstant.REGISTER_SUBMIT_SUCCESS,incident}}
    function error(error) { return {type: incidentConstant.REGISTER_SUBMIT_ERROR, error}}
}

function getIncidentsByCustomer(customerID) {

    return (dispatch) => {

        dispatch(request());

        axios.get(`/api/v1/incident/customer/${customerID}`)
            .then(response => {
                dispatch(success(response.data.data))
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: incidentConstant.REQUEST}}
    function success(incidents) { return {type: incidentConstant.GET_INCIDENTS_SUCCESS, incidents}}
    function error(error) { return {type: incidentConstant.GET_INCIDENTS_ERROR, error}}
}

function getIncidentsByLocation(locationID) {

    return (dispatch) => {

        dispatch(request());

        axios.get(`/api/v1/incident/location/${locationID}`)
            .then(response => {
                dispatch(success(response.data.data))
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: incidentConstant.REQUEST}}
    function success(incidents) { return {type: incidentConstant.GET_INCIDENTS_SUCCESS, incidents}}
    function error(error) { return {type: incidentConstant.GET_INCIDENTS_ERROR, error}}
}

function close(closeRemark, incidentID) {

    return (dispatch) => {

        dispatch(request());

        axios.put(`/api/v1/incident/close/${incidentID}`,{id: incidentID, closeRemark: closeRemark})
            .then(response => {
                dispatch(success(response.data.data))
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: incidentConstant.REQUEST}}
    function success(incident) { return {type: incidentConstant.CLOSE_SUCCESS, incident}}
    function error(error) { return {type: incidentConstant.CLOSE_ERROR, error}}
}

function setIncident(incident) {

    return (dispatch) => {

        dispatch(success(incident));
        
    };

    function request() {return {type: incidentConstant.REQUEST}}
    function success(incident) { return {type: incidentConstant.SET_INCIDENT_SCCESS, incident}}

}

function getIncidentTypes() {

    return (dispatch) => {

        dispatch(request());

        axios.get(`/api/v1/incident/types`)
            .then(response => {
                dispatch(success(response.data.data))
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: incidentConstant.REQUEST}}
    function success(types) { return {type: incidentConstant.GET_TYPES_SUCCESS, types}}
    function error(error) { return {type: incidentConstant.GET_TYPES_ERROR, error}}
}

function getIncidentTypeReasons(typeID) {

    return (dispatch) => {

        dispatch(request());

        axios.get(`/api/v1/incident/reasons/${typeID}`)
            .then(response => {
                dispatch(success(response.data.data))
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: incidentConstant.REQUEST}}
    function success(reasons) { return {type: incidentConstant.GET_TYPE_REASONS_SUCCESS, reasons}}
    function error(error) { return {type: incidentConstant.GET_TYPE_REASONS_ERROR, error}}
}

function getFallAreas(locationID) {

    return (dispatch) => {

        dispatch(request());

        axios.get(`/api/v1/reference/incident-falls-area`)
            .then(response => {
                dispatch(success(response.data.data))
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: incidentConstant.REQUEST}}
    function success(areas) { return {type: incidentConstant.GET_FALL_AREAS_SUCCESS, areas}}
    function error(error) { return {type: incidentConstant.GET_FALL_AREAS_ERROR, error}}
}

function getFallPreventions(locationID) {

    return (dispatch) => {

        dispatch(request());

        axios.get(`/api/v1/reference/incident-fall-prevention`)
            .then(response => {
                dispatch(success(response.data.data))
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: incidentConstant.REQUEST}}
    function success(fallPreventions) { return {type: incidentConstant.GET_FALL_PREVENTIONS_SUCCESS, fallPreventions}}
    function error(error) { return {type: incidentConstant.GET_FALL_PREVENTIONS_ERROR, error}}
}

function getInjuryTypes(locationID) {

    return (dispatch) => {

        dispatch(request());

        axios.get(`/api/v1/reference/incident-injury-type`)
            .then(response => {
                dispatch(success(response.data.data))
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: incidentConstant.REQUEST}}
    function success(injuryTypes) { return {type: incidentConstant.GET_INJURY_TYPES_SUCCESS, injuryTypes}}
    function error(error) { return {type: incidentConstant.GET_INJURY_TYPES_ERROR, error}}
}

function getGroupByType(locationID) {

    return (dispatch) => {

        dispatch(request());

        axios.get(`/api/v1/incident/group/type/${locationID}`)
            .then(response => {
                dispatch(success(response.data.data))
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: incidentConstant.REQUEST}}
    function success(lines) { return {type: incidentConstant.GET_GROUP_TYPE_SUCCESS, lines}}
    function error(error) { return {type: incidentConstant.GET_INCIDENTS_ERROR, error}}
}

// ---------------------------------- [ Incident Checklist Management Actions ] -----------------------------------------
function updateCheckList(checkListItem) {
    return dispatch => {
        console.log(checkListItem);
        
        axios.put('/api/v1/incident/checklistItem', checkListItem) 
            .then(response => {
                dispatch(success(response.data.data));
                dispatch(alertActions.success(response.data.message));
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: incidentConstant.REQUEST}}
    function success(item) { return { type: incidentConstant.UPDATE_CHECKLIST_ITEM_SUBMIT_SUCCESS,item}}
    function error(error) { return {type: incidentConstant.UPDATE_CHECKLIST_ITEM_SUBMIT_ERROR, error}}
}

function updateBulkCheckList(checkListItems) {
    return dispatch => {
        console.log(checkListItems);
        
        axios.put('/api/v1/incident/checklistItem/bulk', checkListItems) 
            .then(response => {
                dispatch(success(response.data.data));
                dispatch(alertActions.success(response.data.message));
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: incidentConstant.REQUEST}}
    function success(items) { return { type: incidentConstant.UPDATE_CHECKLIST_ITEMS_SUBMIT_SUCCESS,items}}
    function error(error) { return {type: incidentConstant.UPDATE_CHECKLIST_ITEMS_SUBMIT_ERROR, error}}
}

function getChecklistItems(incidentID) {
    return dispatch => {
        console.log(incidentID);
        
        axios.get(`/api/v1/incident/checklistitem/${incidentID}`) 
            .then(response => {
                dispatch(success(response.data.data));
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: incidentConstant.REQUEST}}
    function success(items) { return { type: incidentConstant.GET_INCIDENT_CHECKLIST_ITEMS_SUCCESS,items}}
    function error(error) { return {type: incidentConstant.GET_INCIDENT_CHECKLIST_ITEMS_ERROR, error}}
}


function setChecklistItemEdit(item) {

    return (dispatch) => {

        dispatch(success(item));
        
    };

    function request() {return {type: incidentConstant.REQUEST}}
    function success(item) { return {type: incidentConstant.SET_INCIDENT_ITEM_EDIT, item}}

}

function setChecklistItemEditCancel(item) {

    return (dispatch) => {

        dispatch(success(item));
        
    };

    function request() {return {type: incidentConstant.REQUEST}}
    function success(item) { return {type: incidentConstant.SET_INCIDENT_ITEM_EDIT_CANCEL, item}}

}

function setHeaderEdit(newState) {

    return (dispatch) => {

        dispatch(setEditing(newState))        
    }; 

    function setEditing(value) { return {type: incidentConstant.SET_HEADER_EDITING, value }}

}

function setAdditionalInfoEdit(newState) {

    return (dispatch) => {

        dispatch(setEditing(newState))        
    }; 

    function setEditing(value) { return {type: incidentConstant.SET_ADD_INFO_EDITING, value }}

}