import React, { useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Card, CardContent, CardHeader, FormHelperText, Grid, MenuItem, TextField } from '@mui/material';

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import { localCurrency } from '../../_helpers/number';

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

import moment from "moment";
import { SYS_DATE_FORMAT } from "../../_helpers/datetime";
import { validateDateTime } from '@mui/lab/internal/pickers/date-time-utils';
import { ContactlessOutlined } from '@mui/icons-material';

import { CYCLES } from '../../_constants/appConstant';

const initialFund = {
    id: 0,
    fundId : '',
    amount: 0,
    cycle: ''
}

export const FundForm = ({fundTypes = [], currFunds = [], onSubmit, onCancel}) => {

    const [funds, setFunds] = useState([{...initialFund}]);
    const [fee, setFee] = useState(0);
    const [errors, setErrors] = useState([]);
    const [effectDate, setEffectDate] = useState('');
    const [isModified, setIsModified] = useState(false);

    useEffect(() => {
        let fundTotal = funds.map(fund => parseFloat(fund.amount)).reduce((prevVal, currVal) => prevVal + currVal, 0);
        setFee(fundTotal);

        let vIsModified = false;
        funds.map(fund => {
            console.log(fund);
            if (parseInt(fund.id) == 0 && fund.fundId > 0 ) {
                vIsModified = true;
                console.log("Fund ID is zero")
            }

            if (fund.fundId > 0 && parseFloat(fund.oldAmount) != parseFloat(fund.amount)) {
                vIsModified = true;
                console.log("Fund amount is changed")
            }
        });
        setIsModified(vIsModified);
    }, [funds]);

    useEffect(() => {
        if (currFunds.length>0)
            setFunds(currFunds.map(fund => { return {...fund, oldAmount: fund.amount}}))
    }, [currFunds])

    const addFundType = (e) => {
        console.log("addFundType is clicked");
        e.preventDefault();

        setFunds([...funds, {...initialFund}])
    }

    const removeFundType = (removeIndex) => {

        setFunds( funds.filter((fund, index) => index != removeIndex));
    }

    const handleFundChange = (modifyIndex, name, value) => {
        if (name === 'fundId') {
            let fundName = fundTypes.find(type => type.id === value).name;
            setFunds ( funds.map((fund,index) => index == modifyIndex ? {...fund, [name]: value, fundName: fundName} : fund));
        } else {
            setFunds ( funds.map((fund,index) => index == modifyIndex ? {...fund, [name]: value} : fund));
        }
        
    }

    const handleDatePicker = (name, date) => {
        //setOccupy({...occupy, [name]: date.format("YYYY-MM-DD")}); 
        setEffectDate(date)
    }

    const submitForm = (e) => {
        e.preventDefault();
        console.log(funds);
        console.log(isModified)

        if (validate()) {
            onSubmit(effectDate, funds,fee, isModified);
            
        }
    }

    useEffect(() => {
        console.log(isModified);
    },[isModified])

    const validate = () => {
        console.log("validate Forms")
        let formErrors = {error: false};

        if (effectDate === '') {
            console.log("Error Leavel 1");
        }

        if (effectDate === '' ) {
            formErrors = {...formErrors, error: true, effectDate: 'Invalid Effect Date'};
        }

        // Funds
        let vFunds = funds.find(fund => (fund.fundId != '' && fund.amount > 0 && fund.cycle != ''));
    
        if (!vFunds || vFunds.length <= 0) {
            formErrors = {...formErrors, error: true, funds: 'Invalid Funds setup'};
        }

        setErrors(formErrors);
        return !formErrors.error;
    }

    return (
        <Card>
            <CardHeader title="Fund Form" />

            <CardContent>

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={DateAdapter}>
                        <DatePicker
                            mask="__-__-____"
                            label="Effect Date"
                            type="date"
                            value={effectDate}
                            inputFormat={SYS_DATE_FORMAT}
                            onChange={(newValue) => {
                                handleDatePicker('effectDate', newValue);
                            }}
                            renderInput={(params) => <TextField {...params} error={errors.effectDate? true : false}
                            helperText={errors.effectDate ? errors.effectDate : ''} />}
                        />
                        </LocalizationProvider>
                    </Grid>
                  
                        {funds.map((fund,index) => 
                        <>
                            <Grid item xs={4} >
                            <TextField
                            id="fundId"
                            select
                            label="Fund"
                            name="fundId"
                            value={fund.fundId}
                     
                            onChange={(e) => handleFundChange(index, e.target.name, e.target.value )}
                            variant="outlined"
                            fullWidth
                        >
                            {fundTypes.map(fundType => <MenuItem key={fundType.id} value={fundType.id}>{`${fundType.name}`}</MenuItem>)}
                        </TextField>
                        </Grid>
                        <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            type="number"
                            id="amount"
                            name="amount"
                            label="Amount"
                            value={fund.amount}
                            onChange={(e) => handleFundChange(index, e.target.name, e.target.value )}
                            error={errors.amount}
                            helperText={errors.amount ? errors.amount : ''}
                        />
                            </Grid> 

                            <Grid item xs={3} >
                                <TextField
                                    id="cycle"
                                    select
                                    label="Billing Cycle"
                                    name="cycle"
                                    value={fund.cycle}
                            
                                    onChange={(e) => handleFundChange(index, e.target.name, e.target.value )}
                                    variant="outlined"
                                    fullWidth
                                >
                                {   CYCLES.map(cycle => <MenuItem key={cycle.code} value={cycle.code}>{`${cycle.name}`}</MenuItem>)}
                                </TextField>
                            </Grid>

                            <Grid item xs={1}>
                                <Button onClick={() => removeFundType(index)}>X</Button>
                            </Grid>
                            </>
                        )}
                 
                    <Grid item xs={8}>Fee</Grid>
                    <Grid item xs={4}>{localCurrency(fee)}</Grid>
                    <Grid item xs={12}><FormHelperText>{errors.funds ? errors.funds : ''}</FormHelperText></Grid>
                    <Grid item xs={6}>
                        <Button onClick={addFundType}>
                            + Add Fund Type
                        </Button>
                    </Grid>

                    <Grid item xs={3} >
                        <LoadingButton
                            loading={false}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            type="submit"
                            onClick={submitForm}
                        >
                            Ok
                        </LoadingButton>

                    </Grid>

                    <Grid item xs={3} >
                        <LoadingButton
                            loading={false}
                            loadingPosition="start"
                            startIcon={<CloseIcon />}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={(e) => onCancel('fund')}
                        >
                            Close
                        </LoadingButton>

                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}