import React from "react";

import { Box, Button, ButtonGroup, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { dateFormat } from "../../_helpers/datetime";

export const EmployeeTable = ({employees, loadCurrEmployee}) => {
    return (
        <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="right">#</TableCell>
                    <TableCell align="left">Full Name</TableCell>
                    <TableCell align="center">Date of Birth</TableCell>
                    <TableCell align="center">Join Date</TableCell>
                    <TableCell align="right">Contract Hours</TableCell>
                    <TableCell align="center">Training Completed</TableCell>
                    <TableCell align="left"> Main Role</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                    {employees.map( (employee, index) => 
                    <TableRow key={employee.id}>
                    <TableCell align="right">{index + 1}</TableCell>
                    <TableCell align="left">{employee.firstName} {' '} {employee.lastName}</TableCell>
                    <TableCell align="center">{dateFormat(employee.dob)}</TableCell>
                    <TableCell align="center">{dateFormat(employee.joinDate)}</TableCell>
                    <TableCell align="right">{employee.contractedHours}</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="left">{employee.jobRole.name}</TableCell>
                    <TableCell align="center">
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                      <Button onClick={() => {loadCurrEmployee(employee)}}>Edit</Button>
                      {/*<Button onClick={() => {}}>Del</Button>*/}
                    </ButtonGroup>
                  </TableCell>

                  </TableRow>

                    )}
                  
                </TableBody>
              </Table>
            </TableContainer>
    )
}