import React, { useEffect } from 'react';

import { Box, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { rotaAction } from '../../_actions/rotaAction';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


export const Shift = () => {
    return (
        <Paper style={{padding: 12}}>

            <Box >
                <Box sx={{ typography: 'subtitle2' }}>00:00 - 04:00</Box>

                <Box sx={{ typography: 'body1', textAlign: 'left', m: 1, fontWeight: 'regular'}}>
                
                
                            
                            <Typography  component="div"  >
                               LCA - 1
                            </Typography>
                            
                    
                </Box>
            </Box>

        </Paper>
    );
}

export const RotaPlan = () => {

    const dispatch = useDispatch();

    const workingLocation = useSelector(state => state.location.working);

    useEffect(() => {
        dispatch(rotaAction.getPlan(workingLocation.id));
    },[workingLocation]);

    return (
        <Paper style={{padding: 12, color: 'red'}}>

        <Box display="flex">
            <Box flexGrow={1}>
              <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Job Roles
              </Typography>
            </Box>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                    <Grid item xs> 
                        <Item>
                            <Box sx={{ typography: 'subtitle1' }}>Sunday</Box>
                            <Paper elevation={3}><Shift /></Paper>
                        </Item>
                    </Grid>
                    <Grid item xs><Item><Paper elevation={3}>Hi</Paper></Item></Grid>
                    <Grid item xs></Grid>
                    <Grid item xs></Grid>
                    <Grid item xs></Grid>
                    <Grid item xs></Grid>
                    <Grid item xs></Grid>
                </Grid>
            </Box>
        </Box>
        </Paper>
    )
}