import { useState } from 'react';

import { Button, Card, CardContent, CardActions, Typography, TextField } from "@mui/material"
import { useDispatch } from "react-redux"
import { incidentAction } from "../../_actions/incidentAction";
import { Box } from '@mui/system';

export const CloseIncidentForm = ({incidentID, close}) => {

    const dispatch = useDispatch();
    const [closeRemark, setCloseRemark] = useState();

    const submit = () => {
        console.log(closeRemark);
        dispatch(incidentAction.close(closeRemark, incidentID));
    }
    return (
        <Card sx={{marginBottom: 3, marginTop: 2}}>
            <CardContent>
                <Typography variant="h6">Required confirmation </Typography>
                <Typography variant="body2">Please confirm to close this Incident.. </Typography>

                <Box m={2}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        id="closeRemark"
                        name="closeRemark"
                        label="Close Remark"
                        value={closeRemark}
                        onChange={(e) => setCloseRemark(e.target.value)}
                        //error={errors.closeRemark}
                        //helperText={errors.closeRemark ? errors.closeRemark : ''}
                        multiline      
                        maxRows={4}
                    />
                </Box>
            </CardContent>

            <CardActions>
        
                <Button size="small" variant="contained" color="primary" onClick={submit}>
                    Confirm
                </Button>
                <Button size="small" variant="outlined" color="secondary" onClick={close}>
                    Cancle
                </Button>
            </CardActions>
        </Card>
    )
}