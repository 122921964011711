import { Button, Container, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { LoadingButton } from "@mui/lab";

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import { updateLocale } from "moment";
import { roomAction } from "../../_actions/roomAction";
import { SYS_DATE_FORMAT } from "../../_helpers/datetime";
import { Room } from "./Room";
import moment from "moment";

export const OccupyForm = ({currRoom, create}) => {

    const dispatch = useDispatch();

    const [occupy, setOccupy] = useState({roomID: 0});
    const [errors, setErrors] = useState({error: false});
    const [formTitle, setFormTitle] = useState('New Admission');
    
    const loading = useSelector(state => state.room.loading);
    const nonOccupiedRooms = useSelector(state => state.room.nonOccupiedRooms);

    const [btnSaveLabel, setBtnSaveLabel] = useState('Register');

    const [roomList, setRoomList] = useState([]);

    useEffect(() => {

            setOccupy({id: 0, room: currRoom, roomId: currRoom.id, rate: currRoom.rate, fromDate: moment().format('YYYY-MM-DD')});

    },[currRoom])

    const handleFormChange = (e) => {
        const {name, value} = e.target;

        setOccupy({...occupy, [name]: value});
    }

    const handleDatePicker = (name, date) => {
        setOccupy({...occupy, [name]: date.format("YYYY-MM-DD")}); 
    }

    const handleSubmit = (e) => {
        e.preventDefault();

            create(occupy);
    
    }

    const validate = () => {
        console.log("validate Forms")
        let formErrors = {error: false};

        if (!occupy.firstName || occupy.firstName.length <= 0) {
            formErrors = {...formErrors, error: true, firstName: 'Required First Name'};
        }
    
        if (!occupy.lastName || occupy.lastName.length <= 0) {
            formErrors = {...formErrors, error: true, lastName: 'Required First Name'};
        }

        if (!occupy.niNo || occupy.niNo.length <= 0) {
            formErrors = {...formErrors, error: true, niNo: 'Required Identity No'};
        }

        setErrors(formErrors);
        return !formErrors.error;
    }
    
    return (
    <>
            <Box style={{   marginTop: 8, 
                            display: 'flex',
                            flexDirection: 'column',
                           }}>

                <Typography component="h1" variant="h5" mb={4} align="left">
                           {formTitle}
                </Typography>
</Box>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>

                    <Grid item xs={3}>
                    <Room 
                        roomNo={currRoom.roomNo} 
                        roomType={currRoom.roomType.name} 
                        roomRate={currRoom.rate} 
                        admissionDate={''} 
                        leavingDate={''}
                        txnMode={'V'}
                        onEdit={() => {}}
                    />
                    </Grid>

                    <Grid item xs={5}>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    autoComplete="fname"
                                    name="firstName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    value={occupy.firstName}
                                    onChange={handleFormChange}
                                    error={errors.firstName}
                                    helperText={errors.firstName ? errors.firstName : ''}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="lastName"
                                    name="lastName"
                                    label="Last Name"
                                    value={occupy.lastName}
                                    onChange={handleFormChange}
                                    error={errors.lastName}
                                    helperText={errors.lastName ? errors.lastName : ''}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="niNo"
                                    name="niNo"
                                    label="Identity No"
                                    value={occupy.niNo}
                                    onChange={handleFormChange}
                                    error={errors.niNo}
                                    helperText={errors.niNo ? errors.niNo : ''}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={DateAdapter}>
                                <DatePicker
                                    label="Admission Date"
                                    type="date"
                                    value={occupy.fromDate}
                                    inputFormat={SYS_DATE_FORMAT}
                                    onChange={(newValue) => {
                                        handleDatePicker('fromDate', newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} error={errors.fromDate}
                                    helperText={errors.fromDate ? errors.fromDate : ''} />}
                                />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12}></Grid>
                            <Grid item xs={9}></Grid>

                            <Grid item xs={3} >
                                <LoadingButton
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    type="submit"
                                >
                                    {btnSaveLabel}
                                </LoadingButton>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                
            </form>
          
        </>
    )
}