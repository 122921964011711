import { useEffect, useState } from 'react';

import { Box, Button, CircularProgress, Paper, Stack, Typography } from "@mui/material"
import { IncidentList } from "../components/incident/List"
import { RegisterIncidentForm } from '../components/incident/RegisterForm';
import { useDispatch, useSelector } from 'react-redux';
import { incidentAction } from '../_actions/incidentAction';
import { TypeList } from '../components/incident/TypeList';
import { FallsAnalysis } from '../components/incident/FallsAnalysis';

export const IncidentPage = () => {

    const dispatch = useDispatch();

    const workingLocation = useSelector(state => state.location.working);
    const loading = useSelector(state => state.referral.listLoading);
    const incidents = useSelector(state => state.incident.list);
    const lines = useSelector(state => state.incident.lines);

    useEffect(() => {
        if (workingLocation.id > 0) {
            //dispatch(incidentAction.getIncidentsByLocation(workingLocation.id));
            dispatch(incidentAction.getGroupByType(workingLocation.id));
        }
    },[workingLocation])

    return (
        <Paper style={{padding: 12, color: 'red'}}>
        
            <Box display="flex">
                <Box flexGrow={1}>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                    Incidents
                </Typography>
                </Box>

                
            </Box>

  
            {loading ?               
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>     
                : <TypeList lines={lines} />
            }
            
            {/*<FallsAnalysis locationID={workingLocation.id} />*/}
        </Paper>
    )
}