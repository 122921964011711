import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import React from "react";
import { dateFormat } from "../../_helpers/datetime";
import { InfoBlock } from "../ui/InfoBlock";

export const ClientDetail = ({referral}) => {

    const {id, firstName, lastName, preferredName, niNo, nhsNo, gender, dob} = referral;


    function dispalyGender (code) {
        switch (code) {
            case 'M':
                return 'Male';
            case 'F':
                return 'Female';
            default:
                return 'Other - ' + code;
        }
    }

    return (

        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="client-detail"
                id="client-detail"
            >
                <Typography variant="h6">Client Information</Typography>
            </AccordionSummary>
                
            <AccordionDetails>
                <InfoBlock label={"First Name "} value={firstName} />
                <InfoBlock label={"Last Name"} value={lastName} />
                <InfoBlock lable={"Preferred Name"} value={preferredName} />
                <InfoBlock label={"Identity "} value={niNo} />
                <InfoBlock label={"NHS No"} value={nhsNo} />
                <InfoBlock label={"Date of Birth "} value={dateFormat(dob)} />
                <InfoBlock label={"Gender "} value={dispalyGender(gender)} />
            </AccordionDetails>
        </Accordion>

    )

}