import { locationConstant } from "../_constants/locationConstant";

const initialState = {
            loading: false,
            list: [],
            working: {id:0}
        };

export function location(state = initialState, action) {
    switch (action.type) {
        case locationConstant.REQUEST: 
            return {
                ...state,
                loading: true,
            }
        case locationConstant.GET_LOCATIONS_SUCCESS:
            return {
                ...state,
                list: action.locations,
                working: action.locations[0],
                loading: false
            }
        case locationConstant.SET_WORKING_SCCESS:
            return {
                ...state,
                working: action.location,
            }
        default:
            return state;
    }
}