import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import React from "react";
import { InfoBlock } from "../ui/InfoBlock";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const ContactDetail = ({firstName, lastName, relationship, contactNo, contactEmail}) => {

    return (

        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="contact-detail"
                id="contact-detail"
            >
                <Typography variant="h6">Contact Detail</Typography>
            </AccordionSummary>
                
            <AccordionDetails>
                <InfoBlock label={"First Name "} value={firstName} valueType={"text"} />
                <InfoBlock label={"Last Name"} value={lastName} valueType={"text"} />
                <InfoBlock label={"Relationship"} value={relationship} valueType={"text"} />
                <InfoBlock label={"Contact No"} value={contactNo} valueType={"text"} />
                <InfoBlock label={"Contact Email"} value={contactEmail} valueType={"text"} />
            </AccordionDetails>
        </Accordion>
    )

}