import { incidentConstant } from "../_constants/incidentConstant";

const initialState = {
            loading: false,
            list: [],
            lines: [],
            incident: {id:0, checklist: []},
            types: [],
            reasons: [],
            fallAreas: [],
            injuryTypes: [],
            hEditing: false,
            aEditing: false,
        };

export function incident(state = initialState, action) {
    switch (action.type) {
        case incidentConstant.REQUEST: 
            return {
                ...state,
                loading: true,
            }
        case incidentConstant.GET_INCIDENTS_SUCCESS:
            return {
                ...state,
                list: action.incidents,
                incident: {id:0},
                loading: false
            }
        case incidentConstant.REGISTER_SUBMIT_SUCCESS:
            return {
                ...state,
                list: [...state.list, action.incident],
                incident: {...action.incident, checklist: []},
                loading: false,
            }
        case incidentConstant.GET_TYPES_SUCCESS: 
            return {
                ...state,
                types: action.types,
                loading: false,
            }
        case incidentConstant.GET_TYPE_REASONS_SUCCESS: 
            return {
                ...state,
                reasons: action.reasons,
                loading: false,
            }
        case incidentConstant.SET_INCIDENT_SCCESS:
            return {
                ...state,
                incident: {...action.incident, checklist: []},
                loading: false,
            }
        case incidentConstant.CLOSE_SUCCESS: 
            return {
                ...state,
                incident: {...action.incident, checklist: state.incident.checklist},
                list: state.list.map(incident =>  incident.id == action.incident.id ? action.incident : incident),
                loading: false, 
            }
        case incidentConstant.GET_INCIDENT_CHECKLIST_ITEMS_SUCCESS: 
            return {
                ...state,
                incident: {...state.incident, checklist: action.items},
                loading: false,
            }
        case incidentConstant.SET_INCIDENT_ITEM_EDIT: 
            return {
                ...state,
                incident: {...state.incident, checklist: state.incident.checklist.map(item =>  item.id == action.item.id ? {...item, status: 'E'} : item)},
            }
        case incidentConstant.SET_INCIDENT_ITEM_EDIT_CANCEL: 
            return {
                ...state,
                incident: {...state.incident, checklist: state.incident.checklist.map(item =>  item.id == action.item.id ? {...item, status: 'N'} : item)},
            }
        case incidentConstant.UPDATE_CHECKLIST_ITEM_SUBMIT_SUCCESS: 
            return {
                ...state,
                incident: {...state.incident, checklist: state.incident.checklist.map(item =>  item.id == action.item.id ? action.item : item)},
                aEditing: false,
            }
        case incidentConstant.UPDATE_CHECKLIST_ITEMS_SUBMIT_SUCCESS: 
            return {
                ...state,
                incident: {...state.incident, checklist: action.items},
                aEditing: false,
            }
        
        case incidentConstant.GET_FALL_AREAS_SUCCESS: 
            return {
                ...state,
                fallAreas: action.areas,
                loading: false,
        }
        case incidentConstant.GET_FALL_PREVENTIONS_SUCCESS: 
            return {
                ...state,
                fallPreventions: action.fallPreventions,
                loading: false,
            }
        case incidentConstant.GET_INJURY_TYPES_SUCCESS:
            return {
                ...state,
                injuryTypes: action.injuryTypes,
                loading: false,
            }
        case incidentConstant.SET_HEADER_EDITING: 
            return {
                ...state,
                hEditing: action.value,
            }
        case incidentConstant.SET_ADD_INFO_EDITING: 
            return {
                ...state,
                aEditing: action.value,
            }
        case incidentConstant.GET_GROUP_TYPE_SUCCESS: 
            return {
                ...state,
                lines: action.lines,
                loading: false, 
            }
        default:
            return state;
    }
}