import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { RotaForm } from '../components/rota/RotaForm';
import { useDispatch, useSelector } from 'react-redux';
import { employeeAction } from '../_actions/employeeAction';
import { rotaAction } from '../_actions/rotaAction';
import { Button, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';

import PrintIcon from '@mui/icons-material/Print';
import CopyAllIcon from '@mui/icons-material/CopyAll'
import { Link } from 'react-router-dom';
import moment from 'moment';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export const Shift = ({shift}) => {
    return (
        <Paper style={{padding: 12}}>

            <Box >
                <Box sx={{ typography: 'subtitle1' }}>{shift.rotaDate}</Box>
                <Box sx={{ typography: 'subtitle2' }}>{shift.fromTime} {shift.toTime}</Box>

                <Box sx={{ typography: 'body1', textAlign: 'left', m: 1, fontWeight: 'regular'}}>
                
                        {shift.lines.map(line => 
                            
                            <Typography key={line.id} component="div"  color={line.employee === null ? 'red' : 'green'}>
                                { (line.employeeLeave) ? <></> : (line.employee === null ? 'Not Allocated' : (line.employee.firstName + ' ' + line.employee.lastName))}
                            </Typography>
                            )}
                    
                </Box>
            </Box>

        </Paper>
    );
}

export const Rota = () => {

    const dispatch = useDispatch();

    const workingLocation = useSelector(state => state.location.working);
    const employees = useSelector(state => state.employee.employees);
    const [errors, setErrors] = useState({error: false});

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [weekStartDate, setWeekStartDate] = useState(moment());

    useEffect(() => {
        console.log("Reset Values");

    }, [])

    useEffect(() => {
        dispatch(rotaAction.init());
        dispatch(employeeAction.getLocationEmployees(workingLocation.id));
    }, [workingLocation]);


    const dayResource = useSelector(state => state.rota.weekRota);  

    const [currShift, setCurrShift] = useState({});

    const openRotaResource = (shift) => {
        setCurrShift(shift);
        handleOpen();
    }

    const loadWeek = (e) => {
        e.preventDefault();
        
        if (validate()) {
            dispatch(rotaAction.loadWeek(workingLocation.id, weekStartDate.format("YYYY-MM-DD")));
        } 
        
    }

    const submitShift = (shift) => {
        console.log(shift);
        dispatch(rotaAction.updateShift(shift));
        //setDayResource(dayResource.map(ishift => ishift.id === shift.id ? shift : ishift));
    }

    const copyToNextWeek = () => {
        console.log("Copy to Next Week");
        dispatch(rotaAction.copyWeek(workingLocation.id, weekStartDate.format("YYYY-MM-DD"), '2022-05-08' ));
    }

    const validate = () => {
        console.log("validate Forms")
        let formErrors = {error: false};

        if (weekStartDate.format("ddd") !== "Sun") {
            formErrors = {...formErrors, error: true, weekStartDate: 'Week start date must be a Sunday Date'};
        }

        setErrors(formErrors);
        return !formErrors.error;
    }

    return (
        <Paper style={{padding: 12, color: 'red'}}>
        <Box display="flex">
            <Box xs={1}>
              <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Rota
              </Typography>
            </Box>
        
            <Box xs={5} >
            <Box justifyContent="start" pt={3}>
            <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                    label="Week Start Date"
                    type="date"
                    name="weekStartDate"
                    value={weekStartDate}
                    onChange={(newDate) => setWeekStartDate(newDate)}
                    renderInput={(params) => <TextField {...params} 
                                                    error={(errors.weekStartDate) ? true: false}
                                                    helperText={errors.weekStartDate ? errors.weekStartDate : ''} />}
                    
                    
                />
            </LocalizationProvider>

            <Button onClick={loadWeek}>Load</Button>
            </Box>

        </Box>

        <Box xs={3} m={1}>
            <Button variant="contained" startIcon={<CopyAllIcon />} onClick={copyToNextWeek}>
                Copy to Next Week
            </Button>
        </Box>

        <Box xs={3} m={1} textAlign="right" >
            <Button variant="contained" startIcon={<PrintIcon />} component={Link} to="/rota/print">
                Print
            </Button>
            </Box>
        </Box>
        
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
                <Grid item xs>
                    {dayResource.mon && dayResource.mon.map((shift) => 
                    <Item>
                        <Paper elevation={3} onClick={() => openRotaResource(shift)}>
                            <Shift shift={shift} />
                        </Paper>
                    </Item>)}
                </Grid>
                <Grid item xs>
                    {dayResource.tue && dayResource.tue.map((shift) => 
                    <Item>
                        <Paper elevation={3} onClick={() => openRotaResource(shift)}>
                            <Shift shift={shift} />
                        </Paper>
                    </Item>)}
                </Grid>

                <Grid item xs>
                    {dayResource.wed && dayResource.wed.map((shift) => 
                    <Item>
                        <Paper elevation={3} onClick={() => openRotaResource(shift)}>
                            <Shift shift={shift} />
                        </Paper>
                    </Item>)}
                </Grid>

                <Grid item xs>
                    {dayResource.thu && dayResource.thu.map((shift) => 
                    <Item>
                        <Paper elevation={3} onClick={() => openRotaResource(shift)}>
                            <Shift shift={shift} />
                        </Paper>
                    </Item>)}
                </Grid>

                <Grid item xs>
                    {dayResource.fri && dayResource.fri.map((shift) => 
                    <Item>
                        <Paper elevation={3} onClick={() => openRotaResource(shift)}>
                            <Shift shift={shift} />
                        </Paper>
                    </Item>)}
                </Grid>

                <Grid item xs>
                    {dayResource.sat && dayResource.sat.map((shift) => 
                    <Item>
                        <Paper elevation={3} onClick={() => openRotaResource(shift)}>
                            <Shift shift={shift} />
                        </Paper>
                    </Item>)}
                </Grid>

                <Grid item xs>
                    {dayResource.sun && dayResource.sun.map((shift) => 
                    <Item>
                        <Paper elevation={3} onClick={() => openRotaResource(shift)}>
                            <Shift shift={shift} />
                        </Paper>
                    </Item>)}
                </Grid>

            </Grid>
        </Box>
        {open && <RotaForm submitShift={submitShift} handleClose={handleClose} shift={currShift} employees={employees}/>}
        </Paper>
    );
}