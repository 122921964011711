import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Button from "@mui/material/Button";

import { referralAction } from "../../_actions/referralAction";
import axios from "../../_helpers/axios";
import { Card, CardContent, CardHeader } from "@mui/material";


export const CloseReferral = ({referral}) => {
    const dispatch = useDispatch();

    const close = () => {
        dispatch(referralAction.closeSR(referral.id));
    }

    return (
        <Card>

            <CardHeader title="Close Referral Process" />

            <CardContent>
                <Button onClick={close} disabled={referral.status == 'D' ? true :  false} >Close Referal</Button>
            </CardContent>
        </Card>
        
    )
}