export const dashboardConstant = {
    REQUEST : 'DS_REQUEST',

    GET_MONTH_SUCCESS : 'DS_GET_MONTH_SUCCESS',
    GET_MONTH_ERROR : 'DS_GET_MONTH_ERROR',

    GET_WEEK_SUCCESS: 'DS_GET_WEEK_SUCCESS',

    GET_REFERRAL_P_REQUEST: 'DS_GET_REFERRAL_P_REQUEST',
    GET_REFERRAL_P_SUCCESS: 'DS_GET_REFERRAL_P_SUCCESS',

    GET_FALLS_SUCCESS: 'DS_GET_FALLS_SUCCESS',
    GET_SERIOUS_INJURY_SUCCESS: 'DS_GET_SERIOUS_INJURY_SUCCESS',
    GET_PRESSURE_INJURY_SUCCESS: 'DS_GET_PRESSURE_INJURY_SUCCESS',
    GET_INFECTIONS_SUCCESS: 'DS_GET_INFECTIONS_SUCCESS',
    GET_SAFEGUARDING_SUCCESS: 'DS_GET_SAFEGUARDING_SUCCESS',
    GET_TRANSFERTOHOSPITAL_SUCCESS: 'DS_GET_TRANSFERTOHOSPITAL_SUCCESS',
    GET_CQCNOTIFICATION_SUCCESS: 'DS_GET_CQCNOTIFICATION_SUCCESS',
    GET_REFERRAL_SUCCESS: 'DS_GET_REFERRAL_SUCCESS',
}