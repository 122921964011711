import { Box, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { JobRoleTable } from "../../components/jobrole/JobRoleTable";
import { employeeAction } from "../../_actions/employeeAction";


const initialState = {
    id: 0,
    abbr: '',
    description: '',
}

export const JobRole = () => {

    const dispatch = useDispatch();

    const workingLocation = useSelector(state => state.location.working);
    const jobRoles = useSelector(state => state.employee.jobRoles);

    const [isEditing, setIsEditing] = useState(false);
    const [currJobRole, setCurrJobRole] = useState({...initialState, locationID: workingLocation.id});


    useEffect(() => {
        console.log('Working Location : ' + workingLocation);
        dispatch(employeeAction.getJobRoles());
    }, [workingLocation]);

    const create = (jobRole) => {
        console.log('Create Clicked');
        console.log(jobRole);
        //dispatch(jobRole.createJobRole(jobRole));
        setIsEditing(false);
    }

    const update = (jobRole) => {
        console.log('Update Clicked');
        console.log(jobRole);
        //dispatch(jobRole.updateJobRole(jobRole));
        setIsEditing(false);
    }

    const loadCurrJobRole = (jobRole) => {
        setCurrJobRole(jobRole);
        setIsEditing(true);
    }

    const closeForm = (e) => {
        e.preventDefault();
        setIsEditing(false);
    }

    return (
        
        <Paper style={{padding: 12, color: 'red'}}>

        <Box display="flex">
            <Box flexGrow={1}>
              <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Job Roles
              </Typography>
            </Box>

            <Box>
                {/*!isEditing &&
             
                    <Button variant="contained" color="primary" onClick={(e) => {e.preventDefault(); setIsEditing(true)} }>
                        CREATE
                    </Button>
                */}
            </Box>
        </Box>

        
        

        {/*isEditing ? <JobRoleTable workingLocation={workingLocation} closeForm={setIsEditing} currOccupy={currOccupy}  create={create} update={update} close={closeForm} /> 
                    : <OccupancyTable  occupancies={occupancies} loadCurrOccupy={loadCurrOccupy}/> 
            */}

            <JobRoleTable jobRoles={jobRoles} loadCurrJobRole={loadCurrJobRole} />
        </Paper>
    );
}