import { combineReducers } from "redux";

import { alert } from "./alertReducer";
import { auth } from "./authReducer";
import { careband } from "./careBandReducer";
import { dashboard } from "./dashboardReducer";
import { employee } from "./employeeReducer";
import { entry } from "./entryReducer";
import { fund } from "./fundReducer";
import { incident } from "./incidentReducer";
import { location } from "./locationReducer";
import { referral } from "./referralReducer";
import { rota } from "./rotaReducer";
import { room } from "./roomReducer";
import { skapp } from "./appReducer";

export const rootReducer = combineReducers({
    alert,
    auth,
    careband,
    dashboard,
    employee,
    entry,
    incident,
    fund,
    location,
    referral,
    rota,
    room,
    skapp,
})                   