import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import React from "react";
import { InfoBlock } from "../ui/InfoBlock";

export const DataDetail = ({isQualified1, assessmentDate, isQualified2, careBand, admissionDate, agreedAmount, room}) => {

    return (
        <Card>
            <CardHeader title="Other Information" />

            <CardContent>
                <Grid container spacing={2}>
                    <Grid item>
                        <InfoBlock label={"Referral Update "} value={isQualified1 == 'Y' ? 'Qualified' : 'Not Qualified'} valueType={"text"} />
                        
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )

}