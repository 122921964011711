import { Box, Button, IconButton, Paper, Tab, Tabs, Typography } from "@mui/material";
import moment, { localeData } from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { OccupancyTable } from "../components/room/OccupancyTable";
import { OccupyForm } from "../components/room/OccupyForm";
import { roomAction } from "../_actions/roomAction";

import { Detail as RoomDetail } from "../components/room/Detail";
import { alertActions } from "../_actions/alertAction";
import { fundAction } from "../_actions/fundAction";

import CloseIcon from '@mui/icons-material/Close';

const initialState = {
    id: 0,
    firstName: '',
    lastName: '',
    roomID: '',
    fromDate: '',
    toDate: '',
    locationID: 0,
}

// status: C - Completed, U - Updated, P - Pending
const initialWorkFlowState = [
    {step: 1, description: "Room Occupy Period", status: 'C'}, 
    {step: 2, description: "Fund Information", status: 'C'},
];

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export const RoomOccupancy = () => {

    const dispatch = useDispatch();

    const workingLocation = useSelector(state => state.location.working);
    //const occupancies = useSelector(state => state.room.occupancies);
    const rooms = useSelector(state => state.room.rooms);

    const [isEditing, setIsEditing] = useState(false);
    const [currRoom, setCurrRoom] = useState({});

    const [workFlow, setWorkFlow] = useState(initialWorkFlowState);
    const [txnState, setTxnState] = useState('V'); // E - Edit , C - Create, V - View

    /*
    useEffect(() => {
        dispatch(roomAction.getActiveOccupancies());
    }, []);*/

    useEffect(() => {
        dispatch(alertActions.clear());
    },[txnState])

    useEffect(() => {
        console.log('Working Location : ' + JSON.stringify(workingLocation));
        
        if (workingLocation.id > 0) {
            setTxnState('V');
            //dispatch(roomAction.getActiveOccupancies(workingLocation.id));
            dispatch(roomAction.getRoomsWithCurrBooking(workingLocation.id));

            dispatch(fundAction.getFundTypes());
        }
        
    }, [workingLocation]);

    const createResident = (occupy) => {
        console.log('Create Clicked');
        console.log(occupy);
        //dispatch(roomAction.createRoomOccupy(occupy));
        //setIsEditing(false);
        let customer = {id: 0, firstName: occupy.firstName, lastName: occupy.lastName, niNo: occupy.niNo};
        setCurrRoom({...currRoom,  currBooking: {...occupy, customer}})
        setTxnState('C2');
    }

    const update = (occupy) => {
        console.log('Update Clicked');
        console.log(occupy);
        dispatch(roomAction.updateRoomOccupy(occupy));
        setIsEditing(false);
    }

    const loadCurrRoom = (room) => {
        console.log(room)
        //if (room.currBooking.id > 0 ) {
            setTxnState('E');
        //} else {
        //    setTxnState('C1');
        //}
            
        setCurrRoom(room);

    }

    const closeChild = (e) => {
        e.preventDefault();
        setTxnState('V');
    }

    const loadComponent = () => {
        
    }



    return (

        <Paper style={{padding: 12}}>

            <Box display="flex">
                <Box flexGrow={1}>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                    Room Occupancy
                </Typography>
                </Box>

                <Box>
                    {(currRoom.id > 0 && txnState != "V") &&
                        <IconButton aria-label="Close" size="large"  onClick={closeChild}>
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                </Box>
            </Box>

            {txnState === 'E' &&
                <RoomDetail room={currRoom} close={closeChild}/> 
            }

            {txnState === 'C1' &&
                <OccupyForm 
                    currRoom={currRoom}
                    create={createResident} 
                />
            }   
            
            {txnState === 'C2' &&
                    <RoomDetail room={currRoom} edit="fund" close={closeChild} /> 
            }

            {txnState === 'V' &&
                <OccupancyTable  rooms={rooms} loadCurrRoom={loadCurrRoom}/> 
            }

        </Paper>
    );
}