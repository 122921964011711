import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { CYCLES } from "../../_constants/appConstant";
import { dateFormat } from "../../_helpers/datetime";
import { localCurrency } from "../../_helpers/number";
import { InfoBlock } from "../ui/InfoBlock";

export const FundDetail = ({funds}) => {

    const [total, setTotal] = useState(0);

    useEffect(() => {
        console.log(funds)
        if (funds) {
            let fundTotal = funds.map(fund => parseFloat(fund.amount)).reduce((prevVal, currVal) => prevVal + currVal, 0);
            setTotal(fundTotal);
        }

    }, [funds]);

    function displayCycleName(code) {
        return CYCLES.find(cycle => cycle.code === code).name;
    }

    return (

        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="assessment-detail"
                id="assessment-detail"
            >
                <Typography variant="h6">Fund Detail</Typography>
            </AccordionSummary>
                
            <AccordionDetails>
                <Grid container spacing={2}>
                    {funds && funds.map(fund => 
                        <>
                        <Grid item xs={6}> {fund.fundName}</Grid>
                        <Grid item xs={2}> {displayCycleName(fund.cycle)}</Grid>
                        <Grid item xs={4} textAlign={"end"}> {localCurrency(fund.amount)}</Grid>
                        </>
                    )}
                
                    <Grid item xs={8}>  </Grid>
                    <Grid item xs={4} textAlign={"end"}>______________  </Grid>

                    <Grid item xs={8}> Total </Grid>
                    <Grid item xs={4} textAlign={"end"}> {localCurrency(total)}</Grid>

                </Grid>
            </AccordionDetails>
        </Accordion>

    )

}