import { Card, CardContent, CardHeader, Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, FormHelperText } from "@mui/material";
import { TextField } from "@mui/material";
import React, {useState, useEffect} from "react";
import { useDispatch } from "react-redux";

import moment from "moment";
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { dateFormat, SYS_DATE_FORMAT } from '../../_helpers/datetime';

import { referralAction } from '../../_actions/referralAction';
import { referral } from "../../_reducers/referralReducer";

export const EvaluationForm = ({referral, referredDate, updateField}) => {

    const dispatch = useDispatch();

    const [isQualified, setIsQualified] = useState('');
    const [remark, setRemark] = useState("");
    const [assessmentDate, setAssessmentDate] = useState('');

    const [errors, setErrors] = useState([]);

    useEffect(() => {
        setIsQualified(referral.isQualified1);
        setAssessmentDate(moment(referral.assessmentDate));
    },[referral])

    const handleChange = (event) => {
        setIsQualified(event.target.value);
    };

    useEffect(() => {

        console.log(assessmentDate)

        let formErrors = {error: false};

        if (assessmentDate)
        {
            //console.log( moment.duration(assessmentDate.startOf('day').diff(moment(referredDate).startOf('day'))).asDays());
            console.log(moment(referredDate).startOf('day'))
            console.log(assessmentDate.startOf('day'))
            if (moment(referredDate).startOf('day').isAfter(assessmentDate.startOf('day'))) {
                formErrors = {...formErrors, error: true, assessmentDate: 'Assessment Date cannot be less than referral registered date'};
            } else if (moment.duration(assessmentDate.startOf('day').diff(moment(referredDate).startOf('day'))).asDays() > 7) {
                formErrors = {...formErrors, error: true, assessmentDate: 'Assessment Date is more than 7 days'};
            }
        }
        setErrors(formErrors);
    }, [assessmentDate]);

    const validate = () => {
        console.log("validate Forms")
        console.log(isQualified)
        let formErrors = {error: false};

        if (!isQualified || isQualified == '') {
            formErrors = {...formErrors, error: true, isQualified: 'Invalid Option'};
        }

        if (isQualified === 'Y') {
            console.log(assessmentDate);
            //if (!assessmentDate || assessmentDate.length <= 0) {
            if (!assessmentDate.isValid()) {
                formErrors = {...formErrors, error: true, assessmentDate: 'Required Assessment Date'};
            } else if (moment(referredDate).startOf('day').isAfter(assessmentDate.startOf('day'))) {
                formErrors = {...formErrors, error: true, assessmentDate: 'Assessment Date cannot be less than referral registered date'};
            } else if (assessmentDate.startOf('day').isAfter(moment().startOf('day'))){
                formErrors = {...formErrors, error: true, assessmentDate: 'Assessment Date cannot be greater than system date'};
            }

        } 

        if ( isQualified === 'N' && (!remark || remark.length <= 0)) {
            formErrors = {...formErrors, error: true, remark: 'Please add a note why not qualified'};
        }

        setErrors(formErrors);
        console.log(formErrors)
        return !formErrors.error;
    }

    
    const onSubmit = (e) => {
        e.preventDefault();

        if (validate()) {
            console.log(assessmentDate)
            dispatch(referralAction.updateQualify(referral.id, updateField, isQualified, remark, assessmentDate));
        }
        
    }

    return (
        <Card>
            <CardHeader title="Evaluation Form" />

            <CardContent>

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <FormControl error={errors.isQualified ? true : false}>
                            <FormLabel id="isQualified">Is Qualified</FormLabel>

                            <RadioGroup
                                aria-labelledby="isQualified"
                                name="isQualified"
                                value={isQualified}
                                onChange={handleChange}
                                row
                            >
                                <FormControlLabel value="Y" label="Yes" control={<Radio />}></FormControlLabel>
                                <FormControlLabel value="N" label="No" control={<Radio />}></FormControlLabel>
                            </RadioGroup>

                            <FormHelperText >{errors.isQualified ? errors.isQualified : '' }</FormHelperText>
                        </FormControl>

                    </Grid>

                    {isQualified == 'Y' &&
                    <Grid item xs={12} >
                        <LocalizationProvider dateAdapter={DateAdapter}>
                        <DatePicker
                            label="Assessment Date"
                            type="date"
                            mask="__-__-____"
                            value={assessmentDate}
                            inputFormat={SYS_DATE_FORMAT}
                            onChange={(newValue) => {
                                setAssessmentDate(newValue);
                            }}
                            //maxDate={moment()}
                            minDate={moment(referredDate)}
                            renderInput={(params) => 
                                <TextField {...params}
                                    fullWidth
                                    disabled
                                    error={errors.assessmentDate ? true : false}
                                    helperText={errors.assessmentDate ? errors.assessmentDate : ''} />}
                        />
                        </LocalizationProvider>
                    </Grid> }

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            
                            fullWidth
                            id="remark"
                            name="remark"
                            label="Remark"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                            error={errors.remark ? true : false}
                            helperText={errors.remark ? errors.remark : ''}
                        />
                    </Grid>
                    
                    <Button variant="contained" sx={{ m: 1}} onClick={onSubmit}>Save</Button>

                </Grid>
        </CardContent>
        </Card>
    )
}