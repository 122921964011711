import { entryConstant } from "../_constants/entryConstant";

const initialState = {
            loading: false,
            entries: [],
            entry: {},
            currPeriod: {id: 0},
            entryPeriod: {id: 0},
        };

export function entry(state = initialState, action) {
    switch (action.type) {
        case entryConstant.REQUEST: 
            return {
                ...state,
                loading: true,
            }
        case entryConstant.GET_LOC_ENTRIES_SUCCESS:
            return {
                ...state,
                loading: false,
                entries: action.entries,
            }
        case entryConstant.UPDATE_SUCCESS : 
            return {
                ...state,
                uloading: false,
                entry: action.entry,
                entries: state.entries.map(entry => entry.id == action.entry.id ? action.entry : entry), 
            }
        case entryConstant.GET_CURR_PERIOD_SUCCESS: 
            return {
                ...state,
                currPeriod: action.period,
            }
        case entryConstant.GET_ENTRY_PERIOD_SUCCESS: 
            return {
                ...state,
                entryPeriod: action.period,
            }
        default:
            return state;
    }
}