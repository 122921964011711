import { Box, Card, CardHeader, Grid } from "@mui/material"
import { localCurrency } from "../../_helpers/number"
import { WRLabel } from "../ui/InfoBlock"

export const CPHeader = ({value}) => {
    return (
        <Box
            sx={{
                backgroundColor: "#eecd8f",padding: 1,
                border: 1,
                }}
        >
            {value}
        </Box>
    )
}

export const CPActual = ({value}) => {
    return (
        <Box
            sx={{
                backgroundColor: '#84efc5',
                padding: 1,
                border: 1,
                }}
        >
            {value}
        </Box>
    )
}

export const CPLabel = ({value}) => {
    return (
        <Box
            sx={{
                backgroundColor: '#bbd6ee',
                padding: 1,
                border: 1,
                }}
        >
            {value}
        </Box>
    )
}

export const CurrentPerformance = ({room}) => {
    return (
        <Card>

            <CardHeader title="Week Performance" />

            <Grid container>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}><CPHeader value="Weekly avg fee" /></Grid>
            <Grid item xs={3}><CPHeader value="Revenue - Week" /></Grid>
            <Grid item xs={3}><CPHeader value="Wages - Month" /></Grid>

            <Grid item xs={3}><CPActual value="Actual" /></Grid>
            <Grid item xs={3}>{room && <CPActual value={localCurrency(room.actual / room.noOfRooms)} />}</Grid>
            <Grid item xs={3}><CPActual value="-" /></Grid>
            <Grid item xs={3}><CPActual value="-" /></Grid>

            <Grid item xs={3}><CPLabel value="Target" /></Grid>
            <Grid item xs={3}>{room && <CPLabel value={localCurrency(room.target / room.noOfRooms)} />}</Grid>
            <Grid item xs={3}><CPLabel value="-" /></Grid>
            <Grid item xs={3}><CPLabel value="-" /></Grid>
        </Grid>
        </Card>
        
    )
}