import { useState, useEffect } from 'react';

import { Box, Button, CircularProgress, IconButton, Paper, Stack, Typography } from "@mui/material"
import { IncidentList } from "./List"
import { RegisterIncidentForm } from './RegisterForm';
import { useDispatch, useSelector } from 'react-redux';
import { IncidentDetail } from './Detail';
import { incidentAction } from '../../_actions/incidentAction';
import { LoadingButton } from '@mui/lab';

import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import { CloseIncidentForm } from './CloseIncidentForm';


const initialState = {
    id: 0,
    roomBookingID: 0,
    incidentDateTime: '',
    typeID: '',
    reasonID: '',
}

export const Incident = ({customerID, roomBookingID}) => {
    
    const dispatch = useDispatch();

    const [txnType, setTxnType] = useState("VIEW");
    const [isEditing, setIsEditing] = useState(false);
    const [currIncident, setCurrIncident] = useState(initialState);
    const [confirmClose, setConfirmClose] = useState(false);

    const workingLocation = useSelector(state => state.location.working);
    const loading = useSelector(state => state.referral.listLoading);

    const incident = useSelector(state => state.incident.incident);
    const incidents = useSelector(state => state.incident.list);

    useEffect(() => {
        console.log("Initial Load");
        setTxnType("LIST");
        dispatch(incidentAction.getIncidentsByCustomer(customerID));
        console.log("Incident Index Page is loaded")
        dispatch(incidentAction.setIncident({id: 0, list: []}))
    },[]);

    useEffect(() => {
        console.log('Working Location : ' + JSON.stringify(workingLocation));
        
        if (workingLocation.id > 0) {
            //setTxnType('VIEW');
            // dispatch(incidentAction.setIncident({id: 0, list: []}))
            console.log("Locaton change in Incident")
        }
        
    }, [workingLocation]);

    useEffect(() => {
        setCurrIncident(incident);
        if (incident.id > 0) {
            console.log("Active Incident found in state")

            setTxnType("VIEW");
        } else {
            setTxnType("LIST");
        }
        
        //if (txnType == "VIEW") {
            // setTxnType("LIST");
        //}

        if (incident.status == 'C') {
            setConfirmClose(false);
        }
        
    }, [incident]);

    const newIncident = () => {
        setTxnType("NEW");
        setIsEditing(true);

        setCurrIncident({...initialState, locationID: workingLocation.id, customerID: customerID, roomBookingID: roomBookingID});

    }

    const loadCurrIncident = (incident) => {
        console.log("Load Current Incident");
        console.log(incident)
        //setCurrIncident(incident);
        //setTxnType("VIEW");
        dispatch(incidentAction.setIncident(incident));
    }

    const closeForm = () => {
        setTxnType("LIST");
        setIsEditing(false);
        setConfirmClose(false);
        //dispatch(alertActions.clear());
    }


    return (
        <Paper style={{padding: 12, margin: 1}}>
        
            <Box display="flex">
                <Box flexGrow={1}>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                    
                </Typography>
                </Box>

                <Box>
                <Stack direction={"row"} spacing={1}>
                    {!isEditing &&  txnType === "LIST" &&              
                

                    <IconButton aria-label="Add" size="large"  onClick={newIncident} color='primary'>
                        <AddCircleOutlineSharpIcon fontSize="inherit" />
                    </IconButton>
                    }

                    {(currIncident.id > 0 && currIncident.status == 'P' && txnType === "VIEW") &&
                        <Button variant="outlined" color="warning" onClick={() => setConfirmClose(true)}>
                        Close Incident
                        </Button>
                    }


                
                    {(currIncident.id > 0 && txnType === "VIEW") &&
                        <IconButton aria-label="Close" size="large"  onClick={closeForm} >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                           
                    </Stack>
                </Box>
            </Box>


            {confirmClose &&  <CloseIncidentForm incidentID={currIncident.id} close={() => setConfirmClose(false)} />}
            {txnType === "VIEW" && currIncident.id > 0 && <IncidentDetail incident={currIncident} />}
            {txnType === "NEW" && <RegisterIncidentForm currIncident={currIncident} close={closeForm} />}
            
            {txnType === "LIST" && (loading ?               
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>     
                : <IncidentList incidents={incidents} loadCurrIncident={loadCurrIncident} />)
            }

        </Paper>
    )
}