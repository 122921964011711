import { Button, ButtonGroup, Chip, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import { dateFormat } from "../../_helpers/datetime";
import { IncidentRow } from "./Row";

export const IncidentList = ({incidents, loadCurrIncident}) => {

    function statusDescription (status) {
        switch (status) {
            case "P":
                return <Chip label="Pending" color="warning" size="small" />
            case "C":
                return <Chip label="Closed" color="success" size="small" />
            default:
                return "--";
        }
    }

    return (
      

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="right">#</TableCell>
                        <TableCell align="left">Resident</TableCell>
                        <TableCell align="left">Date</TableCell>
                        <TableCell align="left">Type</TableCell>
                        <TableCell align="left">Reason</TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="center">Action</TableCell>
                    </TableRow>
                </TableHead>    

                <TableBody>
                    {incidents && incidents.map( (incident, index) => 
                    <TableRow key={incident.id}>
                    <TableCell align="right">{index + 1}</TableCell>
                    <TableCell align="left">{`${incident.firstName} ${incident.lastName}`}</TableCell>
                    <TableCell align="left">{dateFormat(incident.incidentDateTime)}</TableCell>
              
                    <TableCell align="left">{incident.typeDescription}</TableCell>
                    <TableCell align="left">{incident.reasonDescription}</TableCell>

                    <TableCell align="left">{statusDescription(incident.status)}</TableCell>
                    
                    <TableCell align="center">
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                      <Button onClick={() => {loadCurrIncident(incident)}}>More</Button>
                 
                    </ButtonGroup>
                  </TableCell>

                  </TableRow>

    )}
                  
                </TableBody>        
            </Table>
    
  
    )
}