import { Checkbox, FormControl, Grid, MenuItem, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';


export const RotaResoruce = ({resource, employees, resourceChange}) => {

    const [leave, setLeave] = useState({id: 0, leaveType: "1", leaveTypeDescscription: "Sick Leave"});

    const [onLeave, setOnLeave] = useState(false);

    useEffect(() => {
        console.log("Resource changed -> ")
        if (resource.employeeLeave) {
            console.log("OnLeave -> true")
            setOnLeave(true); 
            setLeave({...resource.employeeLeave, leaveTypeDescscription: "Sick Leave"});
        } else {
            console.log("OnLeave -> false");
            setOnLeave(false) ;   
            setLeave(leave);
        }
    },[resource]);
    
    const textOnChange = (e) => {
        const {name, value} = e.target;

        console.log(name + ":" + value);
        console.log(resource)
        resourceChange(resource.id, name, value);
    }

    const checkOnChange = (e) => {
        const {name, checked} = e.target;
        console.log(name + "::" + checked);
        if (checked) {
            setOnLeave(true);
            resourceChange(resource.id, name, leave);
        } else {
            setOnLeave(false);
            resourceChange(resource.id, name, null);
        } 
                    
    }


    return (
        <>
        <Grid item xs={12} sm={4} key={resource.id}>
          <TextField
              key={resource.id}
              name="employeeID"
              id="employeeID"
              select
              label={resource.jobRole.name}
              value={resource.employee ? resource.employee.id : ''}
              onChange={textOnChange}
              variant="outlined"
              disabled={onLeave}
              fullWidth
          >
              {employees.map(employee => <MenuItem key={employee.id} value={employee.id}>{employee.firstName} {' '} {employee.lastName}</MenuItem>)}
          </TextField>
        </Grid>
        
        <Grid item xs={12} sm={4}>
            <Checkbox  
                name="employeeLeave"
                checked={onLeave}
                onChange={checkOnChange}
                disabled={onLeave}
            /> {leave.leaveTypeDescscription}
        </Grid>
        <Grid item xs={12} sm={4}></Grid>
        </>
    );
}