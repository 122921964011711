import { employeeConstant } from "../_constants/employeeConstant";

const initialState = {
            loading: false,
            employees: [],
            jobRoles: [],
        };

export function employee(state = initialState, action) {
    switch (action.type) {
        case employeeConstant.REQUEST: 
            return {
                ...state,
                loading: true,
            }
        case employeeConstant.GET_EMPLOYEES_SUCCESS:
            return {
                ...state,
                employees: action.employees,
                loading: false
            }
        
        case employeeConstant.CREATE_SUCCESS:
            return {
                ...state,

                employees: [...state.employees, action.employee]
            }
        case employeeConstant.UPDATE_SUCCESS:
            return {
                ...state,
                employees: state.employees.map(employee => employee.id === action.employee.id ? action.employee : employee),
            }
        case employeeConstant.GET_JOBROLES_SUCCESS:
            return {
                ...state,
                jobRoles: action.jobRoles,
            }
        case employeeConstant.GET_CONTRACTS_SUCCESS: 
            return {
                ...state,
                contracts: action.contracts,
                loading: false,
            }
        case employeeConstant.ADD_CONTRACT_SUCCESS:
            return {
                ...state,
                contracts: [...state.contracts, action.contract],
                loading: false,
            }
        case employeeConstant.UPDATE_CONTRACT_SUCCESS:
            return {
                ...state,
                contracts: state.contracts.map(contract => contract.id === action.contract.id ? action.contract : contract),
                loading: false,
            }
        default:
            return state;
    }
}