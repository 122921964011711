import { locationConstant } from "../_constants/locationConstant";
import axios from '../_helpers/axios';

export const locationAction = {
    getLocations,
    setworkingLocation,
}


function getLocations() {

    return (dispatch) => {

        dispatch(request());

        axios.get('/api/v1/location/user')
            .then(response => {
                dispatch(success(response.data))
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: locationConstant.REQUEST}}
    function success(locations) { return {type: locationConstant.GET_LOCATIONS_SUCCESS, locations}}
    function error(error) { return {type: locationConstant.GET_LOCATIONS_ERROR, error}}
}

function setworkingLocation(location) {

    return (dispatch) => {

        dispatch(success(location));
        
    };

    function request() {return {type: locationConstant.REQUEST}}
    function success(location) { return {type: locationConstant.SET_WORKING_SCCESS, location}}

}