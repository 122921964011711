import { referralConstant } from "../_constants/referralConstant";
import axios from '../_helpers/axios';
import { alertActions } from "./alertAction";

import { appActions }  from './appAction';

export const referralAction = {
    reset, 
    generateAdmissionForm,

    getLocationReferrals,

    getTasks,
    getFunds,
    
    register,

    updateQualify,
    updateAssessment,
    updateConfirm,
    updateRoom,
    updateFunds,
    printConfirm,
    admint,
    closeSR,

    setCurrReferral,

    openFrom,

    getCloseReasons,
}

function reset() {

    return (dispatch) => {
        dispatch(success());
        
    };

    function success() {return {type: referralConstant.RESET}}

}

function register(referral) {
    return dispatch => {
        console.log(referral);
        
        axios.post('/api/v1/referral', referral) 
            .then(response => {
                dispatch(success(response.data.data));
                dispatch(alertActions.success(response.data.message));
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: referralConstant.REQUEST}}
    function success(referral) { return { type: referralConstant.SUBMIT_SUCCESS,referral}}
    function error(error) { return {type: referralConstant.SUBMIT_SUCCESS, error}}
}

function getLocationReferrals(location, status) {

    return (dispatch) => {

        dispatch(request());
        dispatch(appActions.startLoading());

        axios.get(`/api/v1/referral/${location.id}/${status}`)
            .then(response => {
                dispatch(success(response.data))
            })
            .catch(error => {
                console.log(error);
            });

        dispatch(appActions.stopLoading());
    };

    function request() {return {type: referralConstant.GET_REFERRALS_REQUEST}}
    function success(referrals) { return {type: referralConstant.GET_LOCATION_STATUS_SUCCESS, referrals}}
    function error(error) { return {type: referralConstant.GET_LOCATION_STATUS_ERROR, error}}
}


function generateAdmissionForm(referralID) {
    return dispatch => {
        console.log(referralID);
        
        axios.post('/api/v1/referral/generate/'+referralID) 
            .then(response => {
                console.log(response.data.data);
                dispatch(success(response.data.data));
                dispatch(alertActions.success(response.data.message));
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: referralConstant.REQUEST}}
    function success(referral) { return { type: referralConstant.SUBMIT_UPDATE_SUCCESS,referral}}
    function error(error) { return {type: referralConstant.SUBMIT_SUCCESS, error}}
}

function updateQualify(referralID, updateField, isQualified, remark, assessmentDate) {
    return dispatch => {
        console.log(referralID);
        console.log(updateField);
        console.log(isQualified);

        axios.put(`/api/v1/referral/${referralID}/${updateField}/${isQualified}`, {remark: remark, assessmentDate: assessmentDate })
            .then(response => {
                dispatch(success(response.data.data));
                dispatch(alertActions.success(response.data.message));
            })
            .catch(error => {
                dispatch(alertActions.error(error.message));
                console.log(error);
            })
    };

    function request() {return {type: referralConstant.REQUEST}}
    function success(referral) { return { type: referralConstant.SUBMIT_UPDATE_SUCCESS,referral}}
}

function updateAssessment(referralID, isSutable, careBandId, body) {
    return dispatch => {
        console.log(referralID);
        console.log(careBandId);
        console.log(isSutable);

        axios.put('/api/v1/referral/'+referralID+'/assessment/'+isSutable+'/'+careBandId, body)
            .then(response => {
                dispatch(success(response.data.data));
                dispatch(alertActions.success(response.data.message));
            })
            .catch(error => {
                dispatch(alertActions.error(error.message));
                console.log(error);
            })
    };

    function request() {return {type: referralConstant.REQUEST}}
    function success(referral) { return { type: referralConstant.SUBMIT_UPDATE_SUCCESS,referral}}
}

function updateRoom(referralID, roomID) {
    return dispatch => {

        axios.put('/api/v1/referral/'+referralID+'/room/'+roomID)
            .then(response => {
                dispatch(success(response.data.data));
                dispatch(alertActions.success(response.data.message));
            })
            .catch(error => {
                dispatch(alertActions.error(error.message));
                console.log(error);
            })
    };

    function request() {return {type: referralConstant.REQUEST}}
    function success(referral) { return { type: referralConstant.SUBMIT_UPDATE_SUCCESS,referral}}
}

function updateFunds(referralID, funds) {
    return dispatch => {

        axios.put('/api/v1/referral/'+referralID+'/fund', funds)
            .then(response => {
                dispatch(success(response.data.data));
                dispatch(alertActions.success(response.data.message));
            })
            .catch(error => {
                dispatch(alertActions.error(error.message));
                console.log(error);
            })
    };

    function request() {return {type: referralConstant.REQUEST}}
    function success(referral) { return { type: referralConstant.SUBMIT_UPDATE_SUCCESS,referral}}
}

function setCurrReferral(referral) {
    return dispatch => dispatch({type: referralConstant.SET_CURR, referral})
}

function updateConfirm(referralID, admissionDate, agreedAmount, body) {
    return dispatch => {

        axios.put(`/api/v1/referral/${referralID}/confirm/${admissionDate}/${agreedAmount}`, body)
            .then(response => {
                dispatch(success(response.data.data));
                dispatch(alertActions.success(response.data.message));
            })
            .catch(error => {
                dispatch(alertActions.error(error.message));
                console.log(error);
            })
    };

    function request() {return {type: referralConstant.REQUEST}}
    function success(referral) { return { type: referralConstant.SUBMIT_UPDATE_SUCCESS,referral}}
}

function printConfirm(referralID) {
    return dispatch => {

        axios.put('/api/v1/referral/print/'+referralID+'/confirm')
            .then(response => {
                dispatch(success(response.data.data));
                dispatch(alertActions.success(response.data.message));
            })
            .catch(error => {
                dispatch(alertActions.error(error.message));
                console.log(error);
            })
    };

    function request() {return {type: referralConstant.REQUEST}}
    function success(referral) { return { type: referralConstant.SUBMIT_UPDATE_SUCCESS,referral}}
}

function admint(referralID, idNo, remark) {
    return dispatch => {

        axios.post(`/api/v1/referral/admit/${referralID}/${idNo}`, {remark: remark})
            .then(response => {
                dispatch(success(response.data.data));
                dispatch(alertActions.success(response.data.message));
            })
            .catch(error => {
                dispatch(alertActions.error(error.message));
                console.log(error);
            })
    };

    function request() {return {type: referralConstant.REQUEST}}
    function success(referral) { return { type: referralConstant.SUBMIT_UPDATE_SUCCESS,referral}}
}

function closeSR(referralID, remark) {
    return dispatch => {

        axios.put(`/api/v1/referral/close/${referralID}`, {remark: remark})
            .then(response => {
                dispatch(success(response.data.data));
                dispatch(alertActions.success(response.data.message));
            })
            .catch(error => {
                dispatch(alertActions.error(error.message));
                console.log(error);
            })
    };

    function request() {return {type: referralConstant.REQUEST}}
    function success(referral) { return { type: referralConstant.CLOSE_SUCCESS,referral}}
}



function getTasks(referralID) {

    return (dispatch) => {

        dispatch(request());
        dispatch(appActions.startLoading());

        axios.get('/api/v1/sr/detail/'+referralID)
            .then(response => {
                dispatch(success(response.data))
            })
            .catch(error => {
                console.log(error);
            });

        dispatch(appActions.stopLoading());
    };

    function request() {return {type: referralConstant.GET_SR_REQUEST}}
    function success(detail) { return {type: referralConstant.GET_SR_SUCCESS, detail}}
    function error(error) { return {type: referralConstant.GET_SR_ERROR, error}}
}

function getFunds(referralID) {

    return (dispatch) => {

        dispatch(request());
        dispatch(appActions.startLoading());

        axios.get(`/api/v1/referral/${referralID}/funds`)
            .then(response => {
                dispatch(success(response.data))
            })
            .catch(error => {
                console.log(error);
            });

        dispatch(appActions.stopLoading());
    };

    function request() {return {type: referralConstant.REQUEST}}
    function success(funds) { return {type: referralConstant.GET_FUNDS_SUCCESS, funds}}
    function error(error) { return {type: referralConstant.GET_SR_ERROR, error}}
}

function openFrom(referralID, stage) {
    return dispatch => {

        axios.post(`/api/v1/referral/openfrom/${referralID}/${stage.srHeader.id}/${stage.id}`)
            .then(response => {
                dispatch(success(response.data.data));
                dispatch(alertActions.success(response.data.message));

                dispatch(getTasks(referralID));
            })
            .catch(error => {
                dispatch(alertActions.error(error.message));
                console.log(error);
            })
    };

    function request() {return {type: referralConstant.REQUEST}}
    function success(referral) { return { type: referralConstant.SUBMIT_UPDATE_SUCCESS,referral}}
}

function getCloseReasons() {

    return (dispatch) => {

        //dispatch(request());
        //dispatch(appActions.startLoading());

        axios.get('/api/v1/referral/closeReasons')
            .then(response => {
                dispatch(success(response.data.data))
            })
            .catch(error => {
                console.log(error);
            });

        dispatch(appActions.stopLoading());
    };

    function request() {return {type: referralConstant.GET_REASONS_REQUEST}}
    function success(reasons) { return {type: referralConstant.GET_REASONS_SUCCESS, reasons}}
    function error(error) { return {type: referralConstant.GET_REASONS_ERROR, error}}
}