import { Button, ButtonGroup, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { dateFormat } from "../../_helpers/datetime";
import { IncidentRow } from "./Row";

export const TypeList = ({lines, loadCurrIncident}) => {

    function statusDescription(status) {
        switch (status) {
            case 'P':
                return "Pending";
            case 'C':
                return "Closed";
            default:
                return "Not Defined ["+status+"]";
        }
    }

    return (
      

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="right">#</TableCell>
                        <TableCell align="left">Type</TableCell>
                        <TableCell align="right">Total</TableCell>
                        <TableCell align="center">Action</TableCell>
                    </TableRow>
                </TableHead>    

                <TableBody>
                    {lines && lines.map( (line, index) => 
                    <TableRow key={index}>
                    <TableCell align="right">{index + 1}</TableCell>
                    <TableCell align="left">{line.description1}</TableCell>
                
                    <TableCell align="right">{line.number1}</TableCell>
                    
                    <TableCell align="center">
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                      <Button onClick={() => {loadCurrIncident(line)}}>More</Button>
                 
                    </ButtonGroup>
                  </TableCell>

                  </TableRow>

    )}
                  
                </TableBody>        
            </Table>
    
  
    )
}