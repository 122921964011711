import axios from 'axios';
import { useDispatch } from 'react-redux';
import { alertActions } from '../_actions/alertAction';

const fetchClient = () => {

  //const dispatch = useDispatch();

  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_URL,
    //method: 'get',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';

    return config;
  });

  /**
   * Catch the AunAuthorized Request
   */
  instance.interceptors.response.use((response) => {
    return response; 
  }, (error) => {
    console.log(error.response)
    if (error.response.status === 401) {
      return window.location.href = '/login';
    }

    if (error.response.status === 500) {
      //dispatch(alertActions.error(error.response.data.message));
    }
    return Promise.reject(error)
  });

  return instance;
};

export default fetchClient();