import { Button, ButtonGroup, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { dateFormat } from "../../_helpers/datetime";
import { ReferralRow } from "./row";

export const ReferralList = ({referrals = [], loadCurrReferral}) => {

    function statusDescription (status) {
        switch (status) {
            case "P":
                return <Chip label="Pending" color="warning" size="small" />
            case "D":
                return <Chip label="Closed" color="success" size="small" />
            default:
                return "--";
        }
    }

    return (
        <TableContainer component={Paper}>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="right">#</TableCell>
                        <TableCell align="left">Reference</TableCell>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="center">NHS No</TableCell>
                        <TableCell align="center">Referred Date</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">Action</TableCell>
                    </TableRow>
                </TableHead>    

                <TableBody>
                    {referrals.map( (referral, index) => 
                    <TableRow key={referral.id}>
                    <TableCell align="right">{index + 1}</TableCell>
                    <TableCell align="left">{referral.referenceNo}</TableCell>
                    <TableCell align="left">{referral.firstName} {' '} {referral.lastName}</TableCell>
                    <TableCell align="center">{referral.nhsNo}</TableCell>
                    <TableCell align="center">{dateFormat(referral.referredDate)}</TableCell>
                    <TableCell align="center">{statusDescription(referral.status)}</TableCell>
                    <TableCell align="center">
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                      <Button onClick={() => {loadCurrReferral(referral)}}>{referral.status === 'P' ? "Edit" : "View"}</Button>
                      {/*<Button onClick={() => {}}>Del</Button>*/}
                    </ButtonGroup>
                  </TableCell>

                  </TableRow>

                    )}
                  
                </TableBody>        
            </Table>
    
        </TableContainer>
    )
}