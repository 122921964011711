import { authConstant } from "../_constants/authConstant";
import axios from '../_helpers/axios';
import { Redirect } from "../_helpers/redirect";
import { alertActions } from "./alertAction";
import { locationAction } from "./locationAction";

export const authAction = {
    login,
    logout,
}


function login(username, password, from) {

    return (dispatch) => {

        dispatch(request());

        axios.post('/authenticate', {username, password})
            .then(response => {
                localStorage.setItem("token", response.data.jwttoken);
                dispatch(success(response.data));
                dispatch(locationAction.getLocations());
            })
            .catch(error => {
                console.log(error);
                dispatch(fail(error.toString()));
                dispatch(alertActions.error(error.toString()));
            });
    };

    function request() {return {type: authConstant.REQUEST}}
    function success(user) { return {type: authConstant.LOGIN_SUCCESS, user}}
    function fail(error) { return {type: authConstant.LOGIN_ERROR, error}}
}

function logout() {
    return (dispatch) => {
        localStorage.removeItem("token");
        dispatch({type: authConstant.LOGOUT_SUCCESS});

    }
}