import { fn } from "moment";
import { careBandConstant } from "../_constants/careBandConstant";
import axios from '../_helpers/axios';

export const careBandAction = {
    getAll,
}


function getAll() {

    return (dispatch) => {

        dispatch(request());

        axios.get('/api/v1/careband/all')
            .then(response => {
                dispatch(success(response.data))
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: careBandConstant.REQUEST}}
    function success(bands) { return {type: careBandConstant.GET_ALL_SUCCESS, bands}}
    function error(error) { return {type: careBandConstant.GET_ALL_ERROR, error}}
}