import { Button, Card, CardHeader, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { employeeAction } from "../../_actions/employeeAction";
import { dateFormat } from "../../_helpers/datetime";

import EditIcon from '@mui/icons-material/Edit';
import { employee } from "../../_reducers/employeeReducer";

export const ContractDetail = ({employeeId, leaveDate, onAdd, onEdit}) => {

    const dispatch = useDispatch();

    const contracts = useSelector(state => state.employee.contracts);

    useEffect(() => {
        console.log("useEffect::employeeId :: " + employeeId)
        if (employeeId && employeeId > 0) {
            dispatch(employeeAction.getContracts(employeeId));
        }
    },[employeeId])

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell component={"th"}>Role</TableCell>
                        <TableCell component={"th"}>Hours</TableCell>
                        <TableCell component={"th"}>From Date</TableCell>
                        <TableCell component={"th"}>To Date</TableCell>
                        <TableCell component={"th"}></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {contracts && contracts.map(contract => 
                        <TableRow key={contract.id}>
                            <TableCell component={"td"}>{contract.jobRole.name}</TableCell>
                            <TableCell component={"td"}>{contract.contractedHours}</TableCell>
                            <TableCell component={"td"}>{dateFormat(contract.startDate)}</TableCell>
                            <TableCell component={"td"}>{contract.endDate ? dateFormat(contract.endDate) : ''}</TableCell>
                            <TableCell >
                                {contract.endDate ? '' : 
                                <Button size='small' color='primary' startIcon={<EditIcon />} onClick={(e) => { e.preventDefault(); onEdit(contract)}}>
                                    Edit
                                </Button>
                                }
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            <Button size='small' color='primary' startIcon={<EditIcon />} onClick={(e) => { e.preventDefault(); onAdd()}} disabled={leaveDate}>
                Add New Contract
            </Button>
        </TableContainer>
    )
}