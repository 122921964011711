import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Button from "@mui/material/Button";

import { referralAction } from "../../_actions/referralAction";
import axios from "../../_helpers/axios";
import { Card, CardContent, CardHeader } from "@mui/material";


export const DocumentGeneratePrint = ({referral}) => {
    const dispatch = useDispatch();

    const generate = () => {
        dispatch(referralAction.generateAdmissionForm(referral.id));
    }

    const confirm = () => {
        dispatch(referralAction.printConfirm(referral.id));
    }

    const print = () => {
        axios
        .get(`/api/v1/referral/print/${referral.id}`, {
            responseType: 'blob', // had to add this one here
        })
        .then(response => {
           const content = response.headers['content-type'];
           //download(response.data, file.file_name, content)
           console.log(content);
           window.open(URL.createObjectURL(response.data));
           
        })
        .catch(error => console.log(error));
    }

    return (
        <Card>
            <CardHeader title="Print Admission Form" />

            <CardContent>
                {referral.admissionFormGenerated === 'Y' ? 
                    <>
                        <Button onClick={print}>Print</Button> 
                        <Button onClick={confirm}>Print Success</Button> 
                    </>
                    : <Button onClick={generate}>Generate</Button>}
            </CardContent>
        </Card>
        
    )
}