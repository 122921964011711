import { ColorLensOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { Roles } from '../_constants/routeRolesConstant';

export const PrivateRoute = ({ children, requiredRoles }) => {
  let location = useLocation();

  const { isAuth, roles, loading } = useSelector(state => state.auth);

  if (loading) {
    return <p className="container">Checking auth..</p>;
  }
  
  console.log(requiredRoles);
  console.log(roles);
  console.log(roles.includes(requiredRoles))
  const userHasRequiredRole = roles.includes(requiredRoles[0]) ? true : false;

  if (!isAuth) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (isAuth && !userHasRequiredRole) {
    return <Navigate to="/403" state={{ from: location }} />;
  }

  return children;
};