import { referralConstant } from "../_constants/referralConstant";

const initialState = {
            loading: false,
            referrals: [],
            currReferral: {id: 0, steps: [], funds: []},
            reasons: [],
        };

export function referral(state = initialState, action) {
    switch (action.type) {
        case referralConstant.REQUEST: 
            return {
                ...state,
                loading: true,
            }
        case referralConstant.RESET: 
            return initialState
        case referralConstant.SET_CURR :
            return {
                ...state,
                currReferral: {...action.referral, steps: [], funds: []},
            }
        case referralConstant.GET_REFERRALS_REQUEST:
            return {
                ...state,
                referrals: [],
                listLoading: true,
            }
        case referralConstant.GET_LOCATION_STATUS_SUCCESS:
            return {
                ...state,
                listLoading: false,
                referrals: action.referrals,
            }
        case referralConstant.SUBMIT_SUCCESS:
            return {
                ...state,
                currReferral: {...action.referral, steps: [], funds: []},
                referrals: [...state.referrals, action.referral]
            }
        case referralConstant.SUBMIT_UPDATE_SUCCESS:
            return {
                ...state,
                referrals: state.referrals.map(referral => referral.id === action.referral.id ? action.referral : referral),
                currReferral: {...action.referral, steps: state.currReferral.steps},
            }
        case referralConstant.CLOSE_SUCCESS:
            return {
                ...state,
                referrals: state.referrals.filter(referral => referral.id != action.referral.id),
                currReferral: {...action.referral, steps: state.currReferral.steps},
            }
        case referralConstant.GET_SR_REQUEST:
            return {
                ...state,
                currReferral: {...state.currReferral, tasksLoading: true}
            }
        case referralConstant.GET_SR_SUCCESS: 
            return {
                ...state,
                currReferral: {...state.currReferral, steps: action.detail, tasksLoading: false},
            }
        case referralConstant.GET_FUNDS_SUCCESS:
            return {
                ...state,
                currReferral: {...state.currReferral, funds: action.funds},
            }
        case referralConstant.GET_REASONS_SUCCESS:
            return {
                ...state,
                reasons: action.reasons,
            }
        default:
            return state;
    }
}