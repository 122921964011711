import React from "react";

import { Box, Button, ButtonGroup, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

export const JobRoleTable = ({jobRoles = [], loadCurrJobRole}) => {
    return (
        <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">#</TableCell>
                    <TableCell align="left">Abbr</TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                    {jobRoles.map( (jobRole, index) => 
                    <TableRow>
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="left">{jobRole.abbr}</TableCell>
                    <TableCell align="left">{jobRole.name}</TableCell>
            
                    <TableCell align="center">
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                      {/*<Button onClick={() => {loadCurrOccupy(occupancy)}}>Edit</Button>/}
                      {/*<Button onClick={() => {}}>Del</Button>*/}
                    </ButtonGroup>
                  </TableCell>

                  </TableRow>

                    )}
                  
                </TableBody>
              </Table>
            </TableContainer>
    )
}