import { Button, Card, CardContent, CardHeader, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { referralAction } from "../../_actions/referralAction";

export const AdmitForm = ({referal}) => {

    const dispatch = useDispatch();

    const [idNo, setIdNo] = useState();
    const [errors, setErrors] = useState([]);

    const admit = () => {

        if (validate()) {
            dispatch(referralAction.admint(referal.id, idNo));
        }
    }

    const validate = () => {
        console.log("validate Forms")
        let formErrors = {error: false};

        if (!idNo || idNo.length <= 0) {
            formErrors = {...formErrors, error: true, idNo: 'Required Identity Information'};
        }

        setErrors(formErrors);
        return !formErrors.error;
    }

    return (
        <Card>
            <CardHeader title="Admit Resident" />

            <CardContent>
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="idNo"
                            name="idNo"
                            label="Identity No"
                            value={idNo}
                            onChange={(e) => setIdNo(e.target.value)}
                            error={errors.idNo}
                            helperText={errors.idNo ? errors.idNo : ''}
                        />
                    </Grid>
                
                    <Button onClick={admit}>Admitted</Button>

                </Grid>
            </CardContent>
        </Card>
        
    )
}