import { useEffect, useState } from 'react';
import { Box, Card, CardContent, CardHeader, Container, Grid, MenuItem, Paper, TextField, Typography } from "@mui/material"
import { useDispatch, useSelector } from 'react-redux';
import { entryAction } from '../../_actions/entryAction';
import { EntryList } from '../entry/List';
import { EntryForm } from '../entry/Form';

export const EntryPage = () => {

    const dispatch = useDispatch();

    const [currEntry, setCurrEntry] = useState({id: 0});

    const workingLocation = useSelector(state => state.location.working);
    const entries = useSelector(state => state.entry.entries);

    useEffect(() => {
        dispatch(entryAction.getEntriesByLocation(workingLocation.id));
    },[])

    const handleFormChange = (e) => {

    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const loadEntryForm = (entry) => {
        setCurrEntry(entry);
    }

    const closeForm = () => {
        setCurrEntry({id: 0})
    }

    return (

        <Container style={{height: "85vh"}} >
        
            <Box mt={2} style={{    
                        display: 'flex',
                        flexDirection: 'column',
                       }}
                       >
                <Box
                    component="span"
                    mb={3}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={12}
                >
                <Typography component="h1" variant="h5" mb={4} align="left">
                    Entries
                    </Typography>
                    <Box>
                    
                    </Box>
                </Box>

                { currEntry.id > 0 ? <EntryForm currEntry={currEntry} close={closeForm}/> : 
                <EntryList entries={entries} loadEntry={loadEntryForm} />}

            
            </Box>

        </Container>

    )
}