import { roomConstant } from '../_constants/roomConstant';
import axios from '../_helpers/axios';
import { alertActions } from './alertAction';

export const roomAction = {
    getActiveOccupancies,
    getNonOccupiedRooms,
    createRoomOccupy,
    updateRoomOccupy,
    getRoomsWithCurrBooking,

    updateLeaving,
}


function getActiveOccupancies(locationID) {

    return (dispatch) => {

        dispatch(request());

        axios.get('/api/v1/roombooking/'+locationID+'/active')
            .then(response => {
                dispatch(success(response.data))
            })
            .catch(error => {
                console.log(error);
                dispatch(alertActions.error(error.message));
                dispatch(fail(error));
            });
    };

    function request() {return {type: roomConstant.REQUEST}}
    function success(occupancies) { return {type: roomConstant.GET_ACTIVE_OCCUPANCIES_SUCCESS, occupancies}}
    function fail(error) { return {type: roomConstant.REQUEST_ERROR, error}}
}

function getRoomsWithCurrBooking(locationID) {

    return (dispatch) => {

        dispatch(request());

        axios.get('/api/v1/room/'+locationID+'/withCurrBookking')
            .then(response => {
                dispatch(success(response.data))
            })
            .catch(error => {
                console.log(error);
                dispatch(alertActions.error(error.message));
                dispatch(fail(error));
            });
    };

    function request() {return {type: roomConstant.REQUEST}}
    function success(rooms) { return {type: roomConstant.GET_ROOMS_WITH_OCCUPANCY_INFO_SUCCESS, rooms}}
    function fail(error) { return {type: roomConstant.REQUEST_ERROR, error}}
}

function getNonOccupiedRooms(locationID) {

    return (dispatch) => {

        dispatch(request());

        axios.get('/api/v1/room/'+locationID+'/nonOccupied')
            .then(response => {
                dispatch(success(response.data))
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: roomConstant.REQUEST}}
    function success(rooms) { return {type: roomConstant.GET_NON_OCCUPIED_SUCCESS, rooms}}
    function error(error) { return {type: roomConstant.GET_NON_OCCUPIED_ERROR, error}}
}

function createRoomOccupy(occupy) {
    return dispatch => {
        console.log(occupy);
        
        axios.post('/api/v1/roombooking', occupy) 
            .then(response => {
                dispatch(success(response.data.data));
                dispatch(alertActions.success(response.data.message));
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: roomConstant.REQUEST}}
    function success(occupy) { return { type: roomConstant.CREATE_OCCUPY_SUCCESS,occupy}}
    function error(error) { return {type: roomConstant.CREATE_OCCUPY_ERROR, error}}
}

function updateRoomOccupy(occupy) {
    return dispatch => {
        console.log(occupy);
        
        axios.put('/api/v1/roombooking', occupy) 
            .then(response => {
                dispatch(success(response.data.data));
                dispatch(alertActions.success(response.data.message));
            })
            .catch(error => {
                dispatch(alertActions.error(error.message));
                console.log(error);
            });
    };

    function request() {return {type: roomConstant.REQUEST}}
    function success(occupy) { return { type: roomConstant.UPDATE_OCCUPY_SUCCESS,occupy}}
    function error(error) { return {type: roomConstant.UPDATE_OCCUPY_ERROR, error}}
}

function updateLeaving(occupy) {
    return dispatch => {
        console.log(occupy);
        
        axios.put('/api/v1/roombooking/updateLeaving', occupy) 
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                dispatch(alertActions.error(error.message));
                console.log(error);
            });
    };

    function request() {return {type: roomConstant.REQUEST}}
    function success(occupy) { return { type: roomConstant.UPDATE_OCCUPY_LEAVING_SUCCESS,occupy}}
    function error(error) { return {type: roomConstant.UPDATE_OCCUPY_ERROR, error}}
}

function requestRoomChange(bookingID) {
    return dispatch => {
        
        axios.put(`/api/v1/roombooking/${bookingID}/change`) 
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                dispatch(alertActions.error(error.message));
                console.log(error);
            });
    };

    function request() {return {type: roomConstant.REQUEST}}
    function success(occupy) { return { type: roomConstant.UPDATE_OCCUPY_LEAVING_SUCCESS,occupy}}
    function error(error) { return {type: roomConstant.UPDATE_OCCUPY_ERROR, error}}
}