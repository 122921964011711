import React, { useEffect, useState } from "react";
import { Box, IconButton, Paper, Typography } from '@mui/material';

import { EmployeeTable } from "../components/employee/EmployeeTable";
import { EmployeeForm } from "../components/employee/EmployeeForm";
import { useDispatch, useSelector } from "react-redux";
import { employeeAction } from "../_actions/employeeAction";
import { EmployeeDetail } from "../components/employee/EmployeeDetail";
import { alertActions } from "../_actions/alertAction";

import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";

const initialState = {
    id: 0,
    firstName: '',
    lastName: '',
    middleName: '',
    otherName: '',
    dob: '',
    jobRoleID: '',
    joinDate: ''
}

export const Employee = () => {

    const workingLocation = useSelector(state => state.location.working);
    const employees = useSelector(state => state.employee.employees);
    const jobRoles = useSelector(state => state.employee.jobRoles);
    const [txnType, setTxnType] = useState("VIEW");

    const dispatch = useDispatch();

    const [isEditing, setIsEditing] = useState(false);
    const [currEmployee, setCurrEmployee] = useState({...initialState, locationID: workingLocation.id});

    useEffect(() => {
        dispatch(employeeAction.getJobRoles());
    },[]);

    useEffect(() => {
        dispatch(alertActions.clear());
    },[txnType])

    useEffect(() => {
        if (txnType !== "VIEW") {
            setCurrEmployee(employees.find(employee => employee.id == currEmployee.id))
        }
    },[employees]);

    useEffect(() => {
        dispatch(employeeAction.getLocationEmployees(workingLocation.id));
    }, [workingLocation]);

    const create = (employee) => {
        console.log('Create Clicked');
        console.log(employee);
        dispatch(employeeAction.create(employee));
        setIsEditing(false);
    }

    const update = (employee) => {
        console.log('Update Clicked');
        console.log(employee);
        dispatch(employeeAction.update(employee));
        setIsEditing(false);
    }

    const loadCurrEmployee = (employee) => {
        setCurrEmployee(employee);
        setIsEditing(true);
        setTxnType("EDIT");
    }

    const newEmployee = (employee) => {
        setCurrEmployee(employee);
        setIsEditing(true);
        setTxnType("NEW");
    }

    const closeForm = (e) => {
        e.preventDefault();
        setIsEditing(false);
        setTxnType("VIEW");
    }

    return (
        <Paper style={{padding: 12, color: 'red'}}>
        <Box display="flex">
            <Box flexGrow={1}>
              <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Employees
              </Typography>
            </Box>

            <Box>
                {!isEditing &&

                    <IconButton aria-label="Add" size="large"  onClick={(e) => {e.preventDefault(); newEmployee({...initialState, locationID: workingLocation.id});} } color='primary'>
                        <AddCircleOutlineSharpIcon fontSize="inherit" />
                    </IconButton>
                    
                }
            </Box>
        </Box>



        {isEditing ? 
            <>
            {txnType === "EDIT" && <EmployeeDetail employee={currEmployee} close={closeForm}/> }
            {txnType === "NEW" && <EmployeeForm jobRoles={jobRoles} closeForm={setIsEditing} currEmployee={currEmployee} create={create} update={update} close={closeForm} />}
            </>
                : <EmployeeTable employees={employees} loadCurrEmployee={loadCurrEmployee}/> }
        
        </Paper>
    );
}