import { Card, CardContent, CardHeader, Grid, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';

import { SYS_DATE_FORMAT } from "../../_helpers/datetime";
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { referralAction } from '../../_actions/referralAction';
import { useDispatch } from 'react-redux';


export const AdmissionConfirmation = ({referral, referredDate}) => {

    const dispatch = useDispatch();

    const [admissionDate, setAdmissionDate] = useState('');
    const [amount, setAmount] = useState(0);
    const [remark, setRemark] = useState();
    const [errors, setErrors] = useState([]);

    const [data, setData] = useState({});

    useEffect(() =>{
        setAdmissionDate(moment(referral.admissionDate));
        setAmount(referral.agreedAmount)
        setData({...data, invoiceTo: referral.invoiceTo, invoiceAddress: referral.invoiceAddress, invoiceContact: referral.invoiceContact})
    }, [referral]);

    const onChange = (e) => {
        const {name, value} = e.target;

        setData({...data, [name]: value});
    }

    const submitForm = (e) => {
        e.preventDefault();

        if (validate()) {
            dispatch(referralAction.updateConfirm(referral.id, admissionDate.format("YYYY-MM-DD"), amount, {...data, remark}));
        }
        
    }

    const validate = () => {
        console.log("validate Forms")
        let formErrors = {error: false};

        //if (moment(referralRegisteredDate).isAfter(admissionDate)) {
            //formErrors = {...formErrors, error: true, assessmentDate: 'Assessment Date cannot be less than referral registered date'};

        if (!admissionDate.isValid()) {
            formErrors = {...formErrors, error: true, admissionDate: 'Required Admission Date'};
        } else if (moment(admissionDate).format("YYYYMMDD") < moment(referredDate).format("YYYYMMDD")) {
            formErrors = {...formErrors, error: true, admissionDate: 'Admission Date cannot be less than referral registered date'};
        }

        if (!amount || amount <= 0) {
            formErrors = {...formErrors, error: true, amount: 'Invalid Amount'};
        }

        if (!data.invoiceTo || data.invoiceTo.length < 1) {
            formErrors = {...formErrors, error: true, invoiceTo: 'Invalid Invoice To'};
        }

        if (!data.invoiceAddress || data.invoiceAddress.length < 1) {
            formErrors = {...formErrors, error: true, invoiceAddress: 'Invalid Invoice Address'};
        }

        if (!data.invoiceContact || data.invoiceContact.length < 1) {
            formErrors = {...formErrors, error: true, invoiceContact: 'Invalid Invoice Contact'};
        }

        setErrors(formErrors);
        return !formErrors.error;
    }

    return (
        <Card>
            <CardHeader title="Admission Confirmation" />

            <CardContent>

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={DateAdapter}>
                        <DatePicker
                            mask="__-__-____"
                            label="Admission Date"
                            type="date"
                            value={admissionDate}
                            inputFormat={SYS_DATE_FORMAT}
                            minDate={moment(referredDate)}
                            //maxDate={moment()}
                            onChange={setAdmissionDate}
                            renderInput={(params) => <TextField {...params} error={errors.admissionDate? true : false}
                            helperText={errors.admissionDate ? errors.admissionDate : ''} />}
                        />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            type="number"
                            id="amount"
                            name="amount"
                            label="Amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            error={errors.amount}
                            helperText={errors.amount ? errors.amount : ''}
                        />
                    </Grid> 

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            
                            fullWidth
                            id="invoiceTo"
                            name="invoiceTo"
                            label="Invoice To"
                            value={data.invoiceTo}
                            onChange={onChange}
                            error={errors.invoiceTo}
                            helperText={errors.invoiceTo ? errors.invoiceTo : ''}
                        />
                    </Grid>


                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            
                            fullWidth
                            id="invoiceAddress"
                            name="invoiceAddress"
                            label="Invoice Address"
                            value={data.invoiceAddress}
                            onChange={onChange}
                            error={errors.invoiceAddress}
                            helperText={errors.invoiceAddress ? errors.invoiceAddress : ''}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            
                            fullWidth
                            id="invoiceContact"
                            name="invoiceContact"
                            label="Telephone No"
                            value={data.invoiceContact}
                            onChange={onChange}
                            error={errors.invoiceContact}
                            helperText={errors.invoiceContact ? errors.invoiceContact : ''}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            
                            fullWidth
                            id="remark"
                            name="remark"
                            label="Remark"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                            error={errors.remark}
                            helperText={errors.remark ? errors.remark : ''}
                        />
                    </Grid>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={9}>
                        
                    </Grid>

                    <Grid item xs={3} >
                        <LoadingButton
                            loading={false}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                            onClick={submitForm}
                        >
                            Ok
                        </LoadingButton>

                    </Grid>


                </Grid>
            
            </CardContent>
        </Card>
    );
}