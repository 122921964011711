import { employeeConstant } from "../_constants/employeeConstant";
import axios from '../_helpers/axios';
import { alertActions } from "./alertAction";

import { appActions }  from './appAction';

export const employeeAction = {
    getLocationEmployees,
    getJobRoles,
    create,
    update,

    getContracts,
    addContract,
    updateContract,
}


function getLocationEmployees(locationID) {

    return (dispatch) => {

        dispatch(request());
        dispatch(appActions.startLoading());

        axios.get('/api/v1/employee/'+locationID+'/all')
            .then(response => {
                dispatch(success(response.data))
            })
            .catch(error => {
                console.log(error);
            });

        dispatch(appActions.stopLoading());
    };

    function request() {return {type: employeeConstant.REQUEST}}
    function success(employees) { return {type: employeeConstant.GET_EMPLOYEES_SUCCESS, employees}}
    function error(error) { return {type: employeeConstant.GET_EMPLOYEES_ERROR, error}}
}

function getJobRoles() {

    return (dispatch) => {

        dispatch(request());

        axios.get('/api/v1/jobrole/all')
            .then(response => {
                dispatch(success(response.data))
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: employeeConstant.REQUEST}}
    function success(jobRoles) { return {type: employeeConstant.GET_JOBROLES_SUCCESS, jobRoles}}
    function error(error) { return {type: employeeConstant.GET_JOBROLES_ERROR, error}}
}

function create(employee) {
    return dispatch => {
        console.log(employee);
        
        axios.post('/api/v1/employee', employee) 
            .then(response => {
                dispatch(success(response.data.data));
                dispatch(alertActions.success(response.data.message));
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: employeeConstant.REQUEST}}
    function success(employee) { return { type: employeeConstant.CREATE_SUCCESS,employee}}
    function error(error) { return {type: employeeConstant.CREATE_ERROR, error}}
}

function update(employee) {
    return dispatch => {
        console.log(employee);
        
        axios.put('/api/v1/employee', employee) 
            .then(response => {
                dispatch(success(response.data.data));
                dispatch(alertActions.success(response.data.message));
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: employeeConstant.REQUEST}}
    function success(employee) { return { type: employeeConstant.UPDATE_SUCCESS,employee}}
    function error(error) { return {type: employeeConstant.UPDATE_ERROR, error}}
}
/*
function saveOrder(order) {
    return (dispatch) => {
        axios.post('/orders/create', order) 
            .then(response => {
                dispatch(success(response.data.data));
            })
            .catch(error => {
                console.log(error);
            });
    }

    function success(order) { return {type: orderConstant.SAVE_SUCCESS, order}}
    function error(error) { return {type: orderConstant.SAVE_ERROR, error}}
}*/

// -------------------- [ Contract Actions ] -----------------------------------------------------------

function getContracts(employeeId) {

    return (dispatch) => {

        dispatch(request());

        axios.get(`/api/v1/employee/contract/${employeeId}`)
            .then(response => {
                dispatch(success(response.data))
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: employeeConstant.REQUEST}}
    function success(contracts) { return {type: employeeConstant.GET_CONTRACTS_SUCCESS, contracts}}
    function error(error) { return {type: employeeConstant.GET_CONTRACTS_ERROR, error}}
}

function addContract(contract) {
    return dispatch => {
        console.log(contract);
        
        axios.post('/api/v1/employee/contract', contract) 
            .then(response => {
                dispatch(success(response.data.data));
                dispatch(alertActions.success(response.data.message));
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: employeeConstant.REQUEST}}
    function success(contract) { return { type: employeeConstant.ADD_CONTRACT_SUCCESS,contract}}
    function error(error) { return {type: employeeConstant.CREATE_ERROR, error}}
}

function updateContract(contract) {
    return dispatch => {
        console.log(contract);
        
        axios.put('/api/v1/employee/contract', contract) 
            .then(response => {
                dispatch(success(response.data.data));
                dispatch(alertActions.success(response.data.message));
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: employeeConstant.REQUEST}}
    function success(contract) { return { type: employeeConstant.UPDATE_CONTRACT_SUCCESS,contract}}
    function error(error) { return {type: employeeConstant.UPDATE_ERROR, error}}
}