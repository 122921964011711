import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, MenuItem, Paper, TextField } from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { employeeAction } from '../../_actions/employeeAction';
import { RotaResoruce } from './RotaResource';
import { useSelector } from 'react-redux';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "70%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1,
  pb: 4
};

export const RotaForm = ({employees, shift, submitShift, handleClose}) => {

  const loading = useSelector(state => state.rota.loading);

  const [errors, setErrors] = useState({}); 
  const [btnSaveLabel, setBtnSaveLabel] = useState('Create');
  //const [schedule, setSchedule] = useState({});

  const [requiredRoles, setRequiredRoles] = useState([]);
    /*[
                    {id:"1", role: {id: 1, name: "Lead Care Assistant"}, employeeID: ''},
                    {id:"2", role: {id: 4, name: " Care Assistant"}, employeeID: ''},
                    {id:"3", role: {id: 4, name: " Care Assistant"}, employeeID: ''},
                    {id:"4", role: {id: 4, name: " Care Assistant"}, employeeID: ''},
                    {id:"5", role: {id: 4, name: " Care Assistant"}, employeeID: ''},
                  ]*/

  useEffect(() => {
    console.log(shift);
    setRequiredRoles(shift.lines);
  },[shift])

  const handleFormChange = (id, name, value) => {
    console.log("handleFormChange");
    console.log(id + " :: " + name + " :: " + value );
    
    let newRequiredRoles = requiredRoles;

    if (name === 'employeeID') {
      let employee = employees.find(employee => employee.id === value);
      newRequiredRoles = requiredRoles.map( role => role.id === id ? {...role, employee, [name]: value} : role);
    } else {
      newRequiredRoles = requiredRoles.map( role => role.id === id ? {...role, [name]: value} : role);
    }
    setRequiredRoles(newRequiredRoles);
    
    console.log(newRequiredRoles);

  }

  const submitForm = (e) => {
    e.preventDefault();
    submitShift({...shift, lines: requiredRoles});
  }
  return (
    <div>

      <Modal
        open
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown={true}
      >
        <Box sx={style}>
        <Paper style={{padding: 12, marginBottom: 32}}>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ typography: 'h4' }}>Rota</Box>
            </Grid>

            <Grid item xs={6}>
              <Box sx={{ typography: 'subtitle1', textAlign: 'right' }}>{shift.rotaDate}</Box>
            </Grid>
          </Grid>
          
            
            <Box sx={{ typography: 'subtitle2', textAlign: 'right'  }}>{shift.fromTime} {shift.toTime}</Box>
       
          </Paper>
          <Grid container spacing={2}>
            {requiredRoles.map((role, index) => 
              <RotaResoruce resource={role} employees={employees} resourceChange={handleFormChange}/>
            )}

              <Grid item xs={12}></Grid>
                
              <Grid item xs={6}></Grid>

              <Grid item xs={3} >
                        <LoadingButton
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            type="submit"
                            onClick={submitForm}
                        >
                            {btnSaveLabel}
                        </LoadingButton>

                    </Grid>

                    <Grid item xs={3} >
                        <LoadingButton
                            
                            loadingPosition="start"
                            startIcon={<CloseIcon />}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={handleClose}
                        >
                            Close
                        </LoadingButton>

                    </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}