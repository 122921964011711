import { useState, useEffect } from "react";

import { Box, List, ListItem, ListItemText } from "@mui/material"
import { dateFormat } from "../../_helpers/datetime"
import { CheckListForm } from "./CheckListForm";

export const IncidentCheckList = ({incidentDate, list, openEdit}) => {

    const [wList, setWList] = useState(list);

    useEffect(() => {
        setWList(list);
    },[list])

    return (
        <List component="nav" aria-label="secondary mailbox folders">
            {list && list.map(item => 
            <>
                {item.dataType === 'D' && (
                item.status === 'E' ? 
            

                <ListItem>
                    <CheckListForm incidentDate={incidentDate} item={item}/>
                </ListItem>

                : 

                <CheckListRow item={item} openEdit={openEdit} />
              )}
        </>
            )}
          
        </List>
    )
}

export const CheckListRow = ({item, openEdit}) => {
    return (
        <ListItem button sx={{ borderLeft: 5, marginBottom: 1, borderColor: (item.status == 'Y') ? "success.main" : "warning.main" }} onClick={() => openEdit(item)}>
            <ListItemText primary={`${item.itemDescription}`} secondary={""} />
            
            <Box alignContent={"end"} >{item.txnDate ? dateFormat(item.txnDate) : ''}</Box>
        </ListItem>
    )
}