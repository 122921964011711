import React, { useState } from 'react';
import { AppBar, Box, IconButton, Menu, MenuItem, Select, Toolbar, Typography } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { locationAction } from '../_actions/locationAction';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

export const Header = () => {

    const dispatch = useDispatch();
    
    const locations = useSelector(state => state.location.list);
    const currLocation = useSelector(state => state.location.working);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleLocationChange = (e) => {
        dispatch(locationAction.setworkingLocation(locations.find(location => location.id === e.target.value)));
    }
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position='static' sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}>
            <Toolbar>
                <IconButton
                    size='large'
                    edge='start'
                    color='inherit'
                    aria-label='menu'
                    sx={{mr: 2}}>
                    <MenuIcon />
                </IconButton>

                <Select
                    id="demo-simple-select"
                    value={currLocation.id}
                    label="Working Location"
                    onChange={handleLocationChange}
                    sx={{color: "white", fontSize: 20}}
                > 
                    {locations.map((location) => <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>)}
                </Select>

                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    
                </Typography>

                <div>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                        {/*
                        <MenuItem onClick={handleClose}>My account</MenuItem> */}
                        <MenuItem component={Link} to={"/logout"} onClick={handleClose}>Logout</MenuItem>
                    </Menu>
                </div>

            </Toolbar>
        </AppBar>
    );
}