import { useEffect, useState } from 'react';

import { Card, CardContent, CardHeader, CardActions, Box, Grid, Typography } from "@mui/material";
import { dateFormat } from "../../_helpers/datetime";
import { InfoBlock } from "../ui/InfoBlock";
import { IncidentCheckList } from "./CheckList";
import { CheckListForm } from "./CheckListForm";
import { useDispatch, useSelector } from 'react-redux';
import { incidentAction } from '../../_actions/incidentAction';
import { FallsAdditionalInfoForm } from './FallsAdditionalInfoForm';
import { FallsAdditionalInfo } from './FallsAdditionalInfo';

export const IncidentDetail = ({incident}) => {

    const dispatch = useDispatch();

    const checklistItems = useSelector(state => state.incident.incident.checklist);
    const isAdditionalInfoEditing = useSelector(state => state.incident.aEditing);

    useEffect(() => {
        if (!incident.checklist || incident.checklist.length <= 0) {
            dispatch(incidentAction.getChecklistItems(incident.id));
        }
    }, [incident]);

    const openEdit = (item) => {
        dispatch(incidentAction.setChecklistItemEdit(item))
    }

    function statusDescription(status) {
        switch (status) {
            case 'P':
                return "Pending";
            case 'C':
                return "Closed";
            default:
                return "Not Defined ["+status+"]";
        }
    }

    const editAdditionalInfo = () => {
        dispatch(incidentAction.setAdditionalInfoEdit(true));
    }

    const closeForm = () => {
        dispatch(incidentAction.setAdditionalInfoEdit(false));
    }

    return (
        <Grid container spacing={2} >

            <Grid item xs={8}>

                <Grid container spacing={2}>

                    <Grid item xs={6}>
                        <InfoBlock label={"Incident Date "} value={dateFormat(incident.incidentDateTime)}/>
                        <InfoBlock label={"Status "} value={statusDescription(incident.status)}/>
                    </Grid>

                    <Grid item xs={6}>
                        <InfoBlock label={"Type "} value={incident.typeDescription}/>
                        <InfoBlock label={"Reason "} value={incident.reasonDescription}/>
                    </Grid>   

                    <Grid item xs={12}>
                        {incident.typeID == 5 && (isAdditionalInfoEditing ? 
                        <FallsAdditionalInfoForm incidentID={incident.id} locationID={incident.locationID} checkListItems={checklistItems} close={closeForm} />
                        :
                        <FallsAdditionalInfo checkListItems={checklistItems} edit={editAdditionalInfo} enableEdit={incident.status === 'P' ? true : false} />
                        )}
                    </Grid>             
           
                    <Grid item xs={12}>

                        <Card>
                            <CardContent>
                                <Typography variant="h6">Registration Remark </Typography>
                                <Typography variant="body2">{incident.registrationRemark ? incident.registrationRemark : "Not Entered"} </Typography>
                            </CardContent>
                        </Card>

                    </Grid>

                    { incident.closeRemark && 
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6">Close Remark </Typography>
                                    <Typography variant="body2">{incident.closeRemark ? incident.closeRemark : "Not Entered"} </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                </Grid>
            </Grid>

            <Grid item xs={4}>
                <IncidentCheckList incidentDate={incident.incidentDateTime} list={checklistItems} openEdit={openEdit}/> 
            </Grid>
        </Grid>
    )
}