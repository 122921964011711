import React, { useEffect, useState } from "react";

import { Button, Container, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { SYS_DATE_FORMAT } from "../../_helpers/datetime";

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { LoadingButton } from "@mui/lab";

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { employeeAction } from "../../_actions/employeeAction";
import { alertActions } from "../../_actions/alertAction";

export const EmploymentForm = ({currEmployee, close}) => {

    const [employee, setEmployee] = useState(currEmployee);
    const [errors, setErrors] = useState({error: false});

    const loading = useSelector(state => state.employee.loading);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log(currEmployee)
        setEmployee(currEmployee);
    },[currEmployee]);

    const handleFormChange = (e) => {
        const {name, value} = e.target;

        setEmployee({...employee, [name]: value});
    }

    const handleDatePicker = (name, date) => {
        setEmployee({...employee, [name]: date.format("YYYY-MM-DD")});
        
    }

    const handleSubmit = (e) => {
        console.log("handleSubmit......")
        e.preventDefault();
        
        dispatch(employeeAction.update({...employee, locationID: employee.location.id, jobRoleID: employee.jobRole.id}));


    }

    const handleClose = (e) => {
        dispatch(alertActions.clear());
        close();
    }

    const validate = () => {
        console.log("validate Forms")
        let formErrors = {error: false};
    
       // if ( contract.contractedHours > 10) {
        //    formErrors = {...formErrors, error: true, contractedHours: 'Invalid Contracted Hours'};
        //}

        //if (!employee.startDate || moment(employee.startDate).format("YYYYMMDD") >= moment().format("YYYYMMDD")) {
        //    formErrors = {...formErrors, error: true, dob: 'Invalid Date of Birth'};
        //}

        if (!moment(employee.leftDate)) {
            formErrors = {...formErrors, error: true, endDate: 'Invalid Contract end date'};
        }

        setErrors(formErrors);
        return !formErrors.error;
    }

    return (
        <>

            <Grid item xs={12} sm={3}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                    label="Join Date"
                    type="date"
                    value={employee.joinDate}
                    inputFormat={SYS_DATE_FORMAT}
                    onChange={(newValue) => {
                        handleDatePicker('joinDate', newValue);
                    }}
                    disabled={employee.id > 0}
                    renderInput={(params) => <TextField {...params} error={errors.joinDate}
                    helperText={errors.joinDate ? errors.joinDate : ''} />}
                />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={3} >
                <LocalizationProvider dateAdapter={DateAdapter} >
                <DatePicker
                    label="Left Date"
                    type="date"
                    value={employee.leftDate}
                    inputFormat={SYS_DATE_FORMAT}
                    onChange={(newValue) => {
                        handleDatePicker('leftDate', newValue);
                    }}
                    disabled={employee.id <= 0}
                    mask={SYS_DATE_FORMAT}
                    renderInput={(params) => <TextField {...params} error={errors.leftDate}
                    helperText={errors.leftDate ? errors.leftDate : ''} />}
                />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12}></Grid>
            <Grid item xs={10}></Grid>

            <Grid item xs={1} >
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                    color="primary"
                    fullWidth
              
                    onClick={handleSubmit}
                >
                    Save
                </LoadingButton>

            </Grid>

            <Grid item xs={1} >
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<CloseIcon />}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={handleClose}
                >
                    Close
                </LoadingButton>

            </Grid>
   
        </>
    )
}