import { Box, Button, Stack, Tab, Tabs, Typography, Card, CardContent, CardHeader, Grid } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roomAction } from '../../_actions/roomAction';
import { InfoBlock } from '../ui/InfoBlock';
import { AdmissionForm } from './AdmissionForm';
import { FundForm } from './FundForm';
import { Funds } from './Funds';
import { Resident } from './Resident';
import { ResidentForm } from './ResidentForm';
import { Room } from './Room';

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { IncidentList } from '../incident/List';
import { Incident } from '../incident/Index';
import { Tasks } from './Tasks';

const initialFund = [
    { id : 1, name: "Fund Name 1"},
    { id: 2, name: "Fund Name 2"},
    { id:3, name: "Fund Fund Funndddddd d 3"}
];

const initialResident = {
    id: 0,
    firstName: '',
    lastName: '',
    idNo: '',
    fromDate: moment().format("YYYY-MM-DD")
};

export const Detail = ({room, edit = '', close}) => {

    const dispatch = useDispatch();

    const fundTypes = useSelector(state => state.fund.types);
    const [editComponent, setEditComoponent] = useState({
                                                            resident: {editing: false, validated: true},
                                                            fund: {editing: false, validated: true},
                                                            admission: {editing: false, validated: true, type: ''}
                                                        })

    const [currResident, setCurrResident] = useState(initialResident);
    const [currFunds, setCurrFunds] = useState([]);
    const [currRoom, setCurrRoom] = useState();
    const [currBooking, setCurrBooking] = useState({})

    useEffect(() => {
        setCurrResident(room.currBooking.customer);
        room.currBooking.funds && setCurrFunds(room.currBooking.funds);
        setCurrRoom(room);
        setCurrBooking({...room.currBooking, isFundModified: false});
        if (edit !== '') {
            setEditComoponent({...editComponent, [edit]: {editing: true}});
        }
    }, [room]);

    const editResident = () => {
        setCurrResident(currResident);
    }

    const onEdit = (component) => {
        if (component === 'admission') {
            setEditComoponent({...editComponent, admission: {editing: true, type: 'discharge'} });
        }

        if (component === 'fund') {
            setEditComoponent({...editComponent, fund: {editing: true} });
        }
    }

    const onCancel = (component) => {
        if (component === 'admission') {
            setEditComoponent({...editComponent, admission: {editing: false, type: ''} });
        }

        if (component === 'fund') {
            setEditComoponent({...editComponent, fund: {editing: false} });
        }
    }

    const onAdmissionUpdate = (dateType, txnDate) => {
        //dispatch(roomAction.updateLeaving({...currResident.currBooking, room: currRoom, toDate: txnDate.format("YYYY-MM-DD")}));
        setCurrBooking({...currBooking, toDate: txnDate.format("YYYY-MM-DD")})
        onCancel('admission');

        dispatch(roomAction.updateRoomOccupy({...currBooking, toDate: txnDate.format("YYYY-MM-DD")}));
    }

    const fundSubmit = (effectDate, funds, total, isModified) => {
        setCurrFunds(funds);
        setCurrBooking({...currBooking, amount: total, fundEffectDate: effectDate, isFundModified: isModified});
        setEditComoponent({...editComponent, fund: {editing: false}});

        dispatch(roomAction.updateRoomOccupy({...currBooking, amount: total, fundEffectDate: effectDate,  isFundModified: isModified, funds: funds}));
    }

    const saveChange = (e) => {
        e.preventDefault();

        console.log(currBooking);
        console.log(currFunds);

        //if (currBooking.id > 0) {
        //    dispatch(roomAction.updateRoomOccupy({...currBooking, funds: currFunds}));
        //} else {
            dispatch(roomAction.createRoomOccupy({...currBooking, funds: currFunds}));
        //}
        
    }

    const [tabIndex, setTabIndex] = React.useState(0);
  
    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };


    const changeRoom = () => {
        // Register the request 
        
    }


    return (
         <>
        <Grid container spacing={2}>
            
            <Grid item xs={8}>

            </Grid>
            
            {/*
            <Grid item xs={2} >
                <LoadingButton
                    loading={false}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    type="submit"
                    onClick={saveChange}
                >
                    Save
                </LoadingButton>

            </Grid>

            <Grid item xs={2} >
                <LoadingButton
                    loading={false}
                    loadingPosition="start"
                    startIcon={<CloseIcon />}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={close}
                >
                    Close
                </LoadingButton>

            </Grid>
            */}
            </Grid>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabIndex} onChange={handleChange} aria-label="Room Occupancy Tab">
                    <Tab label="Resident" disabled={room.isOccupied == 'Y' ? false : true}/>
                    <Tab label="Room"  />
                    <Tab label="Incident"  disabled={room.isOccupied == 'Y' ? false : true}/>
                </Tabs>
            </Box>
       

            

            <Box sx={{ paddingTop: 2 }}>
        
                {tabIndex === 0 && (
                <Box>
                    {/*editComponent.resident.editing ? <ResidentForm currResident={currResident} />
                                                        : <Resident resident={room.currBooking.customer} edit={editResident} /> */}
                    
                    {room.isOccupied == 'Y' && <Resident resident={room.currBooking.customer} edit={editResident} /> }
                        
                </Box>
                )}

                {tabIndex === 1 && (
                
<>
<Box display="flex">
                <Box flexGrow={1}>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                    
                </Typography>
                </Box>

                <Box>
                <Stack direction={"row"} spacing={1}>

                    { room.isOccupied == 'Y' &&  (   <>
                    <Button variant="outlined" color="warning" onClick={changeRoom}>
                        Change Room 
                    </Button>
                    <Button variant="outlined" color="warning" onClick={() => onEdit('admission')}>
                        Discharge 
                    </Button></>)}
                           
                    </Stack>
                </Box>
            </Box>
            <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Room roomNo={room.roomNo} roomType={room.roomType.name} roomRate={room.rate} admissionDate={room.currBooking.fromDate} 
                            leavingDate={room.currBooking.toDate}
                            onEdit={onEdit}
                            enableEdit={room.isOccupied == 'Y' ? true : false}
                        />

                        <br />
                        {editComponent.admission.editing    ? <AdmissionForm 
                                                                dateType={editComponent.admission.type}
                                                                onSave={onAdmissionUpdate}
                                                                onCancel={onCancel}/> 
                                                            : '' }
                    </Grid>
            
                    <Grid item xs={6}>
                    { room.isOccupied == 'Y' &&  (   <>
                        {editComponent.fund.editing    ? <FundForm fundTypes={fundTypes} currFunds={currFunds} onSubmit={fundSubmit} onCancel={onCancel}/>
                                                        : <Funds funds={currFunds} onEdit={onEdit} enableEdit={room.isOccupied == 'Y' ? true : false}/>}
                                                        </> )}
                    </Grid>
                </Grid>
                </>
                )}

                {tabIndex === 2 && (
                    <Box >

                        {room.isOccupied == 'Y' && <Incident customerID={currResident.id} roomBookingID={room.currBooking.id}/>}
                    </Box>
                )}
            </Box>
  
        </>

        
    )
}