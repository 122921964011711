export const incidentConstant = {
    REQUEST : 'INCIDENT_REQUEST',

    REGISTER_SUBMIT_SUCCESS : 'INCIDENT_REGISTER_SUBMIT_SUCCESS',
    REGISTER_SUBMIT_ERROR: 'INCIDENT_REGISTER_SUBMIT_ERROR',

    GET_INCIDENTS_REQUEST: 'INCIDENT_GET_INCIDENTS_REQUEST',
    GET_INCIDENTS_SUCCESS: 'INCIDENT_GET_INCIDENTS_SUCCESS',
    GET_INCIDENTS_ERROR: 'INCIDENT_GET_INCIDENTS_ERROR',

    GET_TYPES_SUCCESS: 'INCIDENT_GET_TYPES_SUCCESS',
    GET_TYPES_ERROR: 'INCIDENT_GET_TYPES_ERROR',

    GET_TYPE_REASONS_SUCCESS: 'INCIDENT_GET_TYPE_REASONS_SUCCESS',
    GET_TYPE_REASONS_ERROR: 'INCIDENT_GET_TYPE_REASONS_ERROR',

    UPDATE_CHECKLIST_ITEM_SUBMIT_SUCCESS: 'INCIDENT_UPDATE_CHECKLIST_ITEM_SUBMIT_SUCCESS',
    UPDATE_CHECKLIST_ITEM_SUBMIT_ERROR: 'INCIDENT_UPDATE_CHECKLIST_ITEM_SUBMIT_ERROR',

    UPDATE_CHECKLIST_ITEMS_SUBMIT_SUCCESS: 'INCIDENT_UPDATE_CHECKLIST_ITEMS_SUBMIT_SUCCESS',
    UPDATE_CHECKLIST_ITEMS_SUBMIT_ERROR: 'INCIDENT_UPDATE_CHECKLIST_ITEMS_SUBMIT_ERROR',

    GET_INCIDENT_CHECKLIST_ITEMS_SUCCESS: 'INCIDENT_GET_INCIDENT_CHECKLIST_ITEMS_SUCCESS',
    GET_INCIDENT_CHECKLIST_ITEMS_ERROR: 'INCIDENT_GET_INCIDENT_CHECKLIST_ITEMS_ERROR',

    CLOSE_SUCCESS: 'INCIDENT_CLOSE_SUCCESS',
    CLOSE_ERROR:  'INCIDENT_CLOSE_ERROR',

    SET_INCIDENT_SCCESS: 'INCIDENT_SET_SCCESS',

    SET_INCIDENT_ITEM_EDIT: 'INCIDENT_SET_ITEM_EDIT',
    SET_INCIDENT_ITEM_EDIT_CANCEL: 'INCIDENT_SET_ITEM_EDIT_CANCEL',

    GET_FALL_AREAS_SUCCESS: 'INCIDENT_GET_FALL_AREAS_SUCCESS',
    GET_FALL_AREAS_ERROR: 'INCIDENT_GET_FALL_AREAS_ERROR',

    GET_FALL_PREVENTIONS_SUCCESS: 'INCIDENT_GET_FALL_PREVENTIONS_SUCCESS',
    GET_FALL_PREVENTIONS_ERROR: 'INCIDENT_GET_FALL_PREVENTIONS_ERROR',

    GET_INJURY_TYPES_SUCCESS: 'INCIDENT_GET_INJURY_TYPES_SUCCESS',
    GET_INJURY_TYPES_ERROR: 'INCIDENT_GET_INJURY_TYPES_ERROR',
    
    SET_HEADER_EDITING: 'INCIDENT_SET_HEADER_EDITING',
    SET_ADD_INFO_EDITING: 'INCIDENT_SET_ADD_INFO_EDITING',

    GET_GROUP_TYPE_SUCCESS: 'INCIDENT_GET_GROUP_TYPE_SUCCESS',

}