import { dashboardConstant } from "../_constants/dashboardConstant";
import axios from '../_helpers/axios';
import { alertActions } from "./alertAction";

import { appActions }  from './appAction';

export const dashboardAction = {
    getMonth,
    getWeek, 

    getReferralPending,
    getDSDetailSummary,
}


function getMonth(location) {

    return (dispatch) => {

        dispatch(request());
        dispatch(appActions.startLoading());

        axios.get('/api/v1/dashboard/month/'+location.id)
            .then(response => {
                dispatch(success(response.data.data))
            })
            .catch(error => {
                console.log(error);
            });

        dispatch(appActions.stopLoading());
    };

    function request() {return {type: dashboardConstant.REQUEST}}
    function success(dashboard) { return {type: dashboardConstant.GET_MONTH_SUCCESS, dashboard}}
    function error(error) { return {type: dashboardConstant.GET_MONTH_ERROR, error}}
}

function getWeek(location, periodID) {

    return (dispatch) => {

        dispatch(request());
        dispatch(appActions.startLoading());

        axios.get(`/api/v1/dashboard/week/${location.id}/${periodID}`)
            .then(response => {
                dispatch(success(response.data.data))
            })
            .catch(error => {
                console.log(error);
            });

        dispatch(appActions.stopLoading());
    };

    function request() {return {type: dashboardConstant.REQUEST}}
    function success(dashboard) { return {type: dashboardConstant.GET_WEEK_SUCCESS, dashboard}}
    function error(error) { return {type: dashboardConstant.GET_MONTH_ERROR, error}}
}

function getReferralPending(location) {

    return (dispatch) => {

        dispatch(request());
        dispatch(appActions.startLoading());

        axios.get(`/api/v1/dashboard/referral/${location.id}/P`)
            .then(response => {
                console.log(response.data)
                dispatch(success(response.data.data))
            })
            .catch(error => {
                console.log(error);
            });

        dispatch(appActions.stopLoading());
    };

    function request() {return {type: dashboardConstant.GET_REFERRAL_P_REQUEST}}
    function success(count) { return {type: dashboardConstant.GET_REFERRAL_P_SUCCESS, count}}
    function error(error) { return {type: dashboardConstant.GET_MONTH_ERROR, error}}
}

function getDSDetailSummary(locationID, dsType, periodID) {

    return (dispatch) => {

        dispatch(request());
        dispatch(appActions.startLoading());

        axios.get(`/api/v1/dashboard/DS/${dsType}/${locationID}/${periodID}`)
            .then(response => {
                console.log(response.data)
                if (dsType == 'FALLS') {
                    dispatch(falls(response.data.data));
                } else if (dsType == 'SERIOUS INJURY') {
                    dispatch(seriousInjury(response.data.data));
                } else if (dsType == 'PRESSURE INJURY') {
                    dispatch(pressureInjury(response.data.data));
                } else if (dsType == 'INFECTIONS') {
                    dispatch(infections(response.data.data));
                } else if (dsType == 'SAFEGUARDING') {
                    dispatch(safeguarding(response.data.data));
                } else if (dsType == 'ADMITTED TO HOSPITAL') {
                    dispatch(transferToHospital(response.data.data));
                } else if (dsType == 'CQC NOTIFICATION DATE') {
                    dispatch(cqcNotification(response.data.data));
                } else if (dsType == 'REFERRAL') {
                    dispatch(referral(response.data.data));
                }
                
            })
            .catch(error => {
                console.log(error);
            });

        dispatch(appActions.stopLoading());
    };

    function request() {return {type: dashboardConstant.GET_REFERRAL_P_REQUEST}}
    function falls(dsDetail) { return {type: dashboardConstant.GET_FALLS_SUCCESS, dsDetail}}
    function seriousInjury(dsDetail) { return {type: dashboardConstant.GET_SERIOUS_INJURY_SUCCESS, dsDetail}}
    function pressureInjury(dsDetail) { return {type: dashboardConstant.GET_PRESSURE_INJURY_SUCCESS, dsDetail}}
    function infections(dsDetail) { return {type: dashboardConstant.GET_INFECTIONS_SUCCESS, dsDetail}}
    function safeguarding(dsDetail) { return {type: dashboardConstant.GET_SAFEGUARDING_SUCCESS, dsDetail}}
    function transferToHospital(dsDetail) { return {type: dashboardConstant.GET_TRANSFERTOHOSPITAL_SUCCESS, dsDetail}}
    function cqcNotification(dsDetail) { return {type: dashboardConstant.GET_CQCNOTIFICATION_SUCCESS, dsDetail}}
    function referral(dsDetail) { return {type: dashboardConstant.GET_REFERRAL_SUCCESS, dsDetail}}
    function error(error) { return {type: dashboardConstant.GET_MONTH_ERROR, error}}
}