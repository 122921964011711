import { Box, Button, Card, CardContent, CardHeader, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, MenuItem, Paper, Radio, RadioGroup, TextField } from "@mui/material";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import useSelection from "antd/lib/table/hooks/useSelection";
import React, { useState, useEffect} from "react";
import { dataURItoByteString } from "react-pdf/dist/umd/shared/utils";
import { useDispatch, useSelector } from "react-redux";

import { referralAction } from '../../_actions/referralAction';

export const AssessmentForm = ({referral}) => {

    const dispatch = useDispatch();

    const [isQualified, setIsQualified] = useState('');
    const [careBandId, setCareBandId] = useState('');
    //const [remark, setRemark] = useState();
    const [data, setData] = useState({gpName: '', gpAddress: '', closeReasonID: ''});
    const [errors, setErrors] = useState([]);
    
    const careBands = useSelector(state => state.careband.bands);
    const closeReasons = useSelector(state =>state.referral.reasons);


    useEffect(() => {
        dispatch(referralAction.getCloseReasons());
    }, [])

    useEffect(() => {
        console.log(referral)
        referral.careBand ? setCareBandId(referral.careBand.id) : setCareBandId('');
        referral.isQualified2 == null ? setIsQualified('') : setIsQualified(referral.isQualified2);
        setData({...data, preferredName: referral.preferredName, gpName: referral.gpName, gpAddress: referral.gpAddress});
    },[referral])

    const handleChange = (event) => {
        //setIsQualified(event.target.value);
        const {name, value } = event.target;

        setData({...data, [name] : value});
    };
    
    const onSubmit = (e) => {
        e.preventDefault();
        console.log(careBandId)

        if (validate()) {

            let vCareBandId = (isQualified == 'N') ? 0 : careBandId ;
            console.log(vCareBandId)

            dispatch(referralAction.updateAssessment(referral.id, isQualified, vCareBandId,data));
        }
        
    }


    const validate = () => {
        console.log("validate Forms")
        let formErrors = {error: false};

        if (!isQualified || isQualified == '') {
            formErrors = {...formErrors, error: true, isQualified: 'Invalid Option'};
        }  
        
        if (isQualified == 'Y') {

            if (careBandId <= 0) {
                formErrors = {...formErrors, error: true, careBandId: 'Careband is required'};
            }


            if (!data.preferredName || data.preferredName.length <= 0) {
                formErrors = {...formErrors, error: true, preferredName: 'Preferred Name required'}
            }

        } else {

            if (!data.closeReasonID && data.closeReasonID <= 0) {
                formErrors = {...formErrors, error: true, reasonID: 'Reason is required'};
            }

            if (!data.remark || data.remark.length <= 0) {
                formErrors = {...formErrors, error: true, remark: 'Please add a note why not qualified'};
            }
        }


        setErrors(formErrors);
        return !formErrors.error;
    }

    return (
        <Card>
            <CardHeader title="Assessment Form" />

            <CardContent>
            
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel id="isQualified">Is Qualified</FormLabel>

                            <RadioGroup
                                aria-labelledby="isQualified"
                                name="isQualified"
                                value={isQualified}
                                onChange={(e) => setIsQualified(e.target.value)}
                                row
                            >
                                <FormControlLabel value="Y" label="Yes" control={<Radio />}></FormControlLabel>
                                <FormControlLabel value="N" label="No" control={<Radio />}></FormControlLabel>
                            </RadioGroup>

                            <FormHelperText>{errors.isQualified ? errors.isQualified : '' }</FormHelperText>

                        </FormControl>
                    </Grid>

                    <Grid item xs={12} >
                        <TextField
                            id="careBandId"
                            select
                            label="Care Band"
                            name="careBandId"
                            value={careBandId}
                    
                            //onChange={(e) => handleCareBandChange(index, e.target.name, e.target.value )}
                            onChange={(e) => setCareBandId(e.target.value)}
                            variant="outlined"
                            fullWidth
                            error={errors.careBandId}
                            helperText={errors.careBandId ? errors.careBandId : ''}
                        >
                        
                            {careBands.map(band => <MenuItem key={band.id} value={band.id}>{`${band.name}`}</MenuItem>)}
                
                        </TextField>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            
                            fullWidth
                            id="preferredName"
                            name="preferredName"
                            label="Preferred Name"
                            value={data.preferredName}
                            onChange={handleChange}
                            error={errors.preferredName}
                            helperText={errors.preferredName ? errors.preferredName : ''}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            
                            fullWidth
                            id="gpName"
                            name="gpName"
                            label="GP"
                            value={data.gpName}
                            onChange={handleChange}
                            error={errors.gpName}
                            helperText={errors.gpName ? errors.gpName : ''}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            
                            fullWidth
                            id="gpAddress"
                            name="gpAddress"
                            label="GP Address"
                            value={data.gpAddress}
                            onChange={handleChange}
                            error={errors.gpAddress}
                            helperText={errors.gpAddress ? errors.gpAddress : ''}
                        />
                    </Grid>

                    {isQualified == 'N' &&
                    <Grid item xs={12} >
                        <TextField
                            id="closeReasonID"
                            select
                            label="Close Reason"
                            name="closeReasonID"
                            value={data.reasonID}
                    
                            //onChange={(e) => handleCareBandChange(index, e.target.name, e.target.value )}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            error={errors.closeReasonID}
                            helperText={errors.closeReasonID ? errors.closeReasonID : ''}
                        >
                        
                            {closeReasons.map(reason => <MenuItem key={reason.id} value={reason.id}>{`${reason.description}`}</MenuItem>)}
                
                        </TextField>
                    </Grid>
                    }

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            
                            fullWidth
                            id="remark"
                            name="remark"
                            label="Remark"
                            value={data.remark}
                            onChange={handleChange}
                            error={errors.remark}
                            helperText={errors.remark ? errors.remark : ''}
                        />
                    </Grid>

                <Button variant="contained" sx={{ m: 1}} onClick={onSubmit}>Save</Button>
                
                </Grid> 

            </CardContent>

        </Card>
    )
}