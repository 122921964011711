import { Alert as MuiAlert, AlertTitle as MuiAlertTitle } from '@mui/material';

import React from 'react';
import { useSelector } from 'react-redux';

export const Alert = () => {

    const alert = useSelector(state => state.alert);

    return (
        <>
        {alert.type && 
        <MuiAlert severity={alert.type}>
            <MuiAlertTitle>{alert.type}</MuiAlertTitle>
            {alert.message}
        </MuiAlert>
        }
        </>
    );
}