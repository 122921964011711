import { LoadingButton } from '@mui/lab';
import { Grid, MenuItem, TextField } from '@mui/material';
import {useState, useEffect} from 'react';

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { entryAction } from '../../_actions/entryAction';

export const EntryForm = ({currEntry, close}) => {

    const dispatch = useDispatch();

    const [entry, setEntry] = useState(currEntry);
    const [errors, setErrors] = useState([]);

    const loading = false;

    useEffect(() => {
        if (currEntry.id > 0 ) {
            setEntry(currEntry);
        }
    }, [currEntry]);

    const handleFormChange = (e) => {
        const {name, value} = e.target;

        setEntry({...entry, [name]: value});
    }

    const handleSubmit = () => {
        console.log(entry);
        dispatch(entryAction.update(entry));
    }

    return (
    
        <Grid container spacing={2}>

            <Grid item xs={12} sm={3}>
                <TextField
                    name="foodHygiene"
                    variant="outlined"
                    required
                    fullWidth
                    id="foodHygiene"
                    label="Food Hygiene"
                    value={entry.foodHygiene}
                    onChange={handleFormChange}
                    error={errors.foodHygiene}
                    helperText={errors.foodHygiene ? errors.foodHygiene : ''}
                />
            </Grid>


            <Grid item xs={12} sm={3}>
                <TextField
                    id="cqcRating"
                    select
                    label="CQC Rating"
                    name="cqcRating"
                    value={entry.cqcRating}
                    onChange={handleFormChange}
                    variant="outlined"
                    fullWidth
                    error={errors.cqcRating}
                        helperText={errors.cqcRating ? errors.cqcRating : ''}
                >
                    <MenuItem key={1} value={"Outstanding"}>Outstanding</MenuItem>
                    <MenuItem key={2} value={"Good"}>Good</MenuItem>
                    <MenuItem key={3} value={"Requires improvement"}>Requires improvement</MenuItem>
                    <MenuItem key={4} value={"Inadequate"}>Inadequate</MenuItem>
                </TextField>
            </Grid>

            <Grid item xs={12} sm={3}>
                <TextField
                    id="cqcIpc"
                    label="CQC IPC No-Assured Sections"
                    name="cqcIpc"
                    value={entry.cqcIpc}
                    onChange={handleFormChange}
                    variant="outlined"
                    fullWidth
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={errors.cqcIpc}
                        helperText={errors.cqcIpc ? errors.cqcIpc : ''}
                />
                
            </Grid>

            <Grid item xs={12} sm={3}>
                <TextField
                    name="carehomeCoUk"
                    variant="outlined"
                    required
                    fullWidth
                    id="carehomeCoUk"
                    label="Carehome.co.uk"
                    value={entry.carehomeCoUk}
                    onChange={handleFormChange}
                    error={errors.carehomeCoUk}
                    helperText={errors.carehomeCoUk ? errors.carehomeCoUk : ''}
                />
            </Grid>  

            <Grid item xs={12}></Grid>
            <Grid item xs={8}></Grid>

            <Grid item xs={2} >
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    onClick={handleSubmit}
                >
                    Update
                </LoadingButton>

            </Grid>

            <Grid item xs={2} >
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<CloseIcon />}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={close}
                >
                    Close
                </LoadingButton>

            </Grid>
        </Grid> 
             
    )
}