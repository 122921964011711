import { appConstant } from '../_constants/appConstant';

export function skapp(state = {loading: false}, action) {
    switch (action.type) {
        case appConstant.START_LOADING:
            return {
                ...state,
                loading: true,
            };
        case appConstant.STOP_LOADING:
            return {
                ...state,
                loading: false
            };
        default:
            return state
    }
}