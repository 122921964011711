import { roomConstant } from "../_constants/roomConstant";

const initialState = {
            loading: false,
            occupancies: [],
            nonOccupiedRooms: [],
            rooms: [],
        };

export function room(state = initialState, action) {
    switch (action.type) {
        case roomConstant.REQUEST: 
            return {
                ...state,
                loading: true,
            }
        case roomConstant.REQUEST_ERROR:
            return {
                ...state,
                loading: false
            }
        case roomConstant.GET_ACTIVE_OCCUPANCIES_SUCCESS:
            return {
                ...state,
                occupancies: action.occupancies,
                loading: false
            }
        case roomConstant.GET_NON_OCCUPIED_SUCCESS:
            return {
                ...state,
                nonOccupiedRooms: action.rooms,
                loading: false,
            }
        case roomConstant.CREATE_OCCUPY_SUCCESS: 
            return {
                ...state,
                occupancies: [...state.occupancies, action.occupy],
                rooms: state.rooms.map(room => room.id == action.occupy.room.id ? {...room, currBooking: action.occupy }: room),
                loading: false,
            }
        case roomConstant.UPDATE_OCCUPY_SUCCESS:
            return {
                ...state,
                occupancies: state.occupancies.map(occupy => occupy.id == action.occupy.id ? action.occupy : occupy),
                rooms: state.rooms.map(room => room.id == action.occupy.room.id ? {...room, isOccupied: action.occupy.room.isOccupied} : room),
                loading: false,
            }
        case roomConstant.UPDATE_OCCUPY_LEAVING_SUCCESS: 
            return {
                ...state,
                rooms: state.rooms.map(room => room.id == action.occupy.room.id ? {...room, currBooking: {...room.currBooking, toDate: action.occupy.toDate} }: room),
                loading: false
            }
        case roomConstant.GET_ROOMS_WITH_OCCUPANCY_INFO_SUCCESS :
            return {
                ...state, 
                rooms: action.rooms,
                loading: false
            }
        default:
            return state;
    }
}