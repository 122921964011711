import { fn } from "moment";
import { fundConstant } from "../_constants/fundConstant";
import axios from '../_helpers/axios';

export const fundAction = {
    getFundTypes,
}


function getFundTypes() {

    return (dispatch) => {

        dispatch(request());

        axios.get('/api/v1/fund/types')
            .then(response => {
                dispatch(success(response.data))
            })
            .catch(error => {
                console.log(error);
            });
    };

    function request() {return {type: fundConstant.REQUEST}}
    function success(types) { return {type: fundConstant.GET_FUND_TYPES_SUCCESS, types}}
    function error(error) { return {type: fundConstant.GET_FUND_TYPES_ERROR, error}}
}