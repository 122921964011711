import { Button, Card, CardContent, CardHeader, Grid } from "@mui/material"
import { WRLabel, WRValue } from "../ui/InfoBlock";

import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';

export const KCI = ({
    falls, 
    seriousInjury, 
    pressureInjury, 
    infections,
    safeguarding,
    transferToHospital,
    cqcNotification
}) => {
    return (
        <Card>
            <CardHeader title="Key Clinical Information (KCI)" />

            <CardContent>
                <Grid container spacing={1}>
                    <Grid item xs={6}><WRLabel value="Accidents and incidents (where injury occurred) (resident/staff)" /></Grid>
                    <Grid item xs={2}><WRValue value={seriousInjury.totNInfo1 ? seriousInjury.totNInfo1 : '-'} /></Grid>
                    <Grid item xs={4}><Button variant="contained" endIcon={<KeyboardDoubleArrowRightRoundedIcon />}>Learn More </Button></Grid>

                    <Grid item xs={6}><WRLabel value="Falls (where serious injury occurred and needing CQC notification)" /></Grid>
                    <Grid item xs={2}><WRValue value={falls.totNInfo1 ? falls.totNInfo1 : '-'} /></Grid>
                    <Grid item xs={4}><Button variant="contained" endIcon={<KeyboardDoubleArrowRightRoundedIcon />}>Learn More </Button></Grid>


                    <Grid item xs={6}><WRLabel value="CQC Statutory Notifications" /></Grid>
                    <Grid item xs={1}><WRValue value={cqcNotification.totNInfo1 ? cqcNotification.totNInfo1 : '-'} /></Grid>
                    
                    <Grid item xs={2}><Button variant="contained" endIcon={<KeyboardDoubleArrowRightRoundedIcon />}>Learn More </Button></Grid>
                    <Grid item xs={3}></Grid>

                    <Grid item xs={6}><WRLabel value="Safeguarding concerns raised" /></Grid>
                    <Grid item xs={1}><WRValue value={safeguarding.totNInfo1 ? safeguarding.totNInfo1 : '-'}  /></Grid>
                    <Grid item xs={2}><Button variant="contained" endIcon={<KeyboardDoubleArrowRightRoundedIcon />}>Learn More </Button></Grid>
                    <Grid item xs={3}></Grid>

                    <Grid item xs={6}><WRLabel value="Transfer to Hospital" /></Grid>
                    <Grid item xs={1}><WRValue value={transferToHospital.totNInfo1 ? transferToHospital.totNInfo1 : '-'}  /></Grid>
                    <Grid item xs={2}><Button variant="contained" endIcon={<KeyboardDoubleArrowRightRoundedIcon />}>Learn More </Button></Grid>
                    <Grid item xs={3}></Grid>

                    <Grid item xs={6}><WRLabel value="Infections" /></Grid>
                    <Grid item xs={1}><WRValue value={infections.totNInfo1 ? infections.totNInfo1 : '-'}  /></Grid>
                    <Grid item xs={2}><Button variant="contained" endIcon={<KeyboardDoubleArrowRightRoundedIcon />}>Learn More </Button></Grid>
                    <Grid item xs={3}></Grid>

                    <Grid item xs={6}><WRLabel value="Pressure ulcers" /></Grid>
                    <Grid item xs={1}><WRValue value={pressureInjury.totNInfo1 ? pressureInjury.totNInfo1 : '-'}  /></Grid>
                    <Grid item xs={2}><Button variant="contained" endIcon={<KeyboardDoubleArrowRightRoundedIcon />}>Learn More </Button></Grid>
                    <Grid item xs={3}></Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}