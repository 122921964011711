export const employeeConstant = {
    REQUEST : 'EMPLOYEE_REQUEST',

    GET_EMPLOYEES_SUCCESS : 'EMPLOYEE_GET_EMPLOYEES_SUCCESS',
    GET_EMPLOYEES_ERROR : 'EMPLOYEE_GET_EMPLOYEES_ERROR',

    CREATE_SUCCESS: 'EMPLOYEE_CREATE_SUCCESS',
    CREATE_ERROR: 'EMPLOYEE_CREATE_ERROR',

    UPDATE_SUCCESS: 'EMPLOYEE_UPDATE_SUCCESS',
    UPDATE_ERROR: 'EMPLOYEE_UPDATE_ERROR',

    GET_JOBROLES_SUCCESS: 'EMPLOYEE_GET_JOBROLES_SUCCESS',
    GET_JOBROLES_ERROR: 'EMPLOYEE_GET_JOBROLES_ERROR',

    GET_CONTRACTS_SUCCESS: 'EMPLOYEE_GET_CONTRACTS_SUCCESS',
    GET_CONTRACTS_ERROR: 'EMPLOYEE_GET_CONTRACTS_ERROR',

    ADD_CONTRACT_SUCCESS: 'EMPLOYEE_ADD_CONTRACT_SUCCESS',

    UPDATE_CONTRACT_SUCCESS: 'EMPLOYEE_UPDATE_CONTRACT_SUCCESS',
}