import { Box, Card, CardContent, CardHeader, Grid } from "@mui/material";

import { WRLabel, WRValue } from "../ui/InfoBlock";

export const General = () => {
    return (
        <Card>
            <CardHeader title="General Information" />

            <CardContent>
                <Grid container spacing={1}>
                
                    <Grid item xs={8}><WRLabel value="Management Holidays" /></Grid>
                    <Grid item xs={4}><WRValue value="-" /></Grid>

                    <Grid item xs={8}><WRLabel value="Major Issues" /></Grid>
                    <Grid item xs={4}><WRValue value="-" /></Grid>

                    <Grid item xs={8}><WRLabel value="Service Interruption" /></Grid>
                    <Grid item xs={4}><WRValue value="-" /></Grid>

                    <Grid item xs={8}><WRLabel value="Meetings" /></Grid>
                    <Grid item xs={4}><WRValue value="-" /></Grid>

                
                </Grid>
            </CardContent>
        </Card>
    )
}