import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import React from "react";
import { dateFormat } from "../../_helpers/datetime";
import { InfoBlock } from "../ui/InfoBlock";

export const BillingDetail = ({referral}) => {

    const {invoiceTo, invoiceAddress} = referral;

    return (

        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="assessment-detail"
                id="assessment-detail"
            >
                <Typography variant="h6">Billing Detail</Typography>
            </AccordionSummary>
                
            <AccordionDetails>
                <InfoBlock label={"Bill To"} value={invoiceTo} valueType={"date"} />
                <InfoBlock label={"Address"} value={invoiceAddress} valueType={"date"} />
            </AccordionDetails>
        </Accordion>

    )

}