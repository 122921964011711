import { useState, useEffect } from 'react';

import { Card, CardContent, CardHeader, Grid, MenuItem, TextField } from "@mui/material";

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import { dateFormat, SYS_DATE_FORMAT } from '../../_helpers/datetime';
import { LoadingButton } from '@mui/lab';

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { incidentAction } from '../../_actions/incidentAction';

import moment from "moment";

export const RegisterIncidentForm = ({currIncident, close}) => {

    const dispatch = useDispatch();

    const [incident, setIncident] = useState(currIncident);
    const [errors, setErrors] = useState([]);
    const [btnSaveLabel, setBtnSaveLabel] = useState('Register');

    const types = useSelector(state => state.incident.types);
    const reasons = useSelector(state => state.incident.reasons);

    useEffect(() => {
        dispatch(incidentAction.getIncidentTypes());
    },[])

    useEffect(() => {
        if (incident.typeID > 0) {
            dispatch(incidentAction.getIncidentTypeReasons(incident.typeID));
        }
    },[incident.typeID])

    const handleFormChange = (e) => {
        const {name, value} = e.target;

        setIncident({...incident, [name]: value});
    }

    const handleDatePicker = (name, date) => {
        setIncident({...incident, [name]: date.format("YYYY-MM-DD hh:mm:ss")}); 
    }

    const onSubmit = () => {
        console.log(incident);
        if (validate()) {
            dispatch(incidentAction.register(incident));
        }
    }

    const validate = () => {
        console.log("validate Forms")
        let formErrors = {error: false};

        if (!incident.incidentDateTime || incident.incidentDateTime.length <= 0) {
            formErrors = {...formErrors, error: true, incidentDateTime: 'Required Incident Date'};
        } 

        if (!incident.typeID || incident.typeID.length <= 0) {
            formErrors = {...formErrors, error: true, typeID: 'Required Incident Type'};
        } 

        setErrors(formErrors);
        return !formErrors.error;
    }

    return (
        <Card>
            <CardHeader title="New Incident Registration" />

            <CardContent>
                <Grid container spacing={2}>
        
                    <Grid item xs={3} >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={DateAdapter}>
                                <DatePicker
                                    label="Incident Date"
                                    type="date"
                                    mask="__-__-____"
                                    value={incident.incidentDateTime}
                                    inputFormat={SYS_DATE_FORMAT}
                                    maxDate={moment()}
                                    onChange={(newValue) => {
                                        handleDatePicker('incidentDateTime', newValue);
                                    }}
                                    renderInput={(params) => 
                                        <TextField {...params} error={errors.incidentDateTime}
                                            fullWidth
                                            disabled
                                            helperText={errors.incidentDateTime ? errors.incidentDateTime : ''} />}
                                />
                                </LocalizationProvider>
                            </Grid>
        
                            <Grid item xs={12}>
                                <TextField
                                    id="typeID"
                                    select
                                    label="Type"
                                    name="typeID"
                                    value={incident.typeID}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    fullWidth
                                    required
                                    error={errors.typeID}
                                        helperText={errors.typeID ? errors.typeID : ''}
                                >
                                    {types.map(type => <MenuItem key={type.id} value={type.id}>{type.description}</MenuItem>)}
                    
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    id="reasonID"
                                    select
                                    label="Reason"
                                    name="reasonID"
                                    value={incident.reasonID}
                                    onChange={handleFormChange}
                                    variant="outlined"
                                    fullWidth
                                    required
                                    error={errors.reasonID}
                                        helperText={errors.reasonID ? errors.reasonID : ''}
                                >
                                    {reasons.map(reason => <MenuItem key={reason.id} value={reason.id}>{reason.description}</MenuItem>)}
                    
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="registrationRemark"
                                    name="registrationRemark"
                                    label="Remark"
                                    value={incident.registrationRemark}
                                    onChange={handleFormChange}
                                    error={errors.registrationRemark}
                                    helperText={errors.registrationRemark ? errors.registrationRemark : ''}
                                    multiline
                                    
                                    maxRows={4}
                                />
                    
                            </Grid>

                        </Grid>

                    </Grid>
                    <Grid item xs={9}>
                      
                    </Grid>
                    <Grid item xs={12}></Grid>
                        <Grid item xs={8}></Grid>

                        <Grid item xs={2} >
                            <LoadingButton
                                loading={false}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                type="submit"
                                onClick={onSubmit}
                            >
                                {btnSaveLabel}
                            </LoadingButton>

                        </Grid>

                        <Grid item xs={2} >
                            <LoadingButton
                                loading={false}
                                loadingPosition="start"
                                startIcon={<CloseIcon />}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={close}
                            >
                                Close
                            </LoadingButton>

                        </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}