import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import React from "react";
import { dateFormat } from "../../_helpers/datetime";
import { InfoBlock } from "../ui/InfoBlock";

export const AssessmentDetail = ({referral}) => {

    const {admissionDate, assessmentDate, isQualified2,careBand} = referral;

    return (

        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="assessment-detail"
                id="assessment-detail"
            >
                <Typography variant="h6">Assessment Detail</Typography>
            </AccordionSummary>
                
            <AccordionDetails>
                <InfoBlock label={"Assessment Date"} value={dateFormat(assessmentDate)} valueType={"date"} />
                <InfoBlock label={"Assessment Result"} value={isQualified2 == 'Y' ? 'Qualified' : 'Not Qualified'} valueType={"text"} />
                <InfoBlock label={"Care Band"} value={careBand && careBand.name} valueType={"text"} />
                <InfoBlock label={"Admission Date"} value={admissionDate && dateFormat( admissionDate)} valueType={"date"} />
            </AccordionDetails>
        </Accordion>

    )

}