export const entryConstant = {

    REQUEST: 'ENTRY_REQUEST',
    GET_LOC_ENTRIES_SUCCESS: 'ENTRY_GET_LOC_ENTRIES_SUCCESS',
    GET_LOC_ENTRIES_ERROR: 'ENTRY_GET_LOC_ENTRIES_ERROR',

    UPDATE_SUCCESS: 'ENTRY_UPDATE_SUCCESS',
    UPDATE_ERROR: 'ENTRY_UPDATE_ERROR',

    GET_CURR_PERIOD_SUCCESS: 'ENTRY_GET_CURR_PERIOD_SUCCESS',
    GET_CURR_PERIOD_ERROR: 'ENTRY_GET_CURR_PERIOD_ERROR',

    GET_ENTRY_PERIOD_SUCCESS: 'ENTRY_GET_ENTRY_PERIOD_SUCCESS',
    GET_ENTRY_PERIOD_ERROR: 'ENTRY_GET_ENTRY_PERIOD_ERROR',
}