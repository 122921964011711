import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CardSummary } from '../components/dashboard/CardSummary';
import LineGraph from '../components/dashboard/LineGraph';
import StackedBarChart from '../components/dashboard/StackedBarChart';
import { dashboardAction } from '../_actions/dashboardAction';
import { localCurrency } from '../_helpers/number';

export const Dashboard = () => {

    const dispatch = useDispatch();
    const todayDs = useSelector(state => state.dashboard.today);
    const referralP = useSelector(state => state.dashboard.referralP);

    const workingLocation = useSelector(state => state.location.working);

    

    useEffect(() => {
        if (workingLocation.id > 0) {
          dispatch(dashboardAction.getMonth(workingLocation));
          dispatch(dashboardAction.getReferralPending(workingLocation));
        }

    }, [workingLocation]);

    
    return (
        <Paper style={{padding: 12, color: 'red'}}>
            <Box display="flex">
                <Box flexGrow={1}>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                    Dashboard
                </Typography>
                </Box>

                <Button variant='outlined' component={Link} to="/weekreport">Weekly Report</Button>
            </Box>

          <Grid container spacing={3}>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
              <CardSummary
                title="Rooms Occupied"
                value={todayDs.noOfRoomsOccupied}
                footer={<div> at Now </div>}
              />
            </Grid>
        
            <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
              <CardSummary
                title="Rooms Not-occupied"
                value={todayDs.noOfRoomsNotOccupied}
                footer={<div> at Now </div>}
              />
            </Grid>

            <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
              <CardSummary
                title="Pending Referrals"
                value={referralP}
                footer={<div> at Now </div>}
              />
            </Grid>
        
          {/*<Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
            <CardSummary
              title="Target"
              value={localCurrency(todayDs.target)}
              footer={<div>  at Now </div>}
   
          </Grid>
        
          <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
            <CardSummary
              title="Actual "
              value={localCurrency(todayDs.actual)}
              footer={<div> at now </div>}

          </Grid>
    />*/}

            <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
              <CardSummary
                  title="CQC Rating "
                  value="Good"
                  footer={<div> at now </div>}
                />
            </Grid>
          

            <Grid item xl={9} lg={9} md={12} sm={12} xs={12}>
        
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <LineGraph title="Average Target vs Average Actual" data={todayDs.dailyPerformance}/>
              </Grid>

              {/*<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <StackedBarChart title="Referrals" data={todayDs.dailyPerformance}/>
      </Grid>*/}

            </Grid>

            <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              </Grid>    
            </Grid>

          </Grid>
           
        </Paper>
    )
}