import { Card, CardContent, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Funds } from "../room/Funds";
import { InfoBlock } from "../ui/InfoBlock";
import { AssessmentDetail } from "./AssessmentDetail";
import { BillingDetail } from "./BillingDetail";
import { ClientDetail } from "./ClientDetail";
import { ContactDetail } from "./ContactDetail";
import { DataDetail } from "./DataDetail";
import { FundDetail } from "./FundDetail";
import { GPDetail } from "./GPDetail";
import { ReferralDetail } from "./ReferralDetail";
import { RoomDetail } from "./RoomDetail";
import { StageRow } from "./StageRow";
import { Tasks } from "./Tasks";

export const Detail = () => {

    const referral = useSelector(state => state.referral.currReferral);

    useEffect(() => {
        console.log(referral)
        
    },[referral])
    return (
        <Grid container spacing={2}>
            <Grid item xs="3">
                <Grid container spacing={2}>

                    <Grid item xs="12">
                        <ReferralDetail referral={referral} />
                    </Grid>
                    
                    <Grid item xs="12">
                        <ClientDetail 
                            referral={referral}
                        />
                    </Grid>
                    
                    <Grid item xs="12">
                        <ContactDetail
                            firstName={referral.contactFirstName}
                            lastName={referral.contactLastName}
                            relationship={referral.contactRelationship}
                            contactNo={referral.contactNo}
                            contactEmail={referral.contactEmail}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <BillingDetail
                            referral={referral}
                        />
                    </Grid>

                </Grid>
            </Grid>

            <Grid item xs="3">
                <Grid container spacing={2}>

                    <Grid item xs="12">
                        <AssessmentDetail referral={referral} />
                    </Grid>

                    <Grid item xs="12">
                        <GPDetail referral={referral} />
                    </Grid>

                    <Grid item xs={12}>
                        { referral.room && <RoomDetail room={referral.room} agreedAmount={referral.agreedAmount} /> }
                    </Grid>

                    <Grid item xs={12}>
                        <FundDetail 
                            funds={referral.funds} />
                    </Grid>

                    <Grid item xs="12">
                        <DataDetail 
                            isQualified1={referral.isQualified1}
                            assessmentDate={referral.assessmentDate}
                            isQualified2={referral.isQualified2}
                            careBand={referral.careBand ? referral.careBand.name : ''}
                            admissionDate={referral.admissionDate}
                            agreedAmount={referral.agreedAmount}
                            room={referral.room}
                        />
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs="6">
                <Tasks referral={referral} />
            </Grid>

        </Grid>
    )
}