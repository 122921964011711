import { Paper } from "@mui/material";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import React from "react";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body3,
    textAlign: 'start',
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: '60px',
    padding: '4px'
  }));

export const StageRow = ({label}) => {
    return (
        <Item elevation={2}>
            {label}
        </Item>
    )
}