import { Box, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdoptGP } from "../components/weekreport/AdoptGP";
import { Autummna } from "../components/weekreport/Autummna";
import { Carehome } from "../components/weekreport/Carehome";
import { CareWorkersCharity } from "../components/weekreport/CareWorkersCharity";
import { CQCIPC } from "../components/weekreport/CQCIPC";
import { CurrentPerformance } from "../components/weekreport/CurrentPerformance";
import { ElderPA } from "../components/weekreport/ElderPA";
import { General } from "../components/weekreport/General";
import { KCI } from "../components/weekreport/KCI";
import { KFI } from "../components/weekreport/KFI";
import { ManagementKPIScore } from "../components/weekreport/ManagementKPIScore";
import { RatingCQC } from "../components/weekreport/RatingCQC";
import { RatingTile } from "../components/weekreport/RatingTile";
import { dashboardAction } from "../_actions/dashboardAction";
import { entryAction } from "../_actions/entryAction";
import { dateFormat } from "../_helpers/datetime";

export const WeekReport = ({}) => {

    const dispatch = useDispatch();

    const period = useSelector(state => state.entry.currPeriod);
    const entryPeriod = useSelector(state => state.entry.entryPeriod);
    const workingLocation = useSelector(state => state.location.working);
    const room = useSelector(state => state.dashboard.room);
    const falls = useSelector(state => state.dashboard.falls);
    const seriousInjury = useSelector(state => state.dashboard.seriousInjury);

    const pressureInjury = useSelector(state => state.dashboard.pressureInjury);
    const infections = useSelector(state => state.dashboard.infections);
    const safeguarding = useSelector(state => state.dashboard.safeguarding);
    const transferToHospital = useSelector(state => state.dashboard.transferToHospital);
    const cqcNotification = useSelector(state => state.dashboard.cqcNotification);
    const referral = useSelector(state => state.dashboard.referral);
    
    useEffect(() => {
        dispatch(entryAction.getCurrentWeek(workingLocation.id));
    },[])

    useEffect(() => {
        if (period.id > 0 && workingLocation.id > 0) {
            dispatch(entryAction.getWeekEntries(period.id, workingLocation.id));
            dispatch(dashboardAction.getWeek(workingLocation, period.id));
            dispatch(dashboardAction.getDSDetailSummary(workingLocation.id, 'FALLS',period.id));
            dispatch(dashboardAction.getDSDetailSummary(workingLocation.id, 'SERIOUS INJURY',period.id));

            dispatch(dashboardAction.getDSDetailSummary(workingLocation.id, 'SAFEGUARDING',period.id));
            dispatch(dashboardAction.getDSDetailSummary(workingLocation.id, 'INFECTIONS',period.id));
            dispatch(dashboardAction.getDSDetailSummary(workingLocation.id, 'PRESSURE INJURY',period.id));

            dispatch(dashboardAction.getDSDetailSummary(workingLocation.id, 'ADMITTED TO HOSPITAL',period.id));
            dispatch(dashboardAction.getDSDetailSummary(workingLocation.id, 'CQC NOTIFICATION DATE',period.id));

            dispatch(dashboardAction.getDSDetailSummary(workingLocation.id, 'REFERRAL',period.id));
        }
        
    },[period, workingLocation])

    return (
        <Paper style={{padding: 12, color: 'red'}}>
            <Box display="flex">
                <Box flexGrow={1}>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                    Weekly Report - {workingLocation.name}
                </Typography>
                </Box>

                <Box>
                <Typography variant="button" color="primary" gutterBottom>
                    Week - {dateFormat(period.fromDate)} - {dateFormat(period.toDate)}
                </Typography>
                </Box>
            </Box>


            <Grid container spacing={2}>
                <Grid item xs={10}>

                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <KFI room={room} referral={referral}/>
                        </Grid>

                        <Grid item xs={4}>
                            <General />
                        </Grid>
                    
                        <Grid item xs={10}>
                            <CurrentPerformance room={room} />
                        </Grid>

                        <Grid item xs={2}>
                            <ManagementKPIScore score="-" />
                        </Grid>

                        <Grid item xs={12}>
                            <KCI falls={falls} 
                                seriousInjury={seriousInjury}
                                pressureInjury={pressureInjury}
                                infections={infections}
                                safeguarding={safeguarding}
                                transferToHospital={transferToHospital}
                                cqcNotification={cqcNotification}
                            />
                        </Grid>
                    </Grid>
                    
                    


                </Grid>

                <Grid item xs={2}>
                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            <RatingCQC secondary={entryPeriod.cqcRating} />
                        </Grid>

                        <Grid item xs={12}>
                            <RatingTile ratingValue={entryPeriod.foodHygiene} title={"Food Hygiene"} />
                        </Grid>

                        <Grid item xs={12}>
                            <CQCIPC />
                        </Grid>

                        <Grid item xs={12}>
                            <Carehome rating={entryPeriod.carehomeCoUk} />
                        </Grid>

                        <Grid item xs={12}>
                            <ElderPA />
                        </Grid>

                        <Grid item xs={12}>
                            <Autummna />
                        </Grid>

                        <Grid item xs={12}>
                            <CareWorkersCharity />
                        </Grid>

                        <Grid item xs={12}>
                            <AdoptGP />
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>



        </Paper>
    )
}