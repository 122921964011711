import React, { useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Alert, Button, Card, CardContent, CardHeader, Grid, MenuItem, TextField } from '@mui/material';

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import { localCurrency } from '../../_helpers/number';

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

import moment from "moment";
import { SYS_DATE_FORMAT } from "../../_helpers/datetime";
import { validateDateTime } from '@mui/lab/internal/pickers/date-time-utils';
import { ContactlessOutlined } from '@mui/icons-material';
import { fundAction } from '../../_actions/fundAction';
import { useDispatch, useSelector } from 'react-redux';
import { referralAction } from '../../_actions/referralAction';

import { CYCLES } from '../../_constants/appConstant';

const initialFund = {
    id: 0,
    fundId : 0,
    amount: 0,
    cycle: ''
}

export const FundForm = ({referral}) => {

    const dispatch = useDispatch();

    const [funds, setFunds] = useState([]);
    const [fee, setFee] = useState(0);
    const [errors, setErrors] = useState([]);
    const fundTypes = useSelector(state =>  state.fund.types);

    useEffect(() => {
        console.log(funds);
        let fundTotal = funds.map(fund => parseFloat(fund.amount)).reduce((prevVal, currVal) => prevVal + currVal, 0);
        setFee(fundTotal);

    }, [funds]);

    useEffect(() => {
        if (referral.id>0) {
            dispatch(fundAction.getFundTypes());

            if (referral.funds) {
                setFunds(referral.funds);
            } else {
                setFunds([initialFund]);
            }
        }

       
    }, [referral])

    const addFundType = (e) => {
        console.log("addFundType is clicked");
        e.preventDefault();

        setFunds([...funds, {...initialFund}])
    }

    const removeFundType = (removeIndex) => {

        setFunds( funds.filter((fund, index) => index != removeIndex));
    }

    const handleFundChange = (modifyIndex, name, value) => {
        if (name === 'fundId') {
            let fundName = fundTypes.find(type => type.id === value).name;
            setFunds ( funds.map((fund,index) => index == modifyIndex ? {...fund, [name]: value, fundName: fundName} : fund));
        } else {
            setFunds ( funds.map((fund,index) => index == modifyIndex ? {...fund, [name]: value} : fund));
        }
        
    }

    const submitForm = (e) => {
        e.preventDefault();
        console.log(funds);

        if (validate()) {
           dispatch(referralAction.updateFunds(referral.id, funds));
        }
    }

    const validate = () => {
        console.log("validate Forms")
        let formErrors = {error: false};

        if (fee <= 0) {
            formErrors = {...formErrors, error: true, fee: 'Funds not selected'};
        }

        if (fee != referral.agreedAmount) {
            formErrors = {...formErrors, error: true, fee: 'Funds total not matches agreed amount'};
        }

        
        if (funds.filter(fund => (!fund.cycle || fund.cycle <= 0)).length > 0) {
            formErrors = {...formErrors, error: true, cycle: 'Billing cycle required'};
        }

        console.log(formErrors)

        setErrors(formErrors);
        return !formErrors.error;
    }

    return (
        <Card>
            <CardHeader title="Fund Form" />

            <CardContent>

                <Grid container spacing={2}>
                    {console.log(funds)}
                    {funds && funds.map((fund,index) => 
                        <>
                            <Grid item xs={4} >
                                <TextField
                                    id="fundId"
                                    select
                                    label="Fund"
                                    name="fundId"
                                    value={fund.fundId}
                            
                                    onChange={(e) => handleFundChange(index, e.target.name, e.target.value )}
                                    variant="outlined"
                                    fullWidth
                                >
                                
                                    {fundTypes.map(fundType => <MenuItem key={fundType.id} value={fundType.id}>{`${fundType.name}`}</MenuItem>)}
                        
                                </TextField>
                            </Grid>
                        
                            <Grid item xs={4}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    type="number"
                                    id="amount"
                                    name="amount"
                                    label="Amount"
                                    value={fund.amount}
                                    onChange={(e) => handleFundChange(index, e.target.name, e.target.value )}
                                    error={errors.amount}
                                    helperText={errors.amount ? errors.amount : ''}
                                />
                            </Grid> 

                            <Grid item xs={3} >
                                <TextField
                                    id="cycle"
                                    select
                                    label="Billing Cycle"
                                    name="cycle"
                                    value={fund.cycle}

                                    onChange={(e) => handleFundChange(index, e.target.name, e.target.value )}
                                    variant="outlined"
                                    fullWidth
                                >
                                {   CYCLES.map(cycle => <MenuItem key={cycle.code} value={cycle.code}>{`${cycle.name}`}</MenuItem>)}
                                </TextField>
                            </Grid>
                            
                            <Grid item xs={1}>
                                <Button onClick={() => removeFundType(index)}>X</Button>
                            </Grid>
                        </>
                        )}
                 
                    <Grid item xs={8}>Fee</Grid>
                    <Grid item xs={4}>{localCurrency(fee)}</Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={6}>
                        <Button onClick={addFundType}>
                            + Add Fund Type
                        </Button>
                    </Grid>

                    <Grid item xs={3} >
                        <LoadingButton
                            loading={false}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            type="submit"
                            onClick={submitForm}
                        >
                            Ok
                        </LoadingButton>

                        

                    </Grid>

                    { errors.fee ? <Alert severity="error">{errors.fee}</Alert> : ""}
                    { errors.cycle ? <Alert severity="error">{errors.cycle}</Alert> : ""}
                    
                </Grid>
            </CardContent>
        </Card>
    )
}