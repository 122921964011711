import { Preview } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, MenuItem, Radio, TextField } from "@mui/material"
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { employeeAction } from "../../_actions/employeeAction";
import { incidentAction } from "../../_actions/incidentAction";

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

const initialState = {witnessed: '', employeeID: '', fallAreaID: '', injuryTypeID: '', incidentFormNo: '', fallenTime: ''};
 
const infoRow = {id: 0, incidentID: 0 , itemID: 15, referenceDataID: '', status: 'P'};

export const FallsAdditionalInfoForm = ({incidentID, locationID, checkListItems, errors = {}, close }) => {

    const dispatch = useDispatch();

    const [data, setData] = useState(initialState);
    const [selectedFallPrevention, setSelectedFallPrevention] = useState([]);

    const employees = useSelector(state => state.employee.employees);
    const fallAreas = useSelector(state => state.incident.fallAreas);
    const injuryTypes = useSelector(state => state.incident.injuryTypes);
    const fallPreventions = useSelector(state => state.incident.fallPreventions);

    useEffect(() => {
        
        if (checkListItems) {
            console.log("Checklist mapper Effect")
            console.log(checkListItems)
            let mappedData = initialState;
            
            
            checkListItems.map(item => {
                if (item.itemID == 11) {
                    mappedData = {...mappedData, witnessed: item.txnValue}
                } else if (item.itemID == 12) {
                    mappedData = {...mappedData, employeeID: item.txnNumber}
                } else if (item.itemID == 13) {
                    mappedData = {...mappedData, fallAreaID: item.referenceDataID}
                } else if (item.itemID == 14) {
                    mappedData = {...mappedData, injuryTypeID: item.referenceDataID}
                //} else if (item.itemID == 15) {
                //    prevensionSteps = [...prevensionSteps, {...item, modified: false}];
                } else if (item.itemID == 16) {
                    mappedData = {...mappedData, incidentFormNo: item.txnValue}
                } else if (item.itemID == 17) {
                    mappedData = {...mappedData, fallenTime: item.txnValue}
                } else {
                    mappedData = mappedData;
                }
            });

            setData(mappedData);
        }
    }, [checkListItems])

    const handleChange = (e) => {

        const {name, value} = e.target;

        setData({...data, [name]: value});
    }

    const handleCheckBoxChange = (selectedPrevention) => {
        console.log(selectedPrevention);
        setSelectedFallPrevention(selectedFallPrevention.map(prevention => (prevention.referenceDataID === selectedPrevention.referenceDataID ? {...prevention, selected: !prevention.selected, modified: true} : prevention)))
    }

    useEffect(() => {
        dispatch(incidentAction.getFallAreas(locationID));
        dispatch(incidentAction.getFallPreventions(locationID));
        dispatch(incidentAction.getInjuryTypes(locationID));
    },[])

    useEffect(() => {
        console.log(data);
        if (data.witnessed == 'Y') {
            console.log("Get employees");
            console.log(locationID)
            dispatch(employeeAction.getLocationEmployees(locationID));
        }
        
    }, [data])

    useEffect(() => {
        console.log("fallPreventions useEffect")

        let prevensionSteps = [];
        checkListItems && checkListItems.map(item => {
            if (item.itemID == 15) {
                prevensionSteps = [...prevensionSteps, {...item, modified: false, selected: true}];
            }
        });

        fallPreventions && fallPreventions.map(prevension => {
            
            if (prevensionSteps.filter(prev => prev.referenceDataID == prevension.id) <= 0) {
                prevensionSteps = [...prevensionSteps, {...infoRow, incidentID: incidentID, modified: false, selected: false, referenceDataID: prevension.id, referenceDataValue: prevension.typeValue}];
            }
                    
        })   
        console.log(prevensionSteps)
        setSelectedFallPrevention(prevensionSteps);
        
    }, [fallPreventions])

    const onSubmit = () => {
        // Validate

            // Modal Map


            dispatch(incidentAction.updateBulkCheckList(modelMapping() ));
    }

    function modelMapping() {
        let mappedCheckListItem = [];

        checkListItems.map(item => {
            
            if (item.itemID == 11) {
                mappedCheckListItem =  [...mappedCheckListItem, {...item, txnValue: data.witnessed}];
            } else if (item.itemID == 12) {
                mappedCheckListItem =  [...mappedCheckListItem, {...item, txnNumber: data.employeeID}];
            } else if (item.itemID == 13) {
                mappedCheckListItem =  [...mappedCheckListItem, {...item, referenceDataID: data.fallAreaID}];
            } else if (item.itemID == 14) {
                mappedCheckListItem =  [...mappedCheckListItem, {...item, referenceDataID: data.injuryTypeID}];
            } else if (item.itemID == 16) {
                mappedCheckListItem =  [...mappedCheckListItem, {...item, txnValue: data.incidentFormNo}];
            } else if (item.itemID == 17) {
                mappedCheckListItem =  [...mappedCheckListItem, {...item, txnValue: data.fallenTime}];
            }
        });

        let modifiedList = [];
         
        modifiedList = selectedFallPrevention.filter(prevension => prevension.modified);
   
        modifiedList = modifiedList.filter(prevension => (!prevension.select && prevension.id <= 0));
        modifiedList = modifiedList.map(prevension => (!prevension.select && prevension.id > 0) ? {...prevension, status: 'X'} : prevension);
console.log(modifiedList);
        modifiedList.map(item => mappedCheckListItem = [...mappedCheckListItem, item])

        console.log(mappedCheckListItem);
        return mappedCheckListItem;
    }

    function selectedPreventionItems() {
        let modifiedList = [];
        modifiedList = selectedFallPrevention.find(prevension => prevension.modified);
        modifiedList.filter(prevension => (!prevension.select && prevension.id <= 0));
        modifiedList.map(prevension => (!prevension.select && prevension.id > 0) ? {...prevension, status: 'X'} : prevension);

        return modifiedList;
    }

    return (
        <Grid container spacing={2}>

            <Grid item xs={6}>

                <Grid container spacing={2}>

                <Grid item xs={12}>
                    <FormLabel component="legend">Fallen Time</FormLabel>
                        <FormControlLabel value="AM" name="fallenTime" onChange={handleChange} control={<Radio checked={data.fallenTime === "AM"} />} label="AM" />
                        <FormControlLabel value="PM" name="fallenTime" onChange={handleChange} control={<Radio checked={data.fallenTime === "PM"}/>} label="PM" />
                        <FormControlLabel value="NIGHT" name="fallenTime" onChange={handleChange} control={<Radio checked={data.fallenTime === "NIGHT"}/>} label="NIGHT" />
                    </Grid>

                    <Grid item xs={12}>
                    <FormLabel component="legend">Is Witnessed by Employee</FormLabel>
                        <FormControlLabel value="Y" name="witnessed" onChange={handleChange} control={<Radio checked={data.witnessed == 'Y'} />} label="Witnessed" />
                        <FormControlLabel value="N" name="witnessed" onChange={handleChange} control={<Radio checked={data.witnessed == 'N'}/>} label="Unwitnessed" />
                    </Grid>

                    <Grid item xs={12}>
                        {data.witnessed === "Y" && 
                        <TextField
                            id="employeeID"
                            select
                            label="Witness Employee"
                            name="employeeID"
                            value={data.employeeID}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            required
                            error={errors.employeeID}
                                helperText={errors.employeeID ? errors.employeeID : ''}
                        >
                            {employees.map(employee => <MenuItem key={employee.id} value={employee.id}>{employee.firstName}</MenuItem>)}
            
                        </TextField>
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="fallAreaID"
                            select
                            label="Fallen Area"
                            name="fallAreaID"
                            value={data.fallAreaID}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            required
                            error={errors.fallAreaID}
                                helperText={errors.fallAreaID ? errors.fallAreaID : ''}
                        >
                            {fallAreas.map(area => <MenuItem key={area.id} value={area.id}>{area.typeValue}</MenuItem>)}
            
                        </TextField>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="injuryTypeID"
                            select
                            label="Injury Type"
                            name="injuryTypeID"
                            value={data.injuryTypeID}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            required
                            error={errors.injuryTypeID}
                                helperText={errors.injuryTypeID ? errors.injuryTypeID : ''}
                        >
                            {injuryTypes.map(injuryType => <MenuItem key={injuryType.id} value={injuryType.id}>{injuryType.typeValue}</MenuItem>)}
            
                        </TextField>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="incidentFormNo"
                            name="incidentFormNo"
                            label="Incident Form No"
                            value={data.incidentFormNo}
                            onChange={handleChange}
                            //error={errors.closeRemark}
                            //helperText={errors.closeRemark ? errors.closeRemark : ''}
                        />
                    </Grid>

                </Grid>

            </Grid>

            <Grid item xs={6}>

                <Grid container spacing={2}>
                    <Grid item xs={12}>

                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">Fall Prevention Steps</FormLabel>
                
 
                            <FormGroup>
                                {selectedFallPrevention && selectedFallPrevention.map(prevention => 
                                        <FormControlLabel
                                        control={
                                            <Checkbox checked={prevention.selected} onChange={(event) => handleCheckBoxChange(prevention)} name={prevention.referenceDataValue} />
                                        }
                                        label={prevention.referenceDataValue}
                                    />
                                    )
                                    }
                            </FormGroup>

                            {/*<FormHelperText>Be careful</FormHelperText>*/}
                        </FormControl>
                    
                    </Grid>
            
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="outherPrevention"
                            name="outherPrevention"
                            label="Other Prevention"
                            value={data.outherPrevention}
                            onChange={(e) => (e.target.value)}
                            //error={errors.closeRemark}
                            //helperText={errors.closeRemark ? errors.closeRemark : ''}
                            multiline      
                            maxRows={4}
                        />
                    </Grid>

                </Grid>

            </Grid>

            <Grid item xs={12}></Grid>
            <Grid item xs={8}></Grid>

                    <Grid item xs={2} >
                        <LoadingButton
                            loading={false}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                            onClick={onSubmit}
                        >
                            Save
                        </LoadingButton>

                    </Grid>

                    <Grid item xs={2} >
                        <LoadingButton
                            loading={false}
                            loadingPosition="start"
                            startIcon={<CloseIcon />}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={close}
                        >
                            Close
                        </LoadingButton>

                    </Grid>


        </Grid>
    )
}