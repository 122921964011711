import { applyMiddleware, createStore } from "redux";
import logger, { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import { composeWithDevTools} from 'redux-devtools-extension';

import {rootReducer} from '../_reducers';

const loggerMiddleware = createLogger();

export const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(
            thunk,
            loggerMiddleware,
    ))
)