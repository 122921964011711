
export const localCurrency = (value = 0) => {

    const currencyParam = {
        style: "currency",
        currency: "GBP"
      }
    
    const convert = (inputVal) => {
        var absValue = Math.abs(value);
        
        return absValue.toLocaleString("en-GB", currencyParam);
    }

    return (
        <>
        {value < 0 ? '(' + convert(value) + ')' : convert(value) }
        
        </>
        
    );
}

export const numberFormat = (value = 0) => {
    return value.toLocaleString(undefined, { maximumFractionDigits: 2 });
}