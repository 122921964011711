import React, {useEffect} from "react";

import { Box, Button, ButtonGroup, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { dateFormat } from "../../_helpers/datetime";
import { localCurrency } from "../../_helpers/number";

export const OccupancyTable = ({rooms , loadCurrRoom}) => {

  function getBackgroundColor (isOccupied) {
    switch (isOccupied) {
      case 'N':
        return "orange";
    
      case 'R':
        return "yellow";
      default:
        return "";
    }
  }

    return (
        <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">#</TableCell>
                    <TableCell align="left">Resident</TableCell>
                    <TableCell align="center">Admission Date</TableCell>
                    <TableCell align="right">Room No</TableCell>
                    <TableCell align="center">Room Type</TableCell>
                    <TableCell align="right">Current Week Fee</TableCell>
                    <TableCell align="right">Current Monthly Fee</TableCell>
                    <TableCell align="right">Weekly Varience</TableCell>
                    <TableCell align="right">Monthly Varience</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                    {rooms && rooms.map( (room, index) =>
                
                    <TableRow key={room.id} sx={{backgroundColor: getBackgroundColor(room.isOccupied)}}>
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="left">{room.currBooking.id > 0 ? `${room.currBooking.customer.firstName} ${room.currBooking.customer.lastName}` : ''}</TableCell>
                    <TableCell align="center">{room.currBooking.id > 0 ? dateFormat(room.currBooking.fromDate) : null}</TableCell>
                    <TableCell align="right">{room.roomNo} </TableCell>
                    <TableCell align="center">{room.roomType.name}</TableCell>
                    
                    <TableCell align="right">{localCurrency(room.currBooking.id > 0 ? room.currBooking.amount : 0)}</TableCell>
                    <TableCell align="right">{localCurrency(room.currBooking.id > 0 ? room.currBooking.monthAmount : 0)}</TableCell>
                    <TableCell align="right">{localCurrency(room.currBooking.id > 0 ? room.currBooking.weekVarience : 0)}</TableCell>
                    <TableCell align="right">{localCurrency(room.currBooking.id > 0 ? room.currBooking.monthVarience : 0)}</TableCell>
            
                    <TableCell align="center">
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                      <Button onClick={() => {loadCurrRoom(room)}}>{room.isOccupied == 'Y' ? "View" : "View"}</Button>
                      {/*<Button onClick={() => {}}>Del</Button>*/}
                    </ButtonGroup>
                  </TableCell>

                  </TableRow>

                    )}
                  
                </TableBody>
              </Table>
            </TableContainer>
    )
}