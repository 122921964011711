import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authAction } from '../_actions/AuthAction';

export const Logout = () => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        console.log("Request to logout ...");
        dispatch(authAction.logout());
        navigate("/")
    }, []);


    return (
        <> Logging out</>
    )
}