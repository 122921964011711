import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export const RotaWeekPrint = () => {

    const employees = useSelector(state => state.employee.employees);
    const monRota = useSelector(state => state.rota.weekRota.mon);
    const tueRota = useSelector(state => state.rota.weekRota.tue);
    const wedRota = useSelector(state => state.rota.weekRota.wed);
    const thuRota = useSelector(state => state.rota.weekRota.thu);
    const friRota = useSelector(state => state.rota.weekRota.fri);
    const satRota = useSelector(state => state.rota.weekRota.sat);
    const sunRota = useSelector(state => state.rota.weekRota.sun);


    const findDuration = (dayRota, employeeID) => {
        let duration = 0;
        let role = '';
        dayRota.map(rota => {
            rota.lines.map(line => {
                console.log(line);
                duration = duration + (line.employee !== null && line.employee.id == employeeID) ? 8 : 0;
                role = (line.employee !== null && line.employee.id == employeeID) ? line.jobRole.abbr : role;
            })
        })

        return <><TableCell>{duration}</TableCell> <TableCell>{role}</TableCell></>;
    }

    const getDuration = (day,employeeID) => {
        let duration = 0;
        
        switch (day) {
            case "mon":
                duration = findDuration(monRota, employeeID);
                break;
            case "tue":
                duration = findDuration(tueRota, employeeID);
                break; 
            case "wed":
                duration = findDuration(wedRota, employeeID);
                break; 
            case "thu":
                duration = findDuration(thuRota, employeeID);
                break;
            case "fri":
                duration = findDuration(friRota, employeeID);
                break;
            case "sat":
                duration = findDuration(satRota, employeeID);
                break;
            case "sun":
                duration = findDuration(sunRota, employeeID);
                break;
            default:
                break;
        }
        return duration;
    } 

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Role</TableCell>
                        <TableCell>Employee</TableCell>
                        <TableCell>Mon</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Tue</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Wed</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Thu</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Fri</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Sat</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Sun</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Wk4 Hrs</TableCell>
                        <TableCell>Total Hrs</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {employees.map (employee =>
                        <TableRow> 
                        <TableCell component="th" scope="row">
                            {employee.jobRole.abbr}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {employee.lastName}
                        </TableCell>
 
                        {getDuration("mon", employee.id)}
  
                        {getDuration("tue", employee.id)}
                        
                        {getDuration("wed", employee.id)}
                        
                        {getDuration("thu", employee.id)}
                        
                        {getDuration("fri", employee.id)}
                        
                        {getDuration("sat", employee.id)}
                        
                        {getDuration("sun", employee.id)}
                        
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        </TableRow>
                    )}
                
                </TableBody>
            </Table>
        </TableContainer>
    )
}