import { rotaConstant } from "../_constants/rotaConstant";
import axios from '../_helpers/axios';
import { alertActions } from "./alertAction";

export const rotaAction = {
    init,
    loadWeek,
    updateShift,
    copyWeek,

    getPlan,
}

function init() {
    return {type: rotaConstant.INIT}
}

function loadWeek(workingLocationID, weekStartDate) {

    return dispatch => {
        
        dispatch(alertActions.clear());

        axios.get(`/api/v1/rota/${workingLocationID}/week/${weekStartDate}`)
            .then(response => {
                console.log("in Success block")
                console.log(response)
                if (response.status == 200)
                    dispatch(success(response.data));
                else 
                    throw new Error();
            })
            .catch(error => {
                console.log("in Exception block")
                console.log(error.response.data.message);
                dispatch(fail(error.response.data.message));
                dispatch(alertActions.error(error.response.data.message));
            });
    };

    function request() {return {type: rotaConstant.REQUEST}}
    function success(shifts) { return { type: rotaConstant.GET_WEEK_SHIFT_SUCCESS,shifts}}
    function fail(error) { return {type: rotaConstant.GET_WEEK_SHIFT_ERROR, error}}
}

function updateShift(shift) {
    return dispatch => {
        console.log(shift);
        
        axios.put('/api/v1/rota/shift', shift) 
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                console.log(error);
                dispatch(alertActions.error(error.response.data.message));
            });
    };

    function request() {return {type: rotaConstant.REQUEST}}
    function success(shift) { return { type: rotaConstant.UPDATE_SHIFT_SUCCESS,shift}}
    function error(error) { return {type: rotaConstant.UPDATE_SHIFT_ERROR, error}}
}

function copyWeek(workingLocationID, copyFromWeekStartDate, copyToWeekStartDate) {
    return dispatch => {
        axios.post(`/api/v1/rota/${workingLocationID}/week/copy/${copyFromWeekStartDate}/${copyToWeekStartDate}`)
            .then(response => {
                console.log("in Success block")
                dispatch(success(response.data));
            })
            .catch(error => {
                console.log("in Exception block")
                console.log(error);
                dispatch(fail(error));
                dispatch(alertActions.error(error.response.data.message));
            });
    };

    function request() {return {type: rotaConstant.REQUEST}}
    //function success(shifts) { return { type: rotaConstant.COPY_WEEK_SHIFT_SUCCESS,shifts}}
    function success(shifts) { return { type: rotaConstant.GET_WEEK_SHIFT_SUCCESS,shifts}}
    function fail(error) { return {type: rotaConstant.COPY_WEEK_SHIFT_ERROR, error}}
}


function getPlan(workingLocationID) {

    return dispatch => {
        
        dispatch(alertActions.clear());

        axios.get(`/api/v1/rota/${workingLocationID}/plan`)
            .then(response => {
                console.log("in Success block")
                console.log(response)
                if (response.status == 200)
                    dispatch(success(response.data));
                else 
                    throw new Error();
            })
            .catch(error => {
                console.log("in Exception block")
                console.log(error.response.data.message);
                dispatch(fail(error.response.data.message));
                dispatch(alertActions.error(error.response.data.message));
            });
    };

    function request() {return {type: rotaConstant.REQUEST}}
    function success(shifts) { return { type: rotaConstant.GET_WEEK_SHIFT_SUCCESS,shifts}}
    function fail(error) { return {type: rotaConstant.GET_WEEK_SHIFT_ERROR, error}}
}