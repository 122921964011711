import React, { useEffect, useState } from "react";

import { Button, Container, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { SYS_DATE_FORMAT } from "../../_helpers/datetime";

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { LoadingButton } from "@mui/lab";

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { employeeAction } from "../../_actions/employeeAction";
import { alertActions } from "../../_actions/alertAction";

export const ContractForm = ({currContract, jobRoles, close}) => {

    const [contract, setContract] = useState(currContract);
    const [errors, setErrors] = useState({error: false});

    const loading = useSelector(state => state.employee.loading);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log(currContract)
        setContract(currContract);
    },[currContract]);

    const handleFormChange = (e) => {
        const {name, value} = e.target;

        setContract({...contract, [name]: value});
    }

    const handleDatePicker = (name, date) => {
        setContract({...contract, [name]: date.format("YYYY-MM-DD")});
        
    }

    const handleSubmit = (e) => {
        console.log("handleSubmit......")
        e.preventDefault();
        //if (validate() ) {
            console.log("Valid Contract");
            console.log(contract)
            if (contract.id > 0) {
                dispatch(employeeAction.updateContract(contract))
            } else {
                dispatch(employeeAction.addContract(contract));
            }
        //}

    }

    const handleClose = (e) => {
        dispatch(alertActions.clear());
        close();
    }

    const validate = () => {
        console.log("validate Forms")
        let formErrors = {error: false};
    
       // if ( contract.contractedHours > 10) {
        //    formErrors = {...formErrors, error: true, contractedHours: 'Invalid Contracted Hours'};
        //}

        if (!contract.jobRoleId || contract.jobRoleId.length <= 0) {
            formErrors = {...formErrors, error: true, jobRoleId: 'Contract is Required'};
        }

        //if (!employee.startDate || moment(employee.startDate).format("YYYYMMDD") >= moment().format("YYYYMMDD")) {
        //    formErrors = {...formErrors, error: true, dob: 'Invalid Date of Birth'};
        //}

        if (!moment(contract.endDate)) {
            formErrors = {...formErrors, error: true, endDate: 'Invalid Contract end date'};
        }

        setErrors(formErrors);
        return !formErrors.error;
    }

    return (
        <>
            <Grid item xs={12} sm={4}>
                <TextField
                    id="jobRoleId"
                    select
                    label="Contract"
                    name="jobRoleId"
                    value={contract.jobRoleId}
                    onChange={handleFormChange}
                    variant="outlined"
                    fullWidth
                    disabled={contract.id > 0}
                    error={errors.jobRoleId}
                        helperText={errors.jobRoleId ? errors.jobRoleId : ''}
                >
                    {jobRoles.map(jobRole => <MenuItem key={jobRole.id} value={jobRole.id}>{jobRole.name}</MenuItem>)}
                </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
                <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="contractedHours"
                    name="contractedHours"
                    label="Contracted Hours"
                    value={contract.contractedHours}
                    onChange={handleFormChange}
                    disabled={contract.id > 0}
                    error={errors.contractedHours}
                        helperText={errors.contractedHours ? errors.contractedHours : ''}
                />
            </Grid>

            <Grid item xs={12} sm={3}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                    label="Start Date"
                    type="date"
                    value={contract.startDate}
                    inputFormat={SYS_DATE_FORMAT}
                    onChange={(newValue) => {
                        handleDatePicker('startDate', newValue);
                    }}
                    disabled={contract.id > 0}
                    renderInput={(params) => <TextField {...params} error={errors.startDate}
                    helperText={errors.startDate ? errors.startDate : ''} />}
                />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={3} >
                <LocalizationProvider dateAdapter={DateAdapter} >
                <DatePicker
                    label="End Date"
                    type="date"
                    value={contract.endDate}
                    inputFormat={SYS_DATE_FORMAT}
                    onChange={(newValue) => {
                        handleDatePicker('endDate', newValue);
                    }}
                    disabled={contract.id <= 0}
                    mask={SYS_DATE_FORMAT}
                    renderInput={(params) => <TextField {...params} error={errors.endDate}
                    helperText={errors.endDate ? errors.endDate : ''} />}
                />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12}></Grid>
            <Grid item xs={10}></Grid>

            <Grid item xs={1} >
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                    color="primary"
                    fullWidth
              
                    onClick={handleSubmit}
                >
                    Save
                </LoadingButton>

            </Grid>

            <Grid item xs={1} >
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<CloseIcon />}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={handleClose}
                >
                    Close
                </LoadingButton>

            </Grid>
   
        </>
    )
}