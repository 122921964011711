import { careBandConstant } from "../_constants/careBandConstant";

const initialState = {
            loading: false,
            bands: [],
        };

export function careband(state = initialState, action) {
    switch (action.type) {
        case careBandConstant.REQUEST: 
            return {
                ...state,
                loading: true,
            }
        case careBandConstant.GET_ALL_SUCCESS:
            return {
                ...state,
                bands: action.bands,
                loading: false
            }
        default:
            return state;
    }
}