export const roomConstant = {
    REQUEST : 'ROOM_REQUEST',
    REQUEST_ERROR: 'ROOM_REQUEST_ERROR',

    GET_ACTIVE_OCCUPANCIES_SUCCESS : 'ROOM_GET_ACTIVE_OCCUPANCIES_SUCCESS',
    GET_ACTIVE_OCCUPANCIES_ERROR : 'ROOM_GET_ACTIVE_OCCUPANCIES_ERROR',

    GET_ROOMS_WITH_OCCUPANCY_INFO_SUCCESS : 'ROOM_GET_ROOMS_WITH_OCCUPANCY_INFO_SUCCESS',
    GET_ROOMS_WITH_OCCUPANCY_INFO_ERROR : 'ROOM_GET_ROOMS_WITH_OCCUPANCY_INFO_ERROR',

    GET_NON_OCCUPIED_SUCCESS : 'ROOM_GET_NON_OCCUPIED_SUCCESS',
    GET_NON_OCCUPIED_ERROR : 'ROOM_GET_NON_OCCUPIED_ERROR',

    CREATE_OCCUPY_SUCCESS: 'ROOM_CREATE_OCCUPY_SUCCESS',
    CREATE_OCCUPY_ERROR: 'ROOM_CREATE_OCCUPY_ERROR',

    UPDATE_OCCUPY_SUCCESS: 'ROOM_UPDATE_OCCUPY_SUCCESS',
    UPDATE_OCCUPY_ERROR: 'ROOM_UPDATE_OCCUPY_ERROR',

    UPDATE_OCCUPY_LEAVING_SUCCESS: 'ROOM_UPDATE_OCCUPY_LEAVING_SUCCESS',
}