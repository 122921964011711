import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material"

export const ManagementKPIScore = ({score}) => {
    return (
        <Card sx={{hight: '100%'}}>
            <CardHeader title="Management KPI Score" />

            <CardContent>
            <Box
      sx={{
          
          padding: 1,
          border: 2,
          borderRadius: '5px',
          textAlign: "center"
          
        }}
      >
          <Typography> {score} </Typography>
       
        </Box>
            </CardContent>
        </Card>
    )
}