import { Button, ButtonGroup, Table, TableBody, TableHead, TableRow, TableCell, IconButton } from "@mui/material"
import { dateFormat } from "../../_helpers/datetime";

import EditIcon from '@mui/icons-material/Edit';

export const EntryList = ({entries, loadEntry}) => {
    
    return (
      
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="right">#</TableCell>
                    <TableCell align="left">Week Start</TableCell>
                    <TableCell align="left">Week End</TableCell>
                    <TableCell align="left">CQC Rating</TableCell>
                    <TableCell align="left">CQC IPC</TableCell>
                    <TableCell align="left">Carehome Rating</TableCell>
                    <TableCell align="left">Food Hygiene</TableCell>
                    <TableCell align="center">Action</TableCell>
                </TableRow>
            </TableHead>    

            <TableBody>
                {entries && entries.map( (entry, index) => 
                <TableRow key={entry.id}>
                <TableCell align="right">{index + 1}</TableCell>
                <TableCell align="center">{dateFormat(entry.entryPeriod.fromDate)}</TableCell>
                <TableCell align="center">{dateFormat(entry.entryPeriod.toDate)}</TableCell>
          
          
                <TableCell align="left">{entry.cqcRating}</TableCell>
                <TableCell align="left">{entry.cqcIpc}</TableCell>

                <TableCell align="left">{entry.carehomeCoUk}</TableCell>
                <TableCell align="left">{entry.foodHygiene}</TableCell>
                
                <TableCell align="center">

             
                  <IconButton onClick={() => {loadEntry(entry)}} >
                        <EditIcon />
                </IconButton>

             
              </TableCell>

              </TableRow>

)}
              
            </TableBody>        
        </Table>


)
}