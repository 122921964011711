import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import React from "react";
import { dateFormat } from "../../_helpers/datetime";
import { InfoBlock } from "../ui/InfoBlock";

export const GPDetail = ({referral}) => {

    const {gpName, gpAddress} = referral;

    return (

        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="gp-detail"
                id="gp-detail"
            >
                <Typography variant="h6">GP Information</Typography>
            </AccordionSummary>
                
            <AccordionDetails>
                <InfoBlock label={"Name "} value={gpName} />
                <InfoBlock label={"GP Address"} value={gpAddress} />
            </AccordionDetails>
        </Accordion>

    )

}