import { Card, CardContent, Divider, Typography } from "@mui/material";
import React, { PureComponent } from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";



export default class LineGraph extends PureComponent {

    render() {
    return (
        <Card>
            <CardContent>
            <Typography
            gutterBottom
            color="textPrimary"
          >
            {this.props.title.toUpperCase()}
          </Typography>
          <Divider />
          <br/>
        <ResponsiveContainer width={"100%"} height={250}> 
            <LineChart
                width={500}
                height={300}
                data={this.props.data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="avgActual" stroke="#0095FF"  />
          <Line type="monotone" dataKey="avgTarget" stroke="#FF0000" />
            </LineChart>
        </ResponsiveContainer>

        </CardContent>
        </Card>
    );
            };
}