import React ,{useState} from "react";
import { Box, Button, IconButton, Stack, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { SYS_DATE_FORMAT } from "../../_helpers/datetime";
import { LoadingButton } from "@mui/lab";

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import { InfoBlock } from "../ui/InfoBlock";

import moment from "moment";

import { incidentAction } from "../../_actions/incidentAction";

export const CheckListForm = ({incidentDate, item}) => {

    const dispatch = useDispatch();

    const [txnDate, setTxnDate] = useState('');
    const [errors, setErrors] = useState({error: false});

    const submit = () => {
        if (validate()) {
            dispatch(incidentAction.updateCheckList({...item, txnDate: txnDate}));
        };
        
    }

    const close = () => {
        dispatch(incidentAction.setChecklistItemEditCancel(item))
    }

    const validate = () => {
        console.log("validate Forms")
        let formErrors = {error: false};

        if (!txnDate || txnDate.length <= 0) {
            formErrors = {...formErrors, error: true, txnDate: 'Required Date'};
        } 

        setErrors(formErrors);
        return !formErrors.error;
    }

    
    return (

        <Box display="flex" 
            justifyContent="space-between"
            alignItems="center" 
        >
            <Box >

                <LocalizationProvider dateAdapter={DateAdapter}>
                    <DatePicker
                        
                        mask="__-__-____"
                        label={item.itemDescription}
                        type="date"
                        value={txnDate}
                        inputFormat={SYS_DATE_FORMAT}
                        onChange={(newValue) => {
                            setTxnDate(newValue);
                        }}
                        maxDate={moment()}
                        minDate={moment(incidentDate)}
                        renderInput={(params) => <TextField {...params} error={errors.txnDate}
                        helperText={errors.txnDate ? errors.txnDate : ''} />}
                    />
                </LocalizationProvider>
            </Box>

            <Box>
                <Stack direction={"row"} spacing={1}>
                
                    <IconButton aria-label="Done" size="large" color="success" onClick={submit}>
                        <DoneSharpIcon fontSize="inherit" />
                    </IconButton>

                    <IconButton aria-label="Close" size="large" color="error" onClick={close}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </Stack>
            </Box>
        </Box>
    )
}