import moment from 'moment';
import React from 'react';

export const SYS_DATE_FORMAT = "DD-MM-YYYY" ;


export const dateFormat = (date) => {
    return (
        <>{moment(date).format(SYS_DATE_FORMAT)}</>
    );
}