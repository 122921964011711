import { Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export const Dialog = ({message, open, handleClose, handleOk}) => {

    return (
        <MuiDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Please Confrim!!!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleOk} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </MuiDialog>
    )
}