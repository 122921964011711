export const Roles = {
    ADMIN_ROLE : 'ADMIN_ROLE',
    USER_ROLE: 'EMPLOYEE_USER',
    MANAGER_ROLE: 'MANAGER_ROLE',
    DASHBOARD_MANAGE: 'DASHBOARD_MANAGE',
    ROOM_MANAGE: 'ROOM_MANAGE',
    ROTA_MANAGE: 'ROTA_MANAGE',
    EMPLOYEE_MANAGE: 'EMPLOYEE_MANAGE',
    REFERRAL_MANAGE: 'REFERRAL_MANAGE',
    INCIDENT_MANAGE: 'INCIDENT_MANAGE',
    ENTRY_MANAGE: 'ENTRY_MANAGE',
}